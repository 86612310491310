import React, { useEffect, useState } from "react";
import {
  Divider,
  Flex,
  SkeletonText,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { H5 } from "../../design-system/headings/H5";

import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { injectIntl } from "react-intl";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { connect } from "react-redux";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ShippingCostBadge } from "../ShippingCostBadge/ShippingCostBadge";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ErrorModal } from "../ErrorModal/ErrorModal";

const ItemOptions = ({ detail, onCartAddition, handleBuyNow }) => {
  const mainState = useSelector((state) => state);
  const history = useHistory();
  const [buyNowResult, setBuyNowResult] = useState(false);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false); 

  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasError = `${mainState.cart.result}`;

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    buyNowResult &&
      mainState.cart.result &&
      !hasError.includes("error") &&
      history.push("/cart");

    if (mainState.cart.result && hasError.includes("error")) {
      setBuyNowResult(false);
      onOpen();
      setLoading(false);
    }
  }, [mainState.cart.result]);

  useEffect(() => {
    if (mainState.cart.result) {
      setResult(false);
      setLoading(false);
      handleScrollTo();
      setCartLoading(false);
    }
  }, [mainState.cart.result]);

  const handleOpenInfoModal = () => {
    setOpenInfoModal(!openInfoModal)
  }

  return (
    <Flex flexDir={"column"} /* minH="250px" */ gap="1.5rem">
      <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />

      <Divider w={"100%"} />
      <Text fontSize={"16px"} fontWeight={600} px="1.5rem">
        {detail?.currency_code} {detail?.value} + Imp
      </Text>
      <Flex flexDir={"column"} px="1.5rem">
        <ShippingCostBadge
          currency={detail?.currency_code}
          shippingCost={detail?.shipping_cost}
        />
        <Flex>
          <Text>Tiempo máximo de entrega </Text>
          <Text ml={1} fontWeight={600}>
            {detail?.sp_delivery_time} días
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} px="1.5rem">
        <Flex alignItems={"center"}>
          <Text>Vendido por⠀</Text>
          <ProductDetailModalSeller
            sellerName={detail?.provider_fantasy_name}
            email={detail?.provider_email}
            phone={detail?.provider_phone}
          />
        </Flex>
      </Flex>

      {!detail?.enable_buy &&
        (
          <>
            <Flex alignItems={"center"} gap={3} mb={"1rem"} px="1.5rem">
              <Text
                borderRadius={"0.75rem"}
                bg={"#FF980080"}
                border={"1px solid"}
                borderColor={"brand.warning"}
                paddingInline={"2.5"}
                paddingY={"1.5"}
                w="fit-content"
                fontWeight={"nomal"}
                fontSize={"sm"}
              >Tu empresa debe registrar este proveedor
              </Text>
              <Text
                fontSize={"sm"}
                fontWeight={"bold"}
                color={"brand.warning"}
                border={"1.5px solid"}
                borderColor={"brand.warning"}
                h={"1.5rem"}
                w={"1.5rem"}
                borderRadius={"100%"}
                textAlign={"center"}
                cursor={"pointer"}
                onClick={() => handleOpenInfoModal()}
              >?</Text>
            </Flex>

            {/* Modal Mas informacion */}
            <Modal
              isOpen={openInfoModal}
              onClose={handleOpenInfoModal}
              size="lg"
              isCentered>
              <ModalOverlay />
              <ModalContent
                borderRadius="12px"
                fontFamily={"Open Sans"}
                flexDir={"row"}>
                <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />

                <ModalBody
                  borderRadius={"12px"}
                  m={"20px"}
                  textAlign="center"
                  p="1.5rem">
                  <H5 mb={"1.5rem"}>
                    No tenés habilitadas las compras con este proveedor
                  </H5>
                  <Paragraphs mb={"1.5rem"} lineHeight="1.375rem">
                  Solicitale al área de Registro de proveedores de tu empresa el registro de este proveedor de Bekeu
                  </Paragraphs>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )
      }

      <Flex gap={2} alignItems="center" justifyContent={"center"} px="1.5rem" mb={"1.5rem"}>
        <ButtonBekeuM
          my={0}
          w="100%"
          isDisabled={detail?.enable_buy === 0}
          onClick={() => {
            handleBuyNow(detail);
            setBuyNowResult(!buyNowResult);
            setLoading(true);
          }}
          isLoading={loading}
        >
          Comprar ahora
        </ButtonBekeuM>
        <ButtonBekeuOutline
          height={"38px"}
          fontSize="16px"
          isDisabled={detail?.enable_buy === 0}
          onClick={() => {
            setResult(true);
            onCartAddition(detail);
            setCartLoading(true);
          }}
          isLoading={cartLoading}
        >
          Agregar al carrito
        </ButtonBekeuOutline>
      </Flex>
    </Flex>
  );
};

const ProductDetailOptions = ({
  providers,
  addItemToCart,
  cartBuyNow,
  skeletonLoading,
  ...props
}) => {

  const handleCartAddition = (item) => {
    addItemToCart(item);
  };

  const handleBuyNow = (item) => {
    cartBuyNow(item);
  };

  return (
    <>
      {providers && providers.length > 0 && (
        <BoxBekeu
          p={0}
          w={"50%"}
          visibility={providers.length > 1 ? "inherit" : "hidden"}
          {...props}
        >
          <Flex flexDir={"column"}>
            <Text px="1.5rem" py="16px">
              Otras opciones de compra
            </Text>
            {skeletonLoading ? (
              <Flex flexDir={"column"} justifyContent={"center"}>
                <SkeletonText
                  noOfLines={2}
                  spacing="4"
                  width={"40%"}
                  mt={"8"}
                />
                <SkeletonText
                  noOfLines={2}
                  spacing="4"
                  width={"35%"}
                  mt={"8"}
                />
                <SkeletonText
                  noOfLines={2}
                  spacing="4"
                  width={"50%"}
                  mt={"8"}
                />
                <SkeletonText mt="4" noOfLines={2} spacing="4" width={"70%"} />
              </Flex>
            ) : (
              providers
                .slice(1)
                .map((detail) => {
                  return(
                    <ItemOptions
                      key={detail.sku}
                      detail={detail}
                      onCartAddition={handleCartAddition}
                      handleBuyNow={handleBuyNow}
                    />
                  )
                })
            )}
          </Flex>
        </BoxBekeu>
      )}
    </>
  );
};

export default injectIntl(connect(null, cartActions)(ProductDetailOptions));
