import {
  Divider,
  Flex,
  Img, ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay, OrderedList, Text, UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import successsvg from "../../design-system/icons/Arrows/success-circle-fill.svg";
import errorsvg from "../../design-system/icons/Arrows/error-circle-fill.svg";
import {Paragraphs} from "../../design-system/headings/Paragraphs";

export const ApproveCartsSuccessModal = ({
  handleApproveCart,
  handlePagination,
  item,
  ...props
}) => {
  const mainState = useSelector((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [approvalLoading, setLoading] = useState(false);

  const handleApprove = () => {
    setLoading(true);
    handleApproveCart(item?.id);
    onOpen();
  };

  const handleOpen = () => {
    mainState.carts_approval.approvalResult = false;
    mainState.carts_approval.approvalResultValue = null;
    onOpen();
  };

  const handleClose = () =>{
    setLoading(false);
    mainState.carts_approval.approvalResult = false;
    mainState.carts_approval.approvalResultValue = null;
    onClose();
  }

  useEffect(() => {
      setLoading({approvalLoading : mainState.carts_approval?.approvalLoading});

  }, [mainState.carts_approval?.approvalLoading]);

  useEffect(() => {
    setLoading(mainState.carts_approval?.approvalLoading);
    if (mainState.carts_approval?.approvalResult) onOpen();
  }, [mainState.carts_approval?.approvalResultValue]);

  return (
    <>
      <ButtonBekeu
          onClick={handleOpen}
          isLoading={approvalLoading && !mainState.carts_approval?.approvalResult}
      >
        Aprobar pedido
      </ButtonBekeu>
      <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            closeOnEsc={mainState.carts_approval && !mainState.carts_approval?.approvalResult}
            closeOnOverlayClick={mainState.carts_approval && !mainState.carts_approval?.approvalResult}
          >
            <ModalOverlay />
            <ModalContent borderRadius={"12px"}>
              <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
              <ModalBody>
                    <Flex flexDir={"column"} justifyContent="center" padding={"48px"} alignItems="center">
                      {
                        mainState.carts_approval?.approvalResultValue && mainState.carts_approval?.approvalResultValue?.status && (
                        <>
                        {
                            mainState.carts_approval?.approvalResultValue && mainState.carts_approval?.approvalResultValue?.status === 'success' && (
                              <>
                                <Img src={successsvg} /><br/>
                                <H5 textAlign="center">¡El pedido fue aprobado con éxito!</H5>
                              </>
                          )
                        }
                          {
                              mainState.carts_approval?.approvalResultValue && mainState.carts_approval?.approvalResultValue?.status === 'fail' && (
                                  <>
                                    <Img src={errorsvg} /><br/>
                                    <H5 textAlign="center">El carrito no fue aprobado</H5>
                                  </>
                              )
                          }

                          <Flex flexDir={"column"} p={"24px"} justifyContent={"space-between"} gap="16px" px="32px" key="approveCartsSuccessModalResult">
                            <Flex flexDir={"column"}>
                              <Paragraphs
                                  fontWeight={600}
                                  fontSize={"1.125rem"}
                                  color={mainState.carts_approval?.approvalResultValue?.status === 'success'?"brand.success":"brand.error"}
                              > Carrito Nº {item.internal_code}
                              </Paragraphs>
                              {
                                  mainState.carts_approval?.approvalResultValue && mainState.carts_approval?.approvalResultValue?.status === 'success' && (
                                      <>
                                        {
                                            mainState.carts_approval.approvalResultValue?.message?.success && mainState.carts_approval.approvalResultValue.message.success.length > 0 &&
                                            (
                                                mainState.carts_approval.approvalResultValue.message.success.map((successItem) => (
                                                Object.keys(successItem).map((success) => {
                                                  const sapMsg = Array.isArray(successItem[success]) && successItem[success].length > 0 ? successItem[success][0]:success;
                                                  const sapNumber = sapMsg?.replace(/^\D+/g, "");
                                                  return (
                                                      <Flex key={Math.random()} flexDir={"column"}>
                                                        <Paragraphs fontWeight={600} fontSize={"1.125rem"} color="brand.success">
                                                          Compra Nº {success}
                                                        </Paragraphs>
                                                        <Paragraphs lineHeight="1.50rem">
                                                          {sapNumber?.length > 0 &&
                                                              `Pedido Bekeu creado bajo el número ${sapNumber}`}
                                                        </Paragraphs>
                                                      </Flex>
                                                  );
                                                })
                                                )
                                              )
                                            )
                                        }
                                        {
                                        mainState.carts_approval.approvalResultValue?.message.error && mainState.carts_approval.approvalResultValue.message.error.length > 0 &&
                                            (
                                                mainState.carts_approval.approvalResultValue.message.error.map((errorsItem) => (
                                                    Object.keys(errorsItem).map((error) => {
                                                      const sapMsg = Array.isArray(errorsItem[error]) && errorsItem[error].length > 0 ? errorsItem[error][0]:error;
                                                      return (
                                                          <Flex flexDir={"column"}>
                                                            <Paragraphs fontWeight={600} fontSize={"1.125rem"} color="brand.error">
                                                              Compra Nº {error} <br/>
                                                            </Paragraphs><br/>
                                                            <Paragraphs lineHeight="1.50rem">
                                                              La compra no pudo realizarse por los siguientes motivos:
                                                            </Paragraphs><br/>
                                                            <Paragraphs lineHeight="1.50rem">
                                                              <UnorderedList>
                                                                {
                                                                  Array.isArray(errorsItem[error]) && errorsItem[error].length > 0 ? (
                                                                      errorsItem[error].map((item, id) => (
                                                                          <ListItem key={id}>{item}</ListItem>
                                                                      ))
                                                                  ):(
                                                                      <ListItem>{sapMsg}</ListItem>
                                                                  )
                                                                }
                                                              </UnorderedList>
                                                            </Paragraphs>
                                                          </Flex>
                                                      );
                                                    })
                                                ))
                                            )
                                        }
                                    </>
                                  )
                              }
                              {
                                  mainState.carts_approval?.approvalResultValue && mainState.carts_approval?.approvalResultValue?.status === 'fail' &&(
                                      <>
                                      {
                                          mainState.carts_approval.approvalResultValue?.error?.success && mainState.carts_approval.approvalResultValue.error.success.length > 0 &&
                                          (
                                              mainState.carts_approval.approvalResultValue.error.success?.map((successItem) => (
                                                      Object.keys(successItem).map((success) => {
                                                        const sapMsg = Array.isArray(successItem[success]) && successItem[success].length > 0 ? successItem[success][0]:success;
                                                        const sapNumber = sapMsg?.replace(/^\D+/g, "");
                                                        return (
                                                            <Flex flexDir={"column"}>
                                                              <Paragraphs fontWeight={600} fontSize={"1.125rem"} color="brand.success">
                                                                Compra Nº {success}
                                                              </Paragraphs>
                                                              <Paragraphs lineHeight="1.50rem">
                                                                {sapNumber?.length > 0 &&
                                                                    `Pedido Bekeu creado bajo el número ${sapNumber}`}
                                                              </Paragraphs>
                                                            </Flex>
                                                        );
                                                      })
                                                  )
                                              )
                                          )
                                      }
                                      {
                                          mainState.carts_approval.approvalResultValue?.error?.error && mainState.carts_approval.approvalResultValue.error.error.length > 0 &&
                                          (
                                              mainState.carts_approval.approvalResultValue.error.error?.map((errorsItem) => (
                                                  Object.keys(errorsItem).map((error) => {
                                                    const sapMsg = Array.isArray(errorsItem[error]) && errorsItem[error].length > 0 ? errorsItem[error][0]:error;
                                                  return (
                                                      <Flex flexDir={"column"}>
                                                        <Paragraphs fontWeight={600} fontSize={"1.125rem"} color="brand.error">
                                                          Compra Nº {error} <br/>
                                                        </Paragraphs><br/>
                                                        <Paragraphs lineHeight="1.50rem">
                                                          La compra no pudo realizarse por los siguientes motivos:
                                                        </Paragraphs><br/>
                                                        <Paragraphs lineHeight="1.50rem">
                                                          <UnorderedList>
                                                            {
                                                              Array.isArray(errorsItem[error]) && errorsItem[error].length > 0 ? (
                                                                  errorsItem[error].map((item, id) => (
                                                                <ListItem key={id}>{item}</ListItem>
                                                                ))
                                                              ):(
                                                                  <ListItem>{sapMsg}</ListItem>
                                                              )
                                                            }
                                                          </UnorderedList>
                                                        </Paragraphs>
                                                      </Flex>
                                                  );
                                                })
                                              ))
                                          )
                                      }
                                      </>
                                  )
                                }
                            </Flex>
                                  <Flex gap={1} fontSize="0.9125rem" justifyContent={"center"} mb={4}>
                                    <Paragraphs>
                                      <Text color="brand.contentSecondary">
                                      Dudas y/o consultas a
                                    </Text>
                                    <Text color="brand.primary">info@bekeu.com</Text>
                                    </Paragraphs>
                                </Flex>
                                <Flex gap={1} fontSize="0.9125rem" justifyContent={"center"} mb={4}>
                                  <ButtonBekeu onClick={() => { handleClose(); handlePagination();}}>
                                    Aceptar
                                  </ButtonBekeu>
                                </Flex>
                              </Flex>
                            </>
                          )
                      }
                      {
                        !mainState.carts_approval?.approvalResult && !mainState.carts_approval?.approvalResultValue && (
                        <Flex flexDir={"column"} justifyContent="center" padding={"48px"}>
                          <H5 textAlign="center">
                            ¿Estás seguro que querés aprobar el pedido?
                          </H5>
                          <Flex alignItems="center" mt={4}>
                            <ButtonBekeuOutline onClick={onClose} mr={4}>
                              No
                            </ButtonBekeuOutline>
                            <ButtonBekeu onClick={handleApprove} isLoading={approvalLoading && !mainState.carts_approval?.approvalResult}>
                              Si
                            </ButtonBekeu>
                          </Flex>
                        </Flex>
                      )
                      }
                  </Flex>
              </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
