import React, { useEffect } from "react";
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Img,
  Flex,
  Select,
} from "@chakra-ui/react";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import successCircleFill from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import moment from "moment";
import { actions as quotesActions } from "../../pages/myQuotesPage/ducks/myQuotes.duck";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import useQuery from "../../hooks/useQuery";
import "./MyQuotesEditPriceModal.css";
import calendarIcon from "../../design-system/icons/Essentials/calendar.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const MyQuotesEditPriceModal = ({
  currency,
  price,
  validityDate,
  isOpen,
  onClose,
  handleEditMyQuotesPrice,
  handleForceState,
  listQuotes,
  currencyId,
}) => {
  const [accept, setAccept] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [newPrice, setNewPrice] = useState(price);
  const [dateInput, setDateInput] = useState();
  // moment(validityDate).format("YYYY/MM/DD")
  const [currencyInput, setCurrencyInput] = useState(currencyId);
  const [loading, setLoading] = useState(false);
  const mainState = useSelector((state) => state);

  const query = useQuery();

  // queries states
  const state = query.get("state");
  const currency_id = query.get("currency_id");
  const region_id = query.get("region_id");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const category_id = query.get("category_id");
  const subcategory_id = query.get("subcategory_id");
  const sub_category_item = query.get("sub_category_item");
  const brand_id = query.get("brand_id");

  const todayDate = new Date();

  const dateFormat = moment(todayDate).format("YYYY-MM-DD");

  const handleNewPrice = (e) => {
    e.target.value >= 0 && setNewPrice(e.target.value.replace(/[^0-9,.]/g, ""));
    if (dateInput === undefined) {
      setDateInput(dateFormat);
    }
  };

  const handleNewCurrency = (e) => {
    setCurrencyInput(e.target.value);
  };

  const handleResetModal = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setAccept(!accept);
    handleForceState();
    onClose();
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
    });
    setLoading(false);
  };

  useEffect(() => {
    mainState.quotes.result && setAccept(false);
  }, [mainState.quotes.result]);

  useEffect(() => {
    mainState.quotes.quotes && setNewPrice(price);
  }, [mainState.quotes.quotes]);

  useEffect(() => {
    setCurrencyInput(currencyId);
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setNewPrice(price);
        }}
        size={"lg"}
        onEsc={() => {
          setNewPrice(price);
          mainState.quotes.result && handleResetModal();
        }}
        onOverlayClick={() => {
          mainState.quotes.result && handleResetModal();
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="12px">
          <ModalCloseButton
            _focus={{ boxShadow: "rgba(0,0,0,0)" }}
            onClick={() => {
              mainState.quotes.result && handleResetModal();
            }}
          />
          <ModalBody p="48px">
            {accept ? (
              <Flex
                flexDirection={"column"}
                justifyContent="center"
                width="100%"
                className="animate__animated animate__fadeIn"
              >
                <H5 mb={"32px"}>Modificar precio del producto</H5>
                <Paragraphs lineHeight={"22px"} mb={"32px"}>
                  El precio no debe incluir IVA
                </Paragraphs>
                <Flex justifyContent="space-around" width="100%" mb={"32px"}>
                  <Select
                    borderRadius={"15px"}
                    borderWidth={"1px"}
                    borderColor={"brand.border"}
                    h={"56px"}
                    bg={"white"}
                    _focus={{
                      color: "brand.primary",
                      boxShadow:
                        "0 0 1px 1.5px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                    }}
                    fontFamily={"Open Sans"}
                    fontStyle={"normal"}
                    fontWeight={300}
                    textAlign={"start"}
                    fontSize={"16px"}
                    cursor={"pointer"}
                    color={"brand.contentPrimary"}
                    minWidth="fit-content"
                    w={"100%"}
                    placeholder={"Moneda"}
                    value={currencyInput}
                    onChange={handleNewCurrency}
                    onFocus={() => {
                      setIsFocused(true);
                    }}
                  >
                    {mainState.quotes?.currencies.map((currency) => (
                      <option
                        key={currency.id}
                        value={currency.id}
                        style={{ padding: "12px 24px" }}
                      >
                        {currency.name}
                      </option>
                    ))}
                  </Select>
                  <InputBekeu
                    type="number"
                    width={"80%"}
                    ml={"20px"}
                    onWheel={(e) => e.preventDefault()}
                    value={newPrice}
                    placeholder={price}
                    onFocus={() => {
                      setIsFocused(true);
                    }}
                    onChange={handleNewPrice}
                  />
                </Flex>
                <H6 mb={"32px"}>Fecha de vigencia</H6>
                <Paragraphs fontWeight={300} lineHeight={"22px"} mb={"8px"}>
                  Desde
                </Paragraphs>
                <InputBekeu
                  size="md"
                  type="date"
                  autoComplete="off"
                  righticon={<BekeuIcon src={calendarIcon} />}
                  width={"220px"}
                  groupw="220px"
                  min={dateFormat}
                  defaultValue={moment(validityDate).format("DD-MM-YYYY")}
                  onKeyPress={(e) => e.preventDefault()}
                  className="dateInput"
                  onChange={(e) => {
                    setDateInput(e.target.value);
                  }}
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                />
                <Box
                  align-items={"center"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  mt={"32px"}
                >
                  <ButtonBekeuOutline
                    width={"100%"}
                    mr={"37px"}
                    onClick={() => {
                      onClose();
                      setNewPrice(price);
                    }}
                  >
                    Cancelar
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    my={0}
                    onClick={() => {
                      setLoading(true);
                      handleEditMyQuotesPrice(
                        newPrice,
                        dateInput,
                        currencyInput
                      );
                    }}
                    isLoading={loading}
                    isDisabled={!newPrice || newPrice === "0"}
                  >
                    Confirmar
                  </ButtonBekeu>
                </Box>
              </Flex>
            ) : (
              <Flex
                flexDirection={"column"}
                justifyContent="center"
                width="100%"
                className="animate__animated animate__fadeIn"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Img src={successCircleFill} alt="importacion ok" mt="48px" />
                  <H5 mt={"37px"} align={"center"}>
                    ¡El precio fue actualizado <br /> con éxito!
                  </H5>
                </Box>
                <Flex width={"100%"} justifyContent={"center"} mt={"32px"}>
                  <ButtonBekeuM
                    onClick={() => handleResetModal()}
                    align="center"
                    width={"fit-content"}
                    px={"75px"}
                    my={"13px"}
                  >
                    Aceptar
                  </ButtonBekeuM>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default injectIntl(connect(null, quotesActions)(MyQuotesEditPriceModal));
