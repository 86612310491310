import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  RadioGroup,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Img,
} from "@chakra-ui/react";
import { ProductDetailVariant } from "../ProductDetailVariant/ProductDetailVariant";
import { H5 } from "../../design-system/headings/H5";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import ReactImageZoom from "react-image-zoom";
import icon from "../../design-system/images/not-found.png";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="12px"
        boxShadow="md"
        _checked={{
          bg: "white",
          color: "white",
          borderColor: "brand.primary",
        }}
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        p={2}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export const ProductDetailMainCard = ({
  product,
  skeletonLoading,
  ...props
}) => {
  const [value, setValue] = useState("1");
  const options = ["1"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageZoom, setImageZoom] = useState(false);
  const [imageError, setImageError] = useState(false);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "react",
  });

  const group = getRootProps();

  let keys = product && Object.keys(product?.variants);

  const imageZoomProps = {
    width: imageError ? 100 : 400,
    zoomLensStyle: !imageError && "opacity: 0.4;background-color: gray;",
    zoomStyle:
      !imageError &&
      "box-shadow: -3px 5px 34px -21px rgba(0,0,0,0.57);border: 1px lightgray solid ;background-color: white;",
    img: imageError ? icon : product?.product_detail?.image_source,
  };

  let imageZoomProps2 = {
    zoomLensStyle: `opacity: 0.4;background-color: gray;visibility:  ${
      imageZoom ? "visible" : "hidden"
    }`,
    zoomStyle: `background-color: white;visibility: ${
      imageZoom ? "visible" : "hidden"
    }`,
    img: imageError ? icon : product?.product_detail?.image_source,
    zoomPosition: "original",
  };

  return (
    <BoxBekeu overflow="hidden" bg={"white"} {...props}>
      <SimpleGrid
        alignItems="center"
        minChildWidth="300px"
        spacing={10}
        overflow={"hidden"}
      >
        <Img
          src={product?.product_detail?.image_source}
          onError={() => setImageError(true)}
          display="none"
        />
        <Flex flexDir={"column"} p={"24px"} alignItems="center">
          <Flex
            w={"390px"}
            h={"390px"}
            justifyContent={"center"}
            zIndex={1000}
            alignItems={"center"}
            cursor={!imageError ? "pointer" : "not-allowed"}
            onClick={() => !imageError && onOpen()}
          >
            {skeletonLoading ? (
              <Spinner color="gray.300" size="lg" />
            ) : !imageError ? (
              product && <ReactImageZoom {...imageZoomProps} />
            ) : (
              <BekeuIcon
                src={icon}
                w={100}
                alt="Imagen no disponible"
                title="Imagen no disponible"
              />
            )}
            {product?.product_detail?.image_source && (
              <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
                <ModalOverlay />
                <ModalContent borderRadius={"12px"}>
                  <ModalCloseButton
                    _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                  />
                  <ModalBody p="36px">
                    <Flex
                      justifyContent={"center"}
                      onClick={() => setImageZoom(!imageZoom)}
                      overflow="hidden"
                      cursor={imageZoom ? "zoom-out" : "zoom-in"}
                    >
                      <ReactImageZoom {...imageZoomProps2} />
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </Flex>
          <RadioGroup onChange={setValue} value={value} variant={"ghost"}>
            <Stack direction="row">
              <HStack {...group}>
                {options.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio}>
                      <Flex
                        w={"40px"}
                        height={"40px"}
                        padding={"4px"}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {skeletonLoading ? (
                          <Spinner color="gray.300" size="xs" />
                        ) : (
                          <BekeuIcon
                            src={
                              imageError
                                ? icon
                                : product?.product_detail?.image_source
                            }
                          />
                        )}
                      </Flex>
                    </RadioCard>
                  );
                })}
              </HStack>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          height="100%"
          mr={"15px"}
          pl="5px"
        >
          {/*--------------- <POST MVP> ----------------*/}
          {/* <Badge
            w={"fit-content"}
            bg={"brand.primary"}
            color={"white"}
            fontWeight={400}
            borderRadius={"6px"}
            px={"10px"}
            py={"3px"}
          >
            Mas vendido
          </Badge> */}
          {/*--------------- </POST MVP> ----------------*/}
          {skeletonLoading ? (
            <Flex flexDir={"column"} height="100%" justifyContent={"center"}>
              <SkeletonText mt="4" noOfLines={2} spacing="4" />
              <SkeletonText
                noOfLines={2}
                spacing="4"
                width={"40%"}
                mt={"50px"}
              />
              <SkeletonText noOfLines={2} spacing="4" width={"35%"} mt={"8"} />
              <SkeletonText noOfLines={2} spacing="4" width={"50%"} mt={"8"} />
            </Flex>
          ) : (
            <H5> {product?.product_detail?.product_name}</H5>
          )}

          {/*--------------- <POST MVP> ----------------*/}
          {/* <Text fontWeight={30} fontSize={"16px"}>
            5.0 (10 opiniones)
          </Text> */}
          {/*--------------- </POST MVP> ----------------*/}
          {/* ------------ Variantes --------------*/}
          <Flex>
            {skeletonLoading ? (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            ) : (
              <Flex fontSize={"16px"}>
                <Flex flexDir={"column"} mt={"24px"}>
                  {keys?.map((key, id) => (
                    <Flex key={key} flexDir={"column"} w={"100%"}>
                      <Flex fontSize={"16px"} alignItems={"center"} key={id}>
                        <Text fontWeight={400} mr={"6px"}>
                          {" "}
                          {key}:{" "}
                        </Text>
                      </Flex>
                      <Flex
                        flexWrap={"wrap"}
                        flexDir="row"
                        w={"fit-content"}
                        alignItems={"center"}
                        mb={"12px"}
                      >
                        {product?.variants[key].map((p) => {
                          return (
                            <ProductDetailVariant
                              product={p}
                              type={key}
                              key={p.variant_id}
                            />
                          );
                        })}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </SimpleGrid>
    </BoxBekeu>
  );
};
