import React, { useEffect } from "react";
import {Divider, Flex, SimpleGrid, Text} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { Paginator } from "../../components/Paginator/Paginator";
import useQuery from "../../hooks/useQuery";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as cartActions } from "./ducks/approveCarts.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import moment from "moment";
import ApproveCartsItemLoader from "../../components/ApproveCartsItem/ApproveCartsItemLoader";
import { ApproveCartsBreadcrumb } from "../../components/ApproveCartsBreadcrumb/ApproveCartsBreadcrumb";
import {ApproveCartsBadge} from "../../components/ApproveCartsBadge/ApproveCartsBadge";
import ApproveCartsOrdersItem from "../../components/ApproveCartsItem/ApproveCartsOrdersItem";
import {ButtonBekeu} from "../../design-system/forms/ButtonBekeu";

const ApproveCartsPage = ({
  listApprovalCarts,
  approveCart,
  rejectCart,
  approveCartOrder,
  rejectCartOrder,
  approvalresult,
  ...props
}) => {
  const history = useHistory();
  const query = useQuery();
  const { pathname } = useLocation();

  const state = query.get("state");
  const d = query.get("d");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const from = query.get("from");
  const to = query.get("to");

  const mainState = useSelector((state) => state);
  const filteredCarts = mainState.carts_approval.carts;
  const arr = [...new Array(mainState.carts_approval.cartsPagination.totalPages)];

  useEffect(() => {
    listApprovalCarts({
      state,
      page,
      perPage,
      from,
      to,
    });
  }, [state, page, perPage, from, to, listApprovalCarts]);


  const handleApproveCart = (item) => {
    approveCart(item);
  };

  const handleRejectCart = (item) => {
    rejectCart(item);
  };

  const handleApproveCartOrder = (item) => {
    approveCartOrder(item);
  };

  const handleRejectCartOrder = (item) => {
    rejectCartOrder(item);
  };

  const handlePagination = () => {
    listApprovalCarts({
      state,
      page,
      perPage,
      from,
      to,
    });
  };


  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  return (
    <Flex justifyContent={"center"} /*{...props}*/>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <ApproveCartsBreadcrumb />

        <H5 mt={"32px"} /*{...props}*/>Aprobar pedidos</H5>
        <SimpleGrid mt={"32px"} minChildWidth="200px" spacing={4} /*{...props}*/>


          {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
          {/* <InputBekeu
          disabled={true}
          placeholder={"Buscar"}
          bg={"white"}
          lefticon={<Img src={search} mr={4} />}
          w={"100%"}
        /> */}
          <MenuBekeu
            height={"56px"}
            mr={"16px"}
            zIndex={9999}
            items={[
              { name: "Pendiente", state: "1" },
              { name: "Aprobada", state: "2" },
              { name: "Rechazada", state: "3" },
            ]}
          >
            {state && state === "1" ? "Pendiente"
              : state === "2" ? "Aprobada"
              : state === "3" ? "Rechazada"
              : "Pendiente"}
          </MenuBekeu>
          {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
          {/* <MenuBekeu
          disabled={true}
          height={"56px"}
          mr={"16px"}
          items={[
            { name: "felipe@empresa.com.ar", user: "felipe@empresa.com.ar" },
            {
              name: "usuario@empresa.com.ar",
              user: "usuario@empresa.com.ar",
            },
            {
              name: "usuario@empresa.com.ar",
              user: "usuario@empresa.com.ar",
            },
          ]}
        >
          Usuario
        </MenuBekeu> */}
        <MenuBekeu
            height={"56px"}
            leftIcon
            setMoment={setMoment}
            state={state}
            items={[
              {
                name: "Todas",
                date: "Todas",
                to: formattedDate,
                d: 0,
              },
              {
                name: "Este mes",
                date: "Este mes",
                to: formattedDate,
                d: 1,
              },
              {
                name: "Últimos 3 meses",
                date: "Últimos 3 meses",
                to: formattedDate,
                d: 3,
              },
              {
                name: "Últimos 6 meses",
                date: "Últimos 3 meses",
                to: formattedDate,
                d: 6,
              },
              {
                name: "Últimos 12 meses",
                date: "Últimos 3 meses",
                to: formattedDate,
                d: 12,
              },
            ]}
          >
            {d
              ? d === "0" ? "Todas"
                : d === "1" ? "Este mes"
                : d === "3" ? "Últimos 3 meses"
                : d === "6" ? "Últimos 6 meses"
                : d === "12" && "Últimos 12 meses"
              : "Todas"}
          </MenuBekeu>
          {/*<Flex w={"100%"}>
            <ButtonBekeuOutline
              onClick={() => history.push("/approve-orders/delegate-orders")}
              bg={"brand.background"}
              height={"56px"}
            >
              Delegación de Aprobaciones
            </ButtonBekeuOutline>
          </Flex>*/}
        </SimpleGrid>
        {!mainState.carts_approval.approvalLoading ? (
          <>
            {filteredCarts && filteredCarts.length > 0 ? (
                filteredCarts.map((cart, id) => {
                    return (
                        <ApproveCartsOrdersItem
                            mt={"32px"}
                            type={cart.state}
                            key={id}
                            item={cart}
                            state={cart?.state === "1" || cart?.state === "0"}
                            handleApproveCart={handleApproveCart}
                            handleRejectCart={handleRejectCart}
                            handlePagination={handlePagination}
                        />
                    )}
                )
            ) : (
              <Text mt={6} zIndex={-1} className="animate__animated animate__fadeIn">
                No se encontraron pedidos pendientes de aprobación
              </Text>
            )}
            {!mainState.carts_approval.approvalLoading &&
              mainState.carts_approval &&
              mainState.carts_approval.cartsPagination.totalPages > 1 && (
                <Paginator
                  totalItems={mainState.carts_approval.cartsPagination.totalPages}
                  itemsArray={arr}
                  perPage={5}
                  mt={"24px"}
                />
              )}
          </>
        ) : (
          [1, 2].map((cart, index) => (
            <ApproveCartsItemLoader mt={"32px"} key={index} cart={cart} />
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...cartActions, ...product.actions })(ApproveCartsPage)
);



