import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import useQuery from "../../../hooks/useQuery.jsx";
import {
  listProductsGrid,
  listProductsGridByTerm,
  getOneProduct,
  listProductsGridFilters,
} from "../cruds/productsGrid.crud.js";

export const actionTypes = {
  CREATE_CATEGORY: "CREATE_CATEGORY",
  FILL_RESULT_CATEGORY: "FILL_RESULT_CATEGORY",
  LIST_PRODUCTSGRID: "LIST_PRODUCTSGRID",
  LIST_PRODUCTSGRID_BYTERM: "LIST_PRODUCTSGRID_BYTERM",
  LIST_FILL_PRODUCTSGRID: "LIST_FILL_PRODUCTSGRID",
  GET_CATEGORY: "GET_CATEGORY",
  FILL_ONE_CATEGORY: "FILL_ONE_CATEGORY",
  FILL_LIST_CATEGORIES: "FILL_LIST_CATEGORIES",
  FILL_CATEGORIES_PAGINATION_DATA: "FILL_CATEGORIES_PAGINATION_DATA",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  FILL_PRODUCTS_GRID_PAGINATION_DATA: "FILL_PRODUCTS_GRID_PAGINATION_DATA",
  SET_LIST_RESULT: "SET_LIST_RESULT",
  GET_ONE_PRODUCT: "GET_ONE_PRODUCT",
  FILL_ONE_PRODUCT: "FILL_ONE_PRODUCT",
  LIST_PRODUCTSGRID_FILTERS: "LIST_PRODUCTSGRID_FILTERS",
  FILL_PRODUCTSGRID_FILTERS: "FILL_PRODUCTSGRID_FILTERS",
  FILTER_RESULT: "FILTER_RESULT",
};

const initialState = {
  products: [],
  product: null,
  loading: false,
  productsGridPagination: {
    currentPage: 1,
    perPage: 20,
    totalPages: 1,
    totalRows: 0,
  },
  resultGrid: null,
  productsGridFilters: {
    categories: [],
    brands: [],
    regions: [],
    prices: { maximo: 1000000, minimo: 0 },
    delivery_time: { maximo: 999, minimo: 0 },
  },
  filterResult: null,
};

export const reducer = persistReducer(
    { storage, key: "bekeu-products", whitelist: ["productsGrid"] },
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.CREATE_CATEGORY: {
          return {
            ...state,
            loading: true,
          };
        }
        case actionTypes.FILL_RESULT_CATEGORY: {
          return {
            ...state,
            loading: false,
            result: action.payload,
          };
        }
        case actionTypes.LIST_PRODUCTSGRID: {
          return {
            ...state,
            products: [],
            loading: true,
            filterResult: null,
          };
        }
        case actionTypes.LIST_PRODUCTSGRID_FILTERS: {
          return {
            ...state,
          };
        }
        case actionTypes.LIST_PRODUCTSGRID_BYTERM: {
          return {
            ...state,
            products: [],
            loading: true,
            filterResult: null,
          };
        }
        case actionTypes.LIST_FILL_PRODUCTSGRID: {
          return {
            ...state,
            loading: false,
            products: action.payload,
          };
        }
        case actionTypes.FILL_LIST_CATEGORIES: {
          return {
            ...state,
            categories: action.payload,
          };
        }

        case actionTypes.FILL_PRODUCTSGRID_FILTERS: {
          return {
            ...state,

            productsGridFilters: action.payload,
          };
        }

        case actionTypes.FILL_PRODUCTS_GRID_PAGINATION_DATA: {
          return {
            ...state,
            productsGridPagination: action.payload,
          };
        }

        case actionTypes.SET_LIST_RESULT: {
          return {
            ...state,
            resultGrid: action.payload,
          };
        }

        case actionTypes.GET_ONE_PRODUCT: {
          return {
            ...state,
            loading: true,
          };
        }

        case actionTypes.FILL_ONE_PRODUCT: {
          return {
            ...state,
            loading: false,
            product: action.payload,
          };
        }
        case actionTypes.FILTER_RESULT: {
          return {
            ...state,
            filterResult: action.payload,
          };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  createCategory: (value) => ({
    type: actionTypes.CREATE_CATEGORY,
    payload: value,
  }),
  fillResultCategory: (value) => ({
    type: actionTypes.FILL_RESULT_CATEGORY,
    payload: value,
  }),
  listProductsGrid: (value) => ({
    type: actionTypes.LIST_PRODUCTSGRID,
    payload: value,
  }),
  listProductsGridFilters: (value) => ({
    type: actionTypes.LIST_PRODUCTSGRID_FILTERS,
    payload: value,
  }),
  listProductsGridByTerm: (value) => ({
    type: actionTypes.LIST_PRODUCTSGRID_BYTERM,
    payload: value,
  }),
  fillProductsGrid: (value) => ({
    type: actionTypes.LIST_FILL_PRODUCTSGRID,
    payload: value,
  }),
  getCategory: (value) => ({ type: actionTypes.GET_CATEGORY, payload: value }),
  fillOneCategory: (value) => ({
    type: actionTypes.FILL_ONE_CATEGORY,
    payload: value,
  }),
  fillListCategories: (value) => ({
    type: actionTypes.FILL_LIST_CATEGORIES,
    payload: value,
  }),
  fillProductsGridFilters: (value) => ({
    type: actionTypes.FILL_PRODUCTSGRID_FILTERS,
    payload: value,
  }),
  fillCategoriesPaginationData: (value) => ({
    type: actionTypes.FILL_CATEGORIES_PAGINATION_DATA,
    payload: value,
  }),
  updateCategory: (value) => ({
    type: actionTypes.UPDATE_CATEGORY,
    payload: value,
  }),
  deleteCategory: (value) => ({
    type: actionTypes.DELETE_CATEGORY,
    payload: value,
  }),
  fillProductsGridPaginationData: (value) => ({
    type: actionTypes.FILL_PRODUCTS_GRID_PAGINATION_DATA,
    payload: value,
  }),
  setListResult: (value) => ({
    type: actionTypes.SET_LIST_RESULT,
    payload: value,
  }),
  getOneProduct: (value) => ({
    type: actionTypes.GET_ONE_PRODUCT,
    payload: value,
  }),
  fillOneProduct: (value) => ({
    type: actionTypes.FILL_ONE_PRODUCT,
    payload: value,
  }),
  fillFilterResult: (value) => ({
    type: actionTypes.FILTER_RESULT,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(
      actionTypes.LIST_PRODUCTSGRID,
      function* listProductsGridSaga({ payload }) {
        try {
          const response = yield listProductsGrid(payload);

          const data = response.data.message.data;
          const pagination = response.data.message.pagination;

          if (response.status === 200) {
            yield put(actions.fillProductsGrid(data));
            yield put(actions.fillProductsGridPaginationData(pagination));
            yield put(actions.setListResult(true));
          }
        } catch (err) {
          console.log(err);
        }
      }
  );

  yield takeLatest(
      actionTypes.LIST_PRODUCTSGRID_BYTERM,
      function* listProductsGridByTermSaga({ payload }) {
        try {
          const response = yield listProductsGridByTerm(payload);

          const data = response.data.message.data;
          const pagination = response.data.message.pagination;

          if (response.status === 200) {
            yield put(actions.fillProductsGrid(data));
            yield put(actions.fillProductsGridPaginationData(pagination));
            yield put(actions.setListResult(true));
          }
        } catch (err) {
          console.log(err);
        }
      }
  );

  yield takeLatest(
      actionTypes.LIST_PRODUCTSGRID_FILTERS,
      function* listProductsGridFiltersSaga({ payload }) {
        try {
          const response = yield listProductsGridFilters(payload);

          const data = response.data.message;

          if(!data.delivery_time) {
            data.delivery_time = [];
            data.delivery_time.max = 999;
            data.delivery_time.min = 999;
          }

          if(!data.prices) {
            data.prices = [];
            data.prices.min = 0;
            data.prices.max = 99999999;
          }
          /*
          if (!data.delivery_time?.max && !data.delivery_time?.min) {
            data.delivery_time.max = 999;
            data.delivery_time.min = 999;
          }

          if (data.delivery_time?.max === data.delivery_time?.min) {
            data.delivery_time.max = 999;
            data.delivery_time.min = 999;
          }*/

          if (response.status === 200) {
            yield put(actions.fillProductsGridFilters(data));
            yield put(actions.fillFilterResult(true));
          }
        } catch (err) {
          yield put(actions.fillFilterResult("error"));
        }
      }
  );

  yield takeLatest(actionTypes.GET_ONE_PRODUCT, function* getOneProductSaga({
    payload,
  }) {
    try {
      const response = yield getOneProduct(payload);
      const data = response.data.message;
      if (response.status === 200) {
        yield put(actions.fillOneProduct(data));
      }
    } catch (err) {
      console.log(err);
    }
  });
}
