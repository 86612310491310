import { objectFilter } from "@chakra-ui/utils";
import axios from "axios";
import { typeOf } from "react-is";
import { urlsBase } from "../../../../config";
import {CART_URL} from "../../../services/cart.crud";

export const PRODUCT_URL = `${urlsBase.base_url}/api/v1/search`;

export function listProductsGrid(payload) {

  /*let requestProducts = `?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload && payload.category_id && payload.category_id !== []) {
    requestProducts += `&category_id=${payload.category_id}`;
  }

  if (payload && payload.brand_id && payload.brand_id !== []) {
    requestProducts += `&brand_id=${payload.brand_id}`;
  }

  if (payload && payload.price && payload.price !== []) {
    requestProducts += `&price_min=${payload.price[0]}&price_max=${payload.price[1]}`;
  }

  if (payload && payload.delivery_zone && payload.delivery_zone !== []) {
    requestProducts += `&zone_available_qty=${payload.delivery_zone}`;
  }

  if (payload && payload.delivery_days && payload.delivery_days !== []) {
    requestProducts += `&delivery_days=${payload.delivery_days}`;
  }

  if (payload && payload.term && payload.term !== []) {
    requestProducts += `&term=${payload.term}`;
  }
   */
  let requestProducts = {
    page: payload.page,
    per_page: payload.perPage,
  };

  if (payload && payload.category_id && payload.category_id.length > 0) {
    requestProducts.category_id = payload.category_id;
  }

  if (payload && payload.brand_id && payload.brand_id.length > 0 && payload.brand_id[0] && (!Array.isArray(payload.brand_id[0]))) {
    requestProducts.brand_id = payload.brand_id[0];
  }
/*
  if (payload && payload.price && payload.price.length > 0) {
    requestProducts.price_min = payload.price[0];
    requestProducts.price_max = payload.price[1];
  }

  if (payload && payload.delivery_zone && payload.delivery_zone.length > 0) {
    requestProducts.zone_available_qty = payload.delivery_zone;
  }

  if (payload && payload.delivery_days && payload.delivery_days.length > 0) {
    requestProducts.delivery_days = payload.delivery_days;
  }
*/
  if (payload && payload.term && payload.term.length > 0) {
    requestProducts.term = payload.term;
  }

  return axios.put(`${PRODUCT_URL}`, requestProducts);
}

export function listProductsGridByTerm(payload) {

  return axios.put(`${PRODUCT_URL}`, {
    page: payload.page,
    per_page: payload.perPage,
    term: payload.term,
  });
}

export function listProductsGridFilters(payload) {
  let requestProducts = {
    page: payload.page,
    perPage: payload.perPage,
    category_id: null,
    brand_id: null,
    price_min: null,
    price_max: null,
    zone_available_qty: null,
    delivery_days: null,
    term: null
  };

  if (payload && payload.category_id && payload.category_id.length > 0) {
    requestProducts.category_id = payload.category_id;
  }

  if (payload && payload.brand_id && payload.brand_id.length > 0 && payload.brand_id[0] && (!Array.isArray(payload.brand_id[0]))) {
      requestProducts.brand_id = payload.brand_id[0];
  }
/*
  if (payload && payload.price && payload.price.length > 0) {
    requestProducts.price_min = payload.price[0];
    requestProducts.price_max = payload.price[1];
  }

  if (payload && payload.delivery_zone && payload.delivery_zone.length > 0) {
    requestProducts.zone_available_qty = payload.delivery_zone[0][0];
  }

  if (payload && payload.delivery_days && payload.delivery_days.length > 0) {
    requestProducts.delivery_days = payload.delivery_days;
  }
*/
  if (payload && payload.term && payload.term.length > 0) {
    requestProducts.term = payload.term;
  }

  return axios.put(`${PRODUCT_URL}/filters`, requestProducts);
}

export function getOneProduct(payload) {
  return axios.get(`${PRODUCT_URL}/${payload}`);
}
