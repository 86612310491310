import React, { useState } from "react";
import "./LoginPage.css";
import bekeuLogo from "../login/bekeuLogo.png";
import arrowLeft from "./assets/arrow-left.png";
import { LoginInput } from "./Components/LoginInput";
import { Box, Button, Flex, Img, Select, Text } from "@chakra-ui/react";
import { LoginButton } from "./Components/LoginButton";
import { login } from "./crud/auth.crud";
import * as auth from "./ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import useQuery from "../../hooks/useQuery";
import { loginRequest } from '../../../AuthConfig';
import { callMsGraph } from '../../../graph';
import { useMsal } from '@azure/msal-react';

const LoginPage = (props) => {
  //email and password states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState(2);
  const [incorrectEmailValidation, setIncorrectEmailValidation] = useState(
    false
  );
  
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const query = useQuery();

  function requestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response));
      });
  }

  const handleSingleSignOn = (loginType = 'popup') => {
      if (loginType === "popup") {
        instance.loginPopup(loginRequest)
        .then((res) => {
          console.log(res);



          login(res.account.username, '0', '0', res.accessToken)
            .then(({ data }) => {
              if (data.message.user?.password_changed_at === null) {
                props.login({
                  authToken: data.message.authorisation?.token,
                  resetToken: data.message.actionables?.token,
                  resetPassword: true,
                  user: data.message?.user,
                });
                props.history.push(
                  `/create-password?email=${data.message.user.email}&token=${data.message.actionables.token}`
                );
                props.setLoading(false);
              } else {
                props.login({ authToken: data.message.authorisation?.token });
                props.fulfillUser(data.message?.user);
                props.setLoading(false);
                setIncorrectEmailValidation(true);
              }
            })
            .catch((err) => {
              console.log(err);
              props.setLoading(false);
              setIncorrectEmailValidation(true);
            });



        })
        .catch((e) => {
          console.log(e);
          props.setLoading(false);
          setIncorrectEmailValidation(true);
        });
      } else if (loginType === "redirect") {
        instance.loginRedirect(loginRequest).catch((e) => {
          console.log(e);
        });
      }
  }

  //const state = query.get("state");
  //const d = query.get("d");

  // setting email and password
  const handleValidationEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleValidationPassword = (e) => {
    setPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };

  const handleValidationUserType = (type) => {
    setUserType(type);
  };

  const handleLogin = (e) => {
    props.setLoading("Loading");
    setIncorrectEmailValidation(false);
    login(email, password, userType)
      .then(({ data }) => {
        if (data.message.user?.password_changed_at === null) {
          props.login({
            authToken: data.message.authorisation?.token,
            resetToken: data.message.actionables?.token,
            resetPassword: true,
            user: data.message?.user,
          });
          props.history.push(
            `/create-password?email=${data.message.user.email}&token=${data.message.actionables.token}`
          );
          props.setLoading(false);
        } else {
          props.login({ authToken: data.message.authorisation?.token });
          props.fulfillUser(data.message?.user);
          props.setLoading(false);
          setIncorrectEmailValidation(true);
        }
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        setIncorrectEmailValidation(true);
      });
  };

  //email and password validation states
  const emailValidation = email.length < 8 && email.length !== 0 ? true : false;
  const passwordValidation =
    password.length < 8 && password.length !== 0 ? true : false;
  const buttonValidation =
    email.length > 0 && email !== "" && password.length > 0 && password !== "";

  return (
    <>
      <Flex
        bg={"brand.primary"}
        h={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
        className="fade"
        /*{...props}*/
      >
        <Box
          bg={"brand.white"}
          w={"471px"}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
        >
          <Flex
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            minH={"540px"}
          >
            <Flex flexDir={"column"} ml={"10%"} w={"100%"}>
              <Flex justifyContent={"space-between"} w={"55%"}>
                <Button
                  variant={"ghost"}
                  borderRadius={999}
                  m={0}
                  p={0}
                  onClick={() => props.history.goBack()}
                >
                  <Img src={arrowLeft} alt="back" h={"fit-content"} />
                </Button>
                <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
              </Flex>
            </Flex>
            <Flex flexDir={"column"} alignItems={"center"} fontSize={"24px"}>
              <Text fontWeight={"normal"} lineHeight="2rem">
                Te damos la bienvenida a
              </Text>
              <Text fontWeight={"semibold"} lineHeight="2rem">
                Bekeu Marketplace
              </Text>
            </Flex>
            <Flex flexDir={"column"} w={"75%"} alignItems={"center"}>
              <LoginInput
                placeholder="Email"
                display={"none"}
                isInvalid={emailValidation || incorrectEmailValidation}
                value={email}
                onChange={handleValidationEmail}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              <LoginInput
                placeholder="Contraseña"
                isInvalid={emailValidation || incorrectEmailValidation}
                onChange={handleValidationPassword}
                isPassword={true}
                value={password}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              <MenuBekeu
                items={[
                  { id: 2, name: "Comprador" },
                  { id: 3, name: "Vendedor" },
                ]}
                fontWeight={400}
                borderRadius={"12px"}
                height={50}
                customOnClick={handleValidationUserType}
                isInvalid={emailValidation || incorrectEmailValidation}
              >
                Comprador
              </MenuBekeu>

              {incorrectEmailValidation && (
                <Text
                  color={"brand.error"}
                  fontSize={"12px"}
                  fontWeight={300}
                  mt="12px"
                  className="animate__animated animate__fadeIn"
                >
                  Revisá los datos ingresados.
                </Text>
              )}
            </Flex>
            <Flex flexDir={"column"} w={"100%"} alignItems="center">
              <LoginButton
                isLoading={props.isLoading}
                isDisabled={!buttonValidation}
                onClick={handleLogin}
                text="Ingresar"
              />
              <ButtonBekeuLink
                onClick={() => props.history.push("/forgot-password")}
              >
                ¿Olvidaste tu contraseña?
              </ButtonBekeuLink>
            </Flex>
            
            <Flex flexDir={"column"} w={"100%"} alignItems="center">
              <ButtonBekeuLink
                onClick={() => handleSingleSignOn()}
              >
                Login Con Azure
              </ButtonBekeuLink>
            </Flex>
             
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(LoginPage));
