import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H6 } from "../../design-system/headings/H6";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";

export const ApproveCartDetail = ({
  item,
  showitems,
  totalItems,
  productName,
}) => {
  const history = useHistory();

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0 });
  }

  const ARS = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS',});
  const USD = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'USD',});

  return (
    !showitems && (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        minH="160px"
        p="24px"
      >
        <Flex flexDir={"column"} w="50%" gap="12px" minH="160px">
          <H6 color={"brand.primary"}>
            <Text
              /*cursor={"pointer"}*/
              /*onClick={() => {
                handleScrollTo();
                history.push(
                  `/my-orders/order-detail/${item?.id}/?state=${item?.state}`
                );
              }}*/
            >
              {item?.buyOrders && totalItems > 1
                ? `${totalItems} productos `
                : item.buyOrders[0].items[0].sku + " " + item.buyOrders[0].items[0].combination.product.name}
            </Text>
          </H6>
          {/*}
          <Text
            fontWeight={400}
            fontSize="16px"
            lineHeight={"22px"}
            noOfLines={2}
          >
            Dirección de Entrega: {item?.address_description}
          </Text>*/}
          {/* <Flex flexDir={"column"}>
            <Text>Centro de costo: </Text>
            {item?.cost_centers?.map((costCenter, index) => (
              <Flex key={index} w="10rem" justifyContent={"space-between"}>
                <Text>{costCenter.cost_center}</Text>
                <Text>{costCenter.percentage}%</Text>
              </Flex>
            ))}
          </Flex> */}
          <Flex gap={2} alignItems="center">
            <Text>Seller: </Text>
            {item?.buyOrders?.length === 1
              ? item?.buyOrders?.map((order, id) => {
                  return (
                    <ProductDetailModalSeller
                      sellerName={order.provider?.fantasy_name}
                      email={order.provider?.email}
                      phone={order.provider?.phone}
                      key={id}
                    />
                  );
                })
              : item?.buyOrders?.length > 1 && (
                  <ButtonBekeuLink
                    color={"brand.primary"}
                    onClick={() =>
                      history.push(
                        `/my-orders/order-detail/${item?.id}/?state=${item?.state}`
                      )
                    }
                  >
                    {item?.buyOrders?.length} sellers
                  </ButtonBekeuLink>
                )}
          </Flex>
        </Flex>
        <Flex width="40%" gap="50px" justifyContent={"flex-end"}>
          {item?.total_ARP && parseInt(item?.total_ARP) !== 0 ? (
            <Flex flexDir={"column"} justifyContent="space-between" gap="24px">
              <Flex flexDir={"column"} gap="8px">
                <Text
                  color={"brand.contentSecondary"}
                  fontWeight={400}
                  fontSize="16px"
                >
                  Total productos en ARS:{" "}
                </Text>
                <Text fontWeight={600} fontSize="16px">
                  {ARS.format(parseFloat(item?.total_ARP))} + Imp
                </Text>
              </Flex>
              {<Flex flexDir={"column"} gap="8px">
                <Text>Total costo de envíos en ARS: </Text>
                {!item?.shipping_cost_ARP || item?.shipping_cost_ARP === 0 ? (
                  <Text fontWeight={600} fontSize="16px" color="brand.success">
                    Envío incluído
                  </Text>
                ) : (
                  <Text fontWeight={600} fontSize="16px">
                    {ARS.format(parseFloat(item?.shipping_cost_ARP))} + Imp
                  </Text>
                )}
              </Flex>}
            </Flex>
          ):(<></>)}
          {item?.total_USD && parseInt(item?.total_USD) !== 0 ? (
            <Flex flexDir={"column"} justifyContent="space-between" gap="24px">
              <Flex flexDir={"column"} gap="8px">
                <Text
                  color={"brand.contentSecondary"}
                  fontWeight={400}
                  fontSize="16px"
                >
                  Total productos en USD:{" "}
                </Text>
                <Text fontWeight={600} fontSize="16px">
                  {USD.format(parseFloat(item?.total_USD))} + Imp
                </Text>
              </Flex>
              <Flex flexDir={"column"} gap="8px">
                <Text>Total costo de envíos en USD: </Text>
                {!item?.shipping_cost_USD || item?.shipping_cost_USD === 0 ? (
                  <Text fontWeight={600} fontSize="16px" color="brand.success">
                    Envío incluído
                  </Text>
                ) : (
                  <Text fontWeight={600} fontSize="16px">
                    {USD.format(parseFloat(item?.shipping_cost_USD))} + Imp
                  </Text>
                )}
              </Flex>
            </Flex>
          ):(<></>)}
        </Flex>
        {/*} <Link
          to={`/my-orders/order-detail/${item?.id}/?state=${item?.state}`}
          _focus={{ boxShadow: "rgba(0,0,0,0)" }}
        >
          <ButtonBekeu
            w={"200px"}
            ml="50px"
            onClick={() => {
              handleScrollTo();
            }}
          >
            Ver compra
          </ButtonBekeu>
        </Link>*/}
      </Flex>
    )
  );
};
