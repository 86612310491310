import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Progress,
} from "@chakra-ui/react";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { H6 } from "../../design-system/headings/H6";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import search from "../../design-system/icons/Search/search-normal.svg";
import helpCircle from "../../design-system/icons/Shipping/help-circle.svg";
import { ClientItem } from "../../components/WhiteList/ClientItem";
import { ClientTitle } from "../../components/WhiteList/ClientTitle";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { ErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary"

import { actions as clients } from "./ducks/ProviderWhiteList.duck";

import { bindActionCreators } from "redux";

import { useSelector } from "react-redux";
import { useState } from "react";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { ClientLoader } from "../../components/WhiteList/ClientLoader";

const ProviderWhiteListPage = ({
  listClients,
  fillClientsResult,
  enableClient,
  disableClient,
  ...props
}) => {
  // const [menuButton, setMenuButton] = useState("Mas relevantes");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [listClientsTypeAction, setListClientsTypeAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [percentageDisable, setPercentageDisable] = useState(false);
  const [clientInput, setClientInput] = useState("");

  const {
    isOpen: isPauseOpen,
    onOpen: onPauseOpen,
    onClose: onPauseClose,
  } = useDisclosure();

  const {
    isOpen: isModifyOpen,
    onOpen: onModifyOpen,
    onClose: onModifyClose,
  } = useDisclosure();

  const mainState = useSelector((state) => state);

  useEffect(() => {
    listClients();
  }, [listClients]);

  /*useEffect(() => {
    if (mainState.clients) {
      //fillClientsResult();
    }
  }, [mainState.clients, fillClientsResult]);*/

  function handleEnable(item) {
    enableClient({ company_id: item });
  }

  function handleDisable(item) {
    disableClient({ company_id: item });
  }

  function handleMassiveAction() {
    setLoading(true);
    /*listClientsTypeAction === "activate"
        ? props.massiveActivate()
        : listClientsTypeAction === "pause" && props.massivePause();*/
  }

  const ClientTitleTemplate = [
    {
      id: 1,
      enable_provider: "Habilitar",
      name: "Nombre",
      alias: "Alias",
      payment_condition: "Condición de Pago",
      company_group: "Pertenece al grupo",
      internal_code: "Código interno",
      company_id: "# Compañía",
      state: "Estado",
      active: "Activo"
    },
  ];

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <ApproveOrdersBreadcrumb />
        <AlertBekeu my={"32px"}>
          Recordá activar las compañías a las que quieras venderle.
        </AlertBekeu>
        <H5>Activación de Clientes</H5>
        {mainState?.clients?.loading ? (
          <ErrorBoundary fallback={<p>Something went wrong at Loading</p>}>
            <BoxBekeu w="100%" mt={"32px"} p="0">
              <Flex justifyContent={"space-between"} px="24px">
                <H6 py="39px">Clientes Disponibles</H6>
                <Flex alignItems={"center"}>
                  <InputBekeu
                    placeholder={"Buscar por firma"}
                    bg={"white"}
                    lefticon={<BekeuIcon src={search} mr={4} />}
                    mr={"32px"}
                    groupw="300px"
                    value={clientInput}
                    onChange={(e) => setClientInput(e.target.value)}
                  />
                  <Menu>
                    <MenuButton
                      as={Button}
                      bg="#FFFFFF"
                      border="1.5px solid"
                      borderColor={"brand.contentSecondary"}
                      borderRadius="5px"
                      m="0 12px"
                      height="20px"
                      minWidth="20px"
                      padding="0 0 9px 0"
                      transform="rotate(90deg)"
                      _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                    >
                      ...
                    </MenuButton>
                  </Menu>
                </Flex>
              </Flex>
              <Divider />
              {ClientTitleTemplate.map((client, id) => (
                  <ClientTitle
                    key={client.id}
                    item={client}
                    infobtn={
                      <Button
                        variant={"ghost"}
                        borderRadius={"12px"}
                        m={2}
                        p={0}
                        _focus={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                        }}
                        onClick={onOpen}
                      >
                        <BekeuIcon src={helpCircle} />
                      </Button>
                    }
                  />
              ))}

            </BoxBekeu>
          </ErrorBoundary>
        ) : (
          <ErrorBoundary fallback={<p>Something went wrong Loaded Clients</p>}>
            <BoxBekeu w="100%" mt={"32px"} p="0">
              <Flex justifyContent={"space-between"} px="24px">
                <H6 py="39px">Clientes Activos</H6>
                <Flex alignItems={"center"}>
                  <InputBekeu
                    placeholder={"Buscar"}
                    bg={"white"}
                    lefticon={<BekeuIcon src={search} mr={4} />}
                    mr={"32px"}
                    groupw="300px"
                    value={clientInput}
                    onChange={(e) => setClientInput(e.target.value)}
                  />
                  <ErrorBoundary fallback={<p>Something went wrong Menu</p>}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      bg="#FFFFFF"
                      border="1.5px solid"
                      borderColor={"brand.contentSecondary"}
                      borderRadius="5px"
                      m="0 12px"
                      height="20px"
                      minWidth="20px"
                      padding="0 0 9px 0"
                      transform="rotate(90deg)"
                      _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                    >
                      ...
                    </MenuButton>

                    <Modal
                      isOpen={isPauseOpen}
                      onClose={onPauseClose}
                      onCloseComplete={() => {
                        setLoading(false);
                        mainState?.clients?.result && listClients();
                      }}
                      size="xl"
                      isCentered
                    >
                      <ModalOverlay />
                      <ModalContent
                        borderRadius="12px"
                        fontFamily={"Open Sans"}
                        flexDir={"row"}
                      >
                        <ModalCloseButton
                          _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                        />
                        {mainState?.clients?.result ? (
                          <ModalBody
                            borderRadius={"12px"}
                            m={"20px"}
                            textAlign="center"
                            p="24px"
                          >
                            <Flex flexDir={"column"} alignItems={"center"}>
                              <BekeuIcon src={success} mb={"24px"} />
                              <H5 mb={"24px"}>Cambios realizados con éxito</H5>
                              <ButtonBekeu
                                mr="12px"
                                onClick={onPauseClose}
                                my={0}
                                px="70px"
                                w="fit-content"
                              >
                                Aceptar
                              </ButtonBekeu>
                            </Flex>
                          </ModalBody>
                        ) : (
                          <ModalBody
                            borderRadius={"12px"}
                            m={"20px"}
                            textAlign="center"
                            p="24px"
                          >
                            <H5 mb={"24px"}>
                              {/*listClientsTypeAction === "pause"
                                ? `¿Estás seguro que querés pausar todos tus clientes?`
                                : listClientsTypeAction === "activate" &&
                                  `¿Estás seguro que querés activar todos tus clientes?`
                                  */}
                            </H5>
                            <Paragraphs mb={"24px"} lineHeight="22px">
                              {/*listClientsTypeAction === "pause"
                                ? "Al pausar todos tus clientes tus productos no estarán disponibles para comprar."
                                : listClientsTypeAction === "activate" &&
                                  "Al activar todos tus clientes tus productos estarán disponibles para comprar."
                                  */}
                            </Paragraphs>
                            <Flex w="100%">
                              <ButtonBekeuOutline
                                mr="12px"
                                onClick={onPauseClose}
                              >
                                No, Cancelar
                              </ButtonBekeuOutline>
                              <ButtonBekeu
                                my="0"
                                ml="12px"
                                onClick={handleMassiveAction}
                                isLoading={loading}
                              >
                                Si, Confirmar
                              </ButtonBekeu>
                            </Flex>
                          </ModalBody>
                        )}
                      </ModalContent>
                    </Modal>

                    <MenuList bg={"white"} borderRadius={"15px"} py={"12px"}>
                      <MenuItem
                        _focus={{ bg: "brand.transparent" }}
                        p={"12px 24px"}
                        _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                        bgColor="white"
                        onClick={() => {
                          onPauseOpen();
                          /*setlistClientsTypeAction("activate");*/
                        }}
                      >
                        Activar masivamente
                      </MenuItem>
                      <MenuItem
                        _focus={{ bg: "brand.transparent" }}
                        p={"12px 24px"}
                        _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                        bgColor="white"
                        onClick={() => {
                          onPauseOpen();
                          /*setlistClientsTypeAction("pause");*/
                        }}
                      >
                        Pausar masivamente
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  </ErrorBoundary>
                </Flex>
              </Flex>
              <Divider />
              <ErrorBoundary fallback={<p>Something went wrong ClientTitle</p>}>
                <></>
              {ClientTitleTemplate.map((client, id) => (
                    <ClientTitle
                      key={client.id}
                      item={client}
                      infobtn={
                        <Button
                          variant={"ghost"}
                          borderRadius={"12px"}
                          m={2}
                          p={0}
                          _focus={{
                            boxShadow: "rgba(0, 0, 0, 0)",
                          }}
                          onClick={onOpen}
                        >
                        <BekeuIcon src={helpCircle} />
                      </Button>
                    }
                    />
              ))}

              </ErrorBoundary>
              <ErrorBoundary fallback={<p>Something went wrong ClientItems</p>}>
              {mainState?.clients?.clients
                /*?.filter((clientItem) =>
                    clientItem.name
                    .toLowerCase()
                    .includes(clientInput.toLowerCase())
                )*/
                ?.map((client) => (
                      <ClientItem
                        key={client.id}
                        altKey={client.name}
                        item={client}
                        handleEnable={handleEnable}
                        handleDisable={handleDisable}
                        listClients={listClients}
                        //changeDeliveryTime={changeDeliveryTime}
                        //listShippingWithResult={listShippingWithResult}
                      />

                ))}
              </ErrorBoundary>
            </BoxBekeu>
          </ErrorBoundary>
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, { ...clients })(ProviderWhiteListPage));
