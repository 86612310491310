import {
  Avatar,
  AvatarGroup,
  Button,
  Divider,
  Flex,
  Img,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ApproveOrdersBadge } from "../ApproveOrdersBadge/ApproveOrdersBadge";
import eye from "../../design-system/icons/Security/eye.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import * as cart from "../../store/cart/cart.duck";

import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";

import { ApproveOrdersRejectModal } from "../ApproveOrdersRejectModal/ApproveOrdersRejectModal";
import { ApproveOrdersSuccessModal } from "../ApproveOrdersSuccessModal/ApproveOrdersSuccessModal";

import { ShippingBlock } from "../ShippingBlock/ShippingBlock";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useSelector } from "react-redux";
import trash from "../../design-system/icons/Cart/trash.svg";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { useState } from "react";
import notFoundImg from "../../design-system/images/not-found.png";

const DetailItem = ({
  item,
  sellerData,
  history,
  removeCartItem,
  handleDeleteSuccess,
  addItemToCart,
}) => {
  const cart = useSelector((state) => state.cart);
  const [inputValue, setInputValue] = useState(item?.quantity);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    cart.result && handleDeleteSuccess();
  }, [cart.result, handleDeleteSuccess]);

  function handleChangeCartQuantity() {
    setTimeout(() => {
      // addItemToCart(item, inputValue);
      console.log(item, inputValue);
    }, 1000);
  }

  return (
    <>
      <Flex flexDir={"column"}>
        <Flex p="24px">
          <Flex w="50%">
            <Flex
              w="88px"
              h="88px"
              mr="40px"
              border="1px solid"
              borderColor={"brand.border"}
              borderRadius={"12px"}
              overflow="hidden"
              justifyContent={"center"}
              alignItems="center"
            >
              <Img
                src={
                  imageError ? notFoundImg : item?.combination?.media?.source
                }
                onError={() => setImageError(true)}
                draggable={"false"}
                objectFit="cover"
                cursor={imageError ? "not-allowed" : "pointer"}
                title={
                  imageError ? "Imagen no disponible" : item?.combination?.title
                }
                h={imageError ? "50%" : "100%"}
              />
            </Flex>
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Flex flexDir={"column"} w={"380px"} gap="12px">
                <H6
                  onClick={() => history.push(`/detail/${item?.sku}`)}
                  cursor="pointer"
                >
                  {item?.combination?.title}
                </H6>
                <ProductDetailModalSeller
                  sellerName={sellerData?.fantasy_name}
                  email={sellerData?.email}
                  phone={sellerData?.phone}
                />
                <Flex flexDir={"column"}>
                  <Paragraphs lineHeight={"22px"} mb="3px" fontWeight={600}>
                    Imputaciones:
                  </Paragraphs>
                  {item?.cost_centers?.map((item, key) => (
                    <Flex key={key} justifyContent={"space-between"} w="100%">
                      <Paragraphs lineHeight={"22px"}>
                        {item?.cost_center}
                      </Paragraphs>
                      <Paragraphs lineHeight={"22px"}>
                        {item?.percentage}%
                      </Paragraphs>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex w="50%" justifyContent="space-between" alignItems={"center"}>
            <Flex
              flexDir={"column"}
              w="50%"
              justifyContent="space-between"
              gap="12px"
            >
              <Text>Cantidad</Text>
              <InputBekeu
                grouph="38px"
                groupw="155px"
                maxLength={3}
                defaultValue={item?.quantity}
                placeholder={item?.quantity}
                value={inputValue}
                isDisabled
                onChange={(e) => {
                  handleChangeCartQuantity();
                  setInputValue(e.target.value.replace(/[^0-9,.]/g, ""));
                }}
                pointerEvents
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleChangeCartQuantity();
                  }
                }}
              />
            </Flex>
            <Flex flexDir={"column"} w="50%" justifyContent="center">
              <H6>
                {item?.currency_code} {item?.price_unit_current} + Imp
              </H6>
            </Flex>
          </Flex>
        </Flex>

        <Divider />
      </Flex>
    </>
  );
};

const ApproveOrdersItem = ({
  item,
  type,
  showitems,
  selected,
  state,
  getOneProduct,
  handleApproveOrder,
  handleRejectOrder,
  handlePagination,
  params,
  removeCartItem,
  handleDeleteSuccess,
  addItemToCart,
  ...props
}) => {
  const history = useHistory();
  const [imageError, setImageError] = useState(false);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const parsedArs = parseInt(item?.total_ARP) !== 0;
  const parsedUsd = parseInt(item?.total_USD) !== 0;
  const parsedShippingArs = parseInt(item?.shipping_cost_ARP) === 0;
  const parsedShippingUsd = parseInt(item?.shipping_cost_USD) === 0;

  return (
    <BoxBekeu {...props}>
      <Flex flexDir={"column"}>
        <Flex alignItems={"center"} justifyContent={"space-between"} mb={30}>
          <H5>{moment(item?.created_at).format("LL")}</H5>
          <ApproveOrdersBadge type={type} />
        </Flex>
        <Divider />
        <Flex justifyContent={"space-between"} py={"24px"} gap="24px">
          <Flex flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Comprador
            </Paragraphs>
            <Paragraphs>{item?.client?.user?.name}</Paragraphs>
            <Paragraphs>{item?.client?.user?.email}</Paragraphs>
          </Flex>
          <Flex flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Código interno
            </Paragraphs>
            <Paragraphs>{item?.internal_code}</Paragraphs>
          </Flex>
          <Flex flexDir={"column"} w="20rem" lineHeight="1.5rem">
            <Paragraphs fontWeight={600}>Zona de entrega</Paragraphs>
            <Paragraphs lineHeight="1.5rem">
              {item?.address_description}
            </Paragraphs>
          </Flex>
          <Flex flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Comentarios del comprador
            </Paragraphs>
            <Paragraphs>{item?.approver_comments}</Paragraphs>
          </Flex>
          <Flex flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Total de artículos:{" "}
              {item?.buy_order_items && item?.buy_order_items.length}{" "}
            </Paragraphs>
            <AvatarGroup
              size="md"
              max={2}
              draggable={false}
              justifyContent={"space-evenly"}
            >
             
                
            </AvatarGroup>
            <Paragraphs textAlign="center"></Paragraphs>
          </Flex>
        </Flex>
        <Divider />
        {showitems &&
          item?.buy_order_items &&
          item.buy_order_items.map((product, id) => (
            <DetailItem
              key={id}
              item={product}
              sellerData={item?.provider}
              getOneProduct={getOneProduct}
              history={history}
              removeCartItem={removeCartItem}
              handleDeleteSuccess={handleDeleteSuccess}
              addItemToCart={addItemToCart}
            />
          ))}
        {showitems && (
          <>
            <ShippingBlock
              arsCost={item?.shipping_cost_ARP}
              usdCost={item?.shipping_cost_USD}
              totalArs={item?.total_ARP}
              totalUsd={item?.total_USD}
            />
            <Divider />
          </>
        )}

        <Flex flexDir={"column"} alignItems="flex-end" gap="12px" my="14px">
          {parsedArs &&
            (parsedShippingArs ? (
              <Flex gap={2}>
                <H6 fontWeight={400}>Total envío en ARS: </H6>
                <H6 fontWeight={600} color="brand.success">
                  Envío Incluído
                </H6>
              </Flex>
            ) : (
              <H6 fontWeight={400}>
                Total envío en ARS: {item?.shipping_cost_ARP} + Imp{" "}
              </H6>
            ))}
          {parsedUsd &&
            (parsedShippingUsd ? (
              <Flex gap={2}>
                <H6 fontWeight={400}>Total envío en USD: </H6>
                <H6 fontWeight={600} color="brand.success">
                  Envío Incluído
                </H6>
              </Flex>
            ) : (
              <H6 fontWeight={400}>
                Total envío en USD: {item?.shipping_cost_USD} + Imp{" "}
              </H6>
            ))}
          {parsedArs ? (
            <H6>Total en ARS: {item?.total_w_shipping_ARP} + Imp </H6>
          ) : (
            ""
          )}
          {parsedUsd ? (
            <H6>Total en USD: {item?.total_w_shipping_USD} + Imp </H6>
          ) : (
            ""
          )}
        </Flex>

        <Flex
          justifyContent={
            item?.status === "pending" && !selected
              ? "space-between"
              : "flex-start"
          }
          alignItems={"center"}
        >
          {!selected && (
            <ButtonBekeuLink
              leftIcon={<Img src={eye} />}
              onClick={() => {
                handleScrollTo();
                history.push(
                  `/approve-orders/order-detail/${item.id}/?state=${item.state}&page=1&per_page=20`
                );
              }}
              mt={"8px"}
            >
              Ver pedido
            </ButtonBekeuLink>
          )}
          {item?.state === "1" && (
            <Flex alignItems={"center"}>
              <ApproveOrdersRejectModal
                handleRejectOrder={handleRejectOrder}
                item={item}
                handlePagination={handlePagination}
                px={"50px"}
              />
              <ApproveOrdersSuccessModal
                handleApproveOrder={handleApproveOrder}
                item={item}
                handlePagination={handlePagination}
                px={"50px"}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, { ...product.actions, ...cart.actions })(ApproveOrdersItem)
);
