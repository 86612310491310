import { Box } from "@chakra-ui/react";
import React from "react";

export const CartOrderId = ({ children, ...props }) => {
  return (
    <Box
      width={"fit-content"}
      color={"brand.contentSecondary"}
      background={"white"}
      borderRadius={"12px"}
      borderWidth={"1px"}
      borderColor={"brand.contentSecondary"}
      padding={"8px"}
      fontWeight={600}
      fontSize={"16px"}
      {...props}
    >
      {children}
    </Box>
  );
};
