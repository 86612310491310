import React from "react";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, Brush, ReferenceLine, BarChart
} from "recharts";
import { scaleLinear } from "d3-scale";

import * as PropTypes from "prop-types";
import {Flex} from "@chakra-ui/react";

const monthTickFormatter = (tick: string) => {
    const date = new Date(tick);
    return date.getMonth() + 1;
};

const dayTickFormatter = (tick: string) => {
    const date = new Date(tick);
    return date.getDay() + 1;
};

const dateTickFormatter = (tick: string) => {
    const date = new Date(tick);
    return date.toLocaleDateString("en-us", { year:"numeric", month:"numeric"});
};

const renderQuarterTick = (tickProps: any) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    if (month % 3 === 1) {
        return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
    }
    const isLast = month === 11;

    if (month % 3 === 0 || isLast) {
        const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    }
    return null;
};
/*
* <!--<XAxis
                            dataKey="dateStr"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            tick={renderQuarterTick}
                            height={1}
                            scale="band"
                            xAxisId="quarter"
                        />-->
* */
const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
};


const getOrderDetail = (label) => {
    if (label === 'Page A') {
        return "Page A is about men's clothing";
    }

    return '';
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        //logErrorToMyService(error, info.componentStack);
        console.log(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label} : ${payload[0].value}`}</p>
                <p className="intro">{getOrderDetail(label)}</p>
                <p className="desc"></p>
            </div>
        );
    }

    return null;
};

export class ByZoneVerticalChart extends React.Component {
    render() {
        let {data, ...props} = this.props;

        let minDate = new Date();
        let maxDate = new Date();

        const normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,
                internal_code: cart.internal_code,
                date: new Date(new Date(cart.exported_at).getUTCFullYear(), new Date(cart.exported_at).getMonth()),
                dateStr: new Date(cart.exported_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                USD: parseFloat(cart.total_USD),
                ARS: parseFloat(cart.total_ARP),
                ARP: parseFloat(cart.total_ARP),
                quantity: 0,
                region_name: cart.region_name,
                iso_3166_2: cart.iso_3166_2,
                business_name: cart.business_name,
                fantasy_name: cart.fantasy_name

            })).sort((a, b) => a.region_name - b.region_name)
        };


        const regionsMap = normalizedData.items.reduce((dateOrders, buyOrder)=> {
            const regionIndex = dateOrders.findIndex(item => item.region_name === buyOrder.region_name);
            /*const buyOrderQuantity = buyOrder.reduce((accumulator, oOrder) =>
            {
                //accumulator = oOrder.buy_order_items.reduce((acc, oItem) => acc + oItem.quantity,0)
            },0)*/


            if(regionIndex > -1){
                dateOrders[regionIndex].dateOrders.push(buyOrder);
                dateOrders[regionIndex].USD += Number(buyOrder["sum(bov.total_USD)"]);
                dateOrders[regionIndex].ARS += Number(buyOrder["sum(bov.total_ARP)"]);
                dateOrders[regionIndex].quantity += 1;
                dateOrders[regionIndex].Cantidad += 1;
            } else {
                dateOrders.push({
                    internal_code: buyOrder.internal_code,
                    dateStr: buyOrder.dateStr,
                    date: new Date(buyOrder.date.getUTCFullYear(), buyOrder.date.getMonth()),
                    dateOrders: [buyOrder],
                    USD: Number(buyOrder["sum(bov.total_USD)"]),
                    ARS: Number(buyOrder["sum(bov.total_ARP)"]),
                    ARP: Number(buyOrder["sum(bov.total_ARP)"]),
                    quantity: 1,
                    Cantidad:1
                })
            }
            return dateOrders;
        }, []);

        regionsMap.sort((a, b) => a.region_name - b.region_name)



        return (
            <Flex height="1000">
                <ErrorBoundary fallback={<p>Something went wrong</p>}>
                    <ResponsiveContainer height="100%">
                        <BarChart
                            layout="vertical"
                            height={2000}
                            data={regionsMap}
                            stackOffset=""
                            margin={{top: 5, right: 30, left: 10, bottom: 5,}}
                        >
                            <CartesianGrid strokeDasharray="3 3" height={"200px"}/>

                            <XAxis type="number" xAxisId={0} orientation={"top"} width={"100%"}/>
                            <XAxis type="number" xAxisId={1} hide="false" width={"100%"}/>
                            <YAxis dataKey="region_name" type="category" width={200}/>
                            <Tooltip />
                            <Legend />
                            <ReferenceLine x={0} stroke="#000" />
                            <Bar dataKey="ARS" xAxisid={0} fill="#64247E" stackId="stack" />
                            <Bar dataKey="USD" xAxisid={1} fill="#4CAF50" stackId="stack" />
                        </BarChart>
                    </ResponsiveContainer>
                </ErrorBoundary>
            </Flex>
                );
    }
}

ByZoneVerticalChart.propTypes = {data: PropTypes.any};