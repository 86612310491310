import React, { useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import { H6 } from "../../design-system/headings/H6";
import { CartItem } from "../../components/CartItem/CartItem";
import { CartShipping } from "../../components/CartShipping/CartShipping";
import { CartSummary } from "../../components/CartSummary/CartSummary";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { useEffect } from "react";
import { ShippingBlock } from "../../components/ShippingBlock/ShippingBlock";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { useHistory } from "react-router-dom";
import { ShippingDetail } from "../../design-system/forms/ShippingDetail";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { actions as authActions } from "../login/ducks/auth.duck";

const CartPage = ({
  getAddress,
  getCostCenter,
  addItemToCart,
  removeCartItem,
  endsBuy,
  user,
  setCecoItem,
  getCompanies,
  deleteCecoItem,
  getCart,
  getFeatures,
  ...props
}) => {
  const history = useHistory();

  //redux state
  const cart = useSelector((state) => state.cart);
  const mainState = useSelector((state) => state);

  //useState
  const [address, setAddress] = useState("");
  const [costCenter, setCostCenter] = useState(user?.client?.cost_center);
  const [providerComments, setProviderComments] = useState("");
  const [approverComments, setApproverComments] = useState("");
  const [societyState, setSocietyState] = useState("");
  const [societyStateId, setSocietyStateId] = useState("");
  const [disabledCeco, setDisabledCeco] = useState([]);
  const [enableLogisticCenter, setEnableLogisticCenter] = useState("");
  const [enablePercentage, setEnablePercentage] = useState("");
  const [allCecos, setAllCecos] = useState({ apply: false, cecos: [] });

  //useEffect
  useEffect(() => {
    getCompanies();
    //if(!mainState.auth.features) getFeatures();
    //console.log('||||', mainState);
  }, []);

  const [sapAvailable, setSapAvailable] = useState(true);
  const [dispatchNoteAvailable, setDispatchNoteAvailable] = useState(true);
  const [shippingIncluded, setShippingIncluded] = useState(true);

  useEffect(() => {
    console.log('---', mainState);
    //console.log(mainState?.auth?.features?.find((str) => str === 'SAP'));
    if(mainState?.auth?.features?.find((str) => str === 'SAP')){
      setSapAvailable(true);
    } else {
      setSapAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'dispatch_note')){
      setDispatchNoteAvailable(true);
    } else {
      setDispatchNoteAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'shipping_included')){
      setShippingIncluded(true);
    } else {
      setShippingIncluded(false);
    }
  }, [mainState.auth.features])

  useEffect(() => {
    if (mainState.cart?.companies.length > 0) {
      setSocietyState(mainState.cart?.companies[0]?.name);
      setSocietyStateId(mainState.cart?.companies[0]?.id);
    }
  }, [mainState.cart.companies]);

  useEffect(() => {
    if (
      societyStateId &&
      mainState?.cart?.items?.items?.length !== 0 &&
      mainState?.cart?.items?.items
    ) {
      getCostCenter(societyStateId);
      getAddress(
        mainState?.cart?.items?.items &&
          mainState?.cart?.items?.items[0]?.cart_item_id,
        societyStateId
      );
    }
  }, [mainState.cart.items, societyStateId]);

  //functions
  function handleQuantityChange(item, quantity) {
    addItemToCart(item, quantity);
  }
  function handleRemoveCartItem(item) {
    removeCartItem(item);
  }
  function handleChangeAddress(event, id) {
    setAddress(id);
  }
  function handleChangeCostCenter(event) {
    setCostCenter(event.target.value);
  }
  function handleChangeProviderComments(event) {
    setProviderComments(event.target.value);
  }
  function handleChangeApproverComments(event) {
    setApproverComments(event.target.value);
  }

  function handleEndsBuy() {
    endsBuy({
      address,
      providerComments,
      approverComments,
      company: societyStateId,
    });
  }

  const handleSelectedCeco = (obj, product) => {
    setCecoItem(obj, product);
  };

  const handleDeleteCecoItem = (obj, product) => {
    deleteCecoItem(obj, product);
  };

  const handleSetSociety = (item) => {
    const deleteCecos = cart.items.items?.map((item) =>
      item?.cost_centers.map(
        (costCenter) => costCenter.id && deleteCecoItem(costCenter)
      )
    );
    setSocietyState(item.name);
    setSocietyStateId(item.id);
  };

  const handleDisabledCeco = (product) => {
    setDisabledCeco(product);
  };

  const handleGetCart = () => {
    getCart();
  };

  function handleApplyAllCecos(allCecos) {
    setAllCecos(allCecos);
  }

  const costCentersArray = cart.items?.items?.map((item) =>
    item?.cost_centers.map((costCenter) => costCenter.id)
  );

  const filteredCostCenterPos = costCentersArray?.filter(
    (costCenter) => costCenter?.length !== 0
  );

  const hasAllCecos =
    filteredCostCenterPos?.length === cart.items?.items?.length;

  const applyAllCecosArray = cart.items?.items?.map(
    (item, i) => i > 0 && item?.cost_centers.map((costCenter) => costCenter.id)
  );

  const filteredApplyAllCecosArray = applyAllCecosArray?.filter(
    (costCenter) => costCenter?.length === 0
  );

  const hasApplyAllCecos =
    filteredApplyAllCecosArray?.length === cart.items?.items?.length - 1;

  const totalCecoPercentage = cart.items?.items?.map((item) =>
    item?.cost_centers?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.percentage,
      0
    )
  );

  const maxTotalCecoPercentage = totalCecoPercentage?.filter(
    (costCenter) => costCenter === 100
  );

  const hasAllCecoPercentages =
    maxTotalCecoPercentage?.length === cart.items?.items?.length;

  return (
    <Flex justifyContent={"center"}>
      {!cart.loading &&
      cart?.items?.items?.length === 0 &&
      !mainState?.cart?.endsBuyResult ? (
        <Flex flexDir={"column"} justifyContent="center" alignItems={"center"}>
          <H5 mb={"16px"}>Tu carrito esta vacío</H5>
          <Paragraphs>
            ¡Encontrá y descubrí productos al mejor precio!{" "}
          </Paragraphs>
          <ButtonBekeu
            px={"24px"}
            w="fit-content"
            borderRadius={"12px"}
            _hover={{ bg: "brand.hover" }}
            _active={{ bg: "brand.hover" }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
            }}
            onClick={() => {
              history.push("/search/?page=1&per_page=20");
            }}
          >
            Descubrir productos
          </ButtonBekeu>
        </Flex>
      ) : (
        <Flex
          minH={"100vh"}
          flexDir={"column"}
          bg={"brand.background"}
          py={"32px"}
          w="75rem"
          /*{...props}*/
        >
          {/*
          {mainState?.auth?.user?.authorizer?.user?.name && (
              <AlertBekeu mb="32px">
              Tus compras deben ser aprobadas por:{" "}
              {mainState?.auth?.user?.authorizer?.user?.name}{" "}
              {mainState?.auth?.user?.authorizer?.user?.last_name} -{" "}
              {mainState?.auth?.user?.authorizer?.user?.email}
            </AlertBekeu>
          )}
          */}
          <AlertBekeu mb="32px">Recordá que tus compras requieren aceptación de tus aprobadores según tu sector dentro de la compañía.</AlertBekeu>
          <BoxBekeu overflow="hidden" p={"0"}>
            <Flex flexDir={"column"}>
              <Flex justifyContent={"space-between"}>
                {cart.loading && (
                  <Flex alignItems={"center"}>
                    <H6 p={"24px"}>Mi Carrito</H6>
                    <Spinner color="gray.300" size="sm" />
                  </Flex>
                )}
                {!cart.loading && (
                  <H6 p={"24px"}>
                    Mi Carrito
                    {cart?.items?.items?.length !== 1
                      ? ` (${cart?.items?.items?.length} productos)`
                      : ` (${cart?.items?.items?.length} producto)`}
                  </H6>
                )}
                <Menu>
                  <MenuButton
                    as={Button}
                    variant={"ghost"}
                    w={"fit-content"}
                    h={"72px"}
                    _hover={{ bg: "rgba(0, 0, 0, 0)" }}
                    _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                    _active={{ bg: "rgba(0, 0, 0, 0)" }}
                    p={"24px"}
                    zIndex={9999}
                  >
                    <ShippingDetail
                      text={
                        societyState ? `Sociedad: ${societyState}` : `Sociedad`
                      }
                      hideLeftIcon
                    />
                  </MenuButton>
                  <MenuList
                    w={"100%"}
                    boxShadow="lg"
                    borderRadius={"12px"}
                    borderTopEndRadius={0}
                    zIndex={999999}
                  >
                    <Flex flexDir={"column"} px={"24px"}>
                      {mainState.cart?.companies?.map((item, id) => (
                        <Button
                          key={id}
                          variant={"link"}
                          width={"fit-content"}
                          as={MenuItem}
                          _hover={{
                            borderBottomWidth: "0px",
                            color: "brand.hover",
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                          height={"38px"}
                          _focus={{
                            boxShadow: "rgba(0, 0, 0, 0)",
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                          _active={{
                            boxShadow: "rgba(0, 0, 0, 0)",
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                          onClick={() => {
                            handleSetSociety(item);
                          }}
                        >
                          <Paragraphs
                            color={"brand.primary"}
                            textAlign={"left"}
                            width={"100%"}
                            isTruncated={true}
                          >
                            {item.name}
                          </Paragraphs>
                        </Button>
                      ))}
                    </Flex>
                  </MenuList>
                </Menu>
              </Flex>

              {cart?.items?.items?.length === 0 && <Divider />}
              {cart?.items?.items?.map((product, index) => {
                return (
                  <CartItem
                    product={product}
                    key={index}
                    onQuantityChange={handleQuantityChange}
                    handleRemoveCartItem={handleRemoveCartItem}
                    loading={cart.loading}
                    handleSelectedCeco={handleSelectedCeco}
                    handleDeleteCeco={handleDeleteCecoItem}
                    handleDisabledCeco={handleDisabledCeco}
                    setEnablePercentage={setEnablePercentage}
                    handleApplyAllCecos={handleApplyAllCecos}
                    allCecos={allCecos}
                    index={index}
                    hasApplyAllCecos={hasApplyAllCecos}
                    sapAvailable={sapAvailable}
                    dispatchNoteAvailable={dispatchNoteAvailable}
                    shippingIncluded={shippingIncluded}
                  />
                );
              })}
            </Flex>

            <Divider />
            {cart?.items?.items?.length > 0 && (
              <ShippingBlock
              arsCost={cart?.items?.shipping_cost_ARP}
              usdCost={cart?.items?.shipping_cost_USD}
              totalArs={cart?.items?.total_ARP}
              totalUsd={cart?.items?.total_USD}
              cartItems={cart?.items}
              shippingIncluded={shippingIncluded}
              />
            )}
          </BoxBekeu>
          <Flex justifyContent={"space-between"} alignItems={"start"}>
            <CartShipping
              handleChangeAddress={handleChangeAddress}
              handleChangeCostCenter={handleChangeCostCenter}
              handleChangeProviderComments={handleChangeProviderComments}
              handleChangeApproverComments={handleChangeApproverComments}
              setEnableLogisticCenter={setEnableLogisticCenter}
              costCenter={costCenter}
            />
            <CartSummary
              getCart={handleGetCart}
              disabledByInputs={
                !address ||
                !enableLogisticCenter ||
                !hasAllCecos ||
                !hasAllCecoPercentages
              }
              cart={cart?.items}
              shippingData={{
                address,
                costCenter,
                providerComments,
                approverComments,
              }}
              state={mainState}
              handleEndsBuy={handleEndsBuy}
              user={user?.authorizer?.user?.email}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default injectIntl(connect(null, {...cartActions, ...authActions})(CartPage));
