import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  Img,
  MenuItem,
  Select,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { ShippingDetail } from "../../design-system/forms/ShippingDetail";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import searchNormal from "../../design-system/icons/Search/search-normal.svg";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useSelector } from "react-redux";
import helpCircle from "../../design-system/icons/Arrows/help-circle.svg";
//import { Autocomplete } from "@mui/lab";
//import { TextField } from "@mui/core";

export const CartShippingAccordion = ({
  handleChangeAddress,
  handleChangeProviderComments,
  handleChangeApproverComments,
  handleChangeCostCenter,
  costCenterValue,
  setEnableLogisticCenter,
}) => {
  //useRef variables
  const inputElement = useRef(null);

  // redux mainState
  const mainState = useSelector((state) => state);

  // delivery address inputs
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("Dirección de entrega");

  // logistic center inputs
  const [logisticCenter, setLogisticCenter] = useState("");

  // cost center inputs
  const [costCenterState, setCostCenterState] = useState("");
  const [selectedCostCenter, setSelectedCostCenter] = useState("Imputación");
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);

  const [cecoValue, setCecoValue] = useState("");

  //search events
  const handleAddressSearch = (e) => {
    e.preventDefault();
    setDeliveryAddress(e.target.value);
  };

  const handleAddressSelected = (value, id) => {
    setSelectedAddress(value);
    //handleChangeAddress(value, id);
  };

  const handleLogisticCenterSelected = (value, id) => {
    setLogisticCenter(value);
    handleChangeAddress(value, id);
    setEnableLogisticCenter(value);
  };

  const handleCecoSearch = (e) => {
    e.preventDefault();
    setCostCenterState(e.target.value);
  };

  const handleSelectedCeco = (value, id) => {
    setSelectedCostCenter(value);
    handleChangeCostCenter(value);
  };

  // demo array
  const cecoElements = [
    {
      id: 1,
      company_id: 1,
      cost_center: "CECO1",
    },
    {
      id: 2,
      company_id: 2,
      cost_center: "CECO2",
    },
  ];

  const mappedData = mainState.cart.address;
  const addressesValues = [];

  const unNormalizedAddresses = mainState.cart?.address && ({
    name: "Addresses",
    items: mappedData.map((address) => ({
      ...address,
      name: address.name,
      id: address.id,
      logistic_center: address.logistic_center,
      region_id: address.region_id

    }))
  })

  const addressesMap = unNormalizedAddresses?.items?.reduce((addresses, address) => {
    const addressIndex = addresses.findIndex(item => item.name === address.name);
    if (addressIndex > -1) {
      addresses[addressIndex].index.push(address);
      addressesValues[addressIndex].index.push(address);
    } else {
      addresses.push({
        index: [address],
        name: address.name
      });
      addressesValues.push({
        index: [address],
        name: address.name,
        id: address.id
      });
    }
    return addresses;
  },[]);
  //console.log(addressesMap);
  //console.log(addressesValues);

  // filter for variables
  const filteredAddresses = addressesValues?.filter((cl) =>
    cl?.name?.toLowerCase().includes(deliveryAddress?.toLowerCase())
  );

  const filteredLogisticCenter = unNormalizedAddresses?.items?.filter((cl) =>
    selectedAddress !== "Dirección de entrega"
      ? cl?.name?.toLowerCase().includes(selectedAddress?.toLowerCase())
      : cl?.logistic_center
  );

  //sets logistic center and address to position 1 if applies
  useEffect(() => {
    const address = mainState.cart.address;
    if (mainState.cart.address?.length === 1) {
      setSelectedAddress(address[0]?.name);
      handleChangeAddress(address[0]?.name, address[0]?.id);
    } else if (mainState.cart.address?.length === 0) {
      setSelectedAddress("Dirección de entrega");
      handleChangeAddress(null, null);
      setLogisticCenter("");
      setEnableLogisticCenter("");
    }
  }, [mainState.cart.address]);

  useEffect(() => {
    const logisticCenter = filteredLogisticCenter;
    if (filteredLogisticCenter?.length === 1) {
      setLogisticCenter(logisticCenter[0]?.logistic_center);
      setEnableLogisticCenter(logisticCenter[0]?.logistic_center);
      setSelectedAddress(logisticCenter[0]?.name);
      handleChangeAddress(logisticCenter[0]?.name, logisticCenter[0]?.id);
    }
  }, [filteredLogisticCenter]);

  return (
    <Flex flexDir={"column"}>
      <Menu placement={"bottom-start"} isLazy>
        <Divider />
        <MenuButton
          as={Button}
          variant={"ghost"}
          w={"fit-content"}
          h={"72px"}
          _hover={{ bg: "rgba(0, 0, 0, 0)" }}
          _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
          _active={{ bg: "rgba(0, 0, 0, 0)" }}
        >
          <ShippingDetail text={selectedAddress} my={"24px"} />
        </MenuButton>
        <MenuList
          w={"100%"}
          boxShadow="lg"
          borderRadius={"12px"}
          zIndex={999}
          py={0}
        >
          {!mainState.cart?.address ? (
            <Flex justifyContent={"center"} alignItems="center" p="24px">
              <Spinner color="gray.300" size="sm" />
            </Flex>
          ) : (
            <Flex flexDir={"column"} p={"24px"}>
              <form
                onSubmit={handleAddressSearch}
                style={{ width: "100%", marginBottom: "16px" }}
              >
                <InputBekeu
                  value={deliveryAddress}
                  placeholder="Buscar"
                  pl={"60px"}
                  onChange={handleAddressSearch}
                  lefticon={<BekeuIcon src={searchNormal} />}
                />
              </form>
              {filteredAddresses?.length !== 0 ? (
                  filteredAddresses?.map((item, id) => (
                  <Button
                    key={id}
                    variant={"link"}
                    as={MenuItem}
                    _hover={{
                      borderBottomWidth: "0px",
                      color: "brand.hover",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    height={"38px"}
                    _focus={{
                      boxShadow: "rgba(0, 0, 0, 0)",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    _active={{
                      boxShadow: "rgba(0, 0, 0, 0)",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    onClick={() => handleAddressSelected(item?.name, item?.id)}
                  >
                    <Paragraphs
                      color={"brand.contentPrimary"}
                      textAlign={"left"}
                      width={"100%"}
                      isTruncated={true}
                      _hover={{ color: "brand.primary" }}
                    >
                      {item?.name}
                    </Paragraphs>
                  </Button>
                ))
              ) : mainState.cart && mainState.cart.address ? (
                "No se encontró la dirección solicitada"
              ) : (
                <Center mt={"12px"}>
                  <Spinner color="gray.300" size="sm" />
                </Center>
              )}
            </Flex>
          )}
        </MenuList>
      </Menu>
      <Menu>
        <Divider />
        <MenuButton isDisabled={selectedAddress === "Dirección de entrega"}
          as={Button}
          variant={"ghost"}
          w={"fit-content"}
          h={"72px"}
          _hover={{ bg: "rgba(0, 0, 0, 0)" }}
          _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
          _active={{ bg: "rgba(0, 0, 0, 0)" }}
        >
          <ShippingDetail text={`Centro logístico:  ${logisticCenter}`} />
        </MenuButton>
        <MenuList
          w={"100%"}
          boxShadow="lg"
          borderRadius={"12px"}
          borderTopEndRadius={0}
          zIndex={999}
        >
          <Flex flexDir={"column"} px={"24px"}>
            {filteredLogisticCenter?.map((item, id) => (
              <Button
                key={id}
                variant={"link"}
                width={"fit-content"}
                as={MenuItem}
                _hover={{
                  borderBottomWidth: "0px",
                  color: "brand.hover",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                height={"38px"}
                _focus={{
                  boxShadow: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                _active={{
                  boxShadow: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                onClick={() =>
                    handleLogisticCenterSelected(item?.logistic_center, item?.id)
                }
              >
                <Paragraphs
                  color={"brand.primary"}
                  textAlign={"left"}
                  width={"100%"}
                  isTruncated={true}
                >
                  {item?.logistic_center}
                </Paragraphs>
              </Button>
            ))}
          </Flex>
        </MenuList>
      </Menu>
      <Divider />
      <Flex flexDir={"column"} p={"12px"}>
        <Paragraphs mb={"12px"}>Comentarios para proveedores (opcional)</Paragraphs>
        <TextAreaBekeu
          p={4}
          placeholder="Sumá información importante"
          isRequired
          onChange={handleChangeProviderComments}
          maxLength={300}
        />
      </Flex>
      <Divider mt={"12px"} />
      <Flex flexDir={"column"} p={"12px"}>
        <Paragraphs mb={"12px"}>
          Comentarios para aprobador (Opcional)
        </Paragraphs>
        <TextAreaBekeu
          p={4}
          placeholder="Ej: El producto es excelente"
          isRequired
          onChange={handleChangeApproverComments}
          maxLength={300}
        />
      </Flex>
    </Flex>
  );
};
