import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";

export const MyOrdersBadge = ({ children, item, ...props }) => {
  return (
    <Box
      {...props}
      color={
            item && item?.state === "1" ? "brand.warning"
          : item && item?.state === "2" ? "brand.success"
          : item && item?.state === "2.1" ? "brand.success"
          : item && item?.state === "3" ? "brand.error"
          : item && item?.state === "3.1" ? "brand.error"
          : item && item?.state === "3.2" ? "brand.error"
          : item && item?.state === "3.3" ? "brand.error"
          : item && item?.state === "3.4" ? "brand.error"
          : item && item?.state === "4" ? "brand.success"
          : item && item?.state === "5" ? "brand.error"
          : "brand.success"
      }
      background={
            item && item?.state === "1" ? "rgba(255, 152, 0, 0.2)"
          : item && item?.state === "2" ? "rgba(76, 175, 80, 0.2)"
          : item && item?.state === "2.1" ? "rgba(76, 175, 80, 0.2)"
          : item && item?.state === "3" ? "rgba(255, 152, 0, 0.2)"
          : item && item?.state === "3.1" ? "rgba(255, 152, 0, 0.2)"
          : item && item?.state === "3.2" ? "rgba(255, 152, 0, 0.2)"
          : item && item?.state === "3.3" ? "rgba(255, 152, 0, 0.2)"
          : item && item?.state === "3.4" ? "rgba(255, 152, 0, 0.2)"
          : item && item?.state === "4" ? "rgba(76, 175, 80, 0.2)"
          : item && item?.state === "5" ? "rgba(255, 152, 0, 0.2)"
          : "rgba(76, 175, 80, 0.2)"
      }
      padding={"8px"}
      borderRadius={"12px"}
    >
      {children}
    </Box>
  );
};
