import {
  Avatar,
  AvatarGroup,
  Button,
  Divider,
  Flex,
  Img,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ApproveOrdersBadge } from "../ApproveOrdersBadge/ApproveOrdersBadge";
import eye from "../../design-system/icons/Security/eye.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
//import * as cart from "../../store/cart/cart.duck";
import * as cartActions from "../../pages/approveCartsPage/ducks/approveCarts.duck";

import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";


import { ApproveCartsRejectModal } from "../ApproveCartsRejectModal/ApproveCartsRejectModal";
import { ApproveCartsSuccessModal } from "../ApproveCartsSuccessModal/ApproveCartsSuccessModal";
import { ShippingBlock } from "../ShippingBlock/ShippingBlock";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useSelector } from "react-redux";
import trash from "../../design-system/icons/Cart/trash.svg";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { useState } from "react";
import notFoundImg from "../../design-system/images/not-found.png";
import {CartOrderId} from "../CartOrderId/CartOrderId";
import {ApproveCartsBadge} from "../ApproveCartsBadge/ApproveCartsBadge";
import {MyOrdersDetail} from "../MyOrdersDetail/MyOrdersDetail";
import {ApproveCartDetail} from "../ApproveCartDetail/ApproveCartDetail";

const DetailItem = ({
                      item,
                      sellerData,
                      history,
                    }) => {
  const cart = useSelector((state) => state.carts_approval);
  const [inputValue, setInputValue] = useState(item?.quantity);
  const [imageError, setImageError] = useState(false);

  const ARS = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS',});
  const USD = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'USD',});

  return (
      <>
        <Flex flexDir={"column"}>
          <Flex p="24px">
            <Flex w="50%">
              <Flex
                  w="88px"
                  h="88px"
                  mr="40px"
                  border="1px solid"
                  borderColor={"brand.border"}
                  borderRadius={"12px"}
                  overflow="hidden"
                  justifyContent={"center"}
                  alignItems="center"
              >
                <Img
                    src={
                      imageError ? notFoundImg : item?.combination?.media?.source
                    }
                    onError={() => setImageError(true)}
                    draggable={"false"}
                    objectFit="cover"
                    cursor={imageError ? "not-allowed" : "pointer"}
                    title={
                      imageError ? "Imagen no disponible" : item?.combination?.title
                    }
                    h={imageError ? "50%" : "100%"}
                />
              </Flex>
              <Flex justifyContent={"space-between"} width={"100%"}>
                <Flex flexDir={"column"} w={"380px"} gap="12px">
                  <H6
                      onClick={() => history.push(`/detail/${item?.sku}`)}
                      cursor="pointer"
                  >
                    {item?.combination?.title}
                  </H6>
                  <ProductDetailModalSeller
                      sellerName={sellerData?.fantasy_name}
                      email={sellerData?.email}
                      phone={sellerData?.phone}
                  />
                  <Flex flexDir={"column"}>
                    <Paragraphs lineHeight={"22px"} mb="3px" fontWeight={600}>
                      Centros de costo:
                    </Paragraphs>
                    {item?.cost_centers?.map((item, key) => (
                        <Flex key={key} justifyContent={"space-between"} w="100%">
                          <Paragraphs lineHeight={"22px"}>
                            {item?.cost_center}
                          </Paragraphs>
                          <Paragraphs lineHeight={"22px"}>
                            {item?.percentage}%
                          </Paragraphs>
                        </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex w="50%" justifyContent="space-between" alignItems={"center"}>
              <Flex
                  flexDir={"column"}
                  w="50%"
                  justifyContent="space-between"
                  gap="12px"
              >
                <Text>Cantidad</Text>
                <InputBekeu
                    grouph="38px"
                    groupw="155px"
                    maxLength={3}
                    defaultValue={item?.quantity}
                    placeholder={item?.quantity}
                    value={inputValue}
                    isDisabled
                    pointerEvents

                />
              </Flex>
              <Flex flexDir={"column"} w="50%" justifyContent="center">
                <H6>
                  {item?.currency_code} {ARS.format(parseFloat(item?.price_unit_current))} + Imp
                </H6>
              </Flex>
            </Flex>
          </Flex>

          <Divider />
        </Flex>
      </>
  );
};

const ApproveCartOrdersItem = ({
                                 item,
                                 type,
                                 showitems,
                                 selected,
                                 state,
                                 getOneProduct,
                                 handleApproveCart,
                                 handleRejectCart,
                                 handleApproveOrder,
                                 handleRejectOrder,
                                 handlePagination,
                                 params,
                                 ...props
                               }) => {
  const history = useHistory();
  const [imageError, setImageError] = useState(false);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  let parsedFullName = item?.client.user?.name + " " + item?.client.user?.last_name;
  if (item?.client?.erp_username) parsedFullName += " (" + item?.client?.erp_username + ")";
 // showitems = false;
  const total_items = item.buyOrders.reduce((accumulator, buyOrder) => accumulator + buyOrder.items.length, 0);

  return (
      <BoxBekeu p={0} {...props}>
          <Flex flexDir={"column"}>
              <Flex alignItems={"center"} justifyContent={"space-between"} mb={30} p="24px">
                <H5>{moment(item?.created_at).format("LL")}</H5>
                  <Flex>
                      <CartOrderId mr={4}>
                          Carrito N°{item?.internal_code}
                      </CartOrderId>
                      <ApproveCartsBadge item={item}>
                          { item?.state === "1" ? "Pendiente"
                          : item?.state === "2" ? "Entrega en proceso"
                          : item?.state === "3" ? "Rechazada"
                          : item?.state === "4" ? "Finalizada"
                          : "En Curso"}
                      </ApproveCartsBadge>
                  </Flex>
              </Flex>
              <Divider />
              <Flex justifyContent={"space-between"} p={"24px"}>
                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                    Comprador
                  </Paragraphs>
                  <Paragraphs>{parsedFullName}</Paragraphs>
                </Flex>
                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                    Nro de compra
                  </Paragraphs>
                  <Paragraphs>{item?.internal_code}</Paragraphs>
                </Flex>
                <Flex flexDir={"column"} w="20rem" lineHeight="1.5rem">
                  <Paragraphs fontWeight={600}>Zona de entrega</Paragraphs>
                  <Paragraphs lineHeight="1.5rem">
                    {item?.address?.logistic_center} - {item?.address?.name}
                  </Paragraphs>
                </Flex>

                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                    Comentarios del comprador
                  </Paragraphs>
                  <Paragraphs>{item?.approver_comments}</Paragraphs>
                </Flex>
                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem" justify="center">
                      <Text justify="center" textAlign="center">Total de artículos:</Text>
                  </Paragraphs>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                      <Text justify="center" textAlign="center">{total_items}</Text>
                  </Paragraphs>
                    {/*<AvatarGroup
                      size="md"
                      max={2}
                      draggable={false}
                      justifyContent={"space-evenly"}
                  >
                  </AvatarGroup>*/}
                  <Paragraphs textAlign="center"></Paragraphs>
                </Flex>
              </Flex>
              <Divider />

              <ApproveCartDetail
                      item={item}
                      showitems={false}
                      totalItems={total_items}
              />

              {/*{showitems &&
                  item?.buyOrders &&
                  item?.buyOrders?.items?.map((buyOrder, boid) => (
                      item?.buyOrder?.items?.map((product, id) => (
                          <DetailItem
                              key={id}
                              item={product}
                              sellerData={item?.provider}
                              getOneProduct={getOneProduct}
                              history={history}
                          />
                      ))
                  ))}
              {showitems && (
                  <>
                    <ShippingBlock
                        arsCost={item.shipping_cost_ARP}
                        usdCost={item.shipping_cost_USD}
                        totalArs={item.total_ARP}
                        totalUsd={item.total_USD}
                    />
                    <Divider />
                  </>
              )}

              <Flex justifyContent={"space-between"} p={"24px"} flexDir={"column"} alignItems="flex-end" gap="12px" my="14px">
                {item?.parsedArs &&
                    (item?.parsedShippingArs ? (
                        <Flex gap={2}>
                          <H6 fontWeight={400}>Total envío en ARS: </H6>
                          <H6 fontWeight={600} color="brand.success">
                            Envío Incluído
                          </H6>
                        </Flex>
                    ) : (
                        <H6 fontWeight={400}>
                          Total envío en ARS: {item?.shipping_cost_ARP} + Imp{" "}
                        </H6>
                    ))}
                {item?.parsedUsd &&
                    (item?.parsedShippingUsd ? (
                        <Flex gap={2}>
                          <H6 fontWeight={400}>Total envío en USD: </H6>
                          <H6 fontWeight={600} color="brand.success">
                            Envío Incluído
                          </H6>
                        </Flex>
                    ) : (
                        <H6 fontWeight={400}>
                          Total envío en USD: {item?.shipping_cost_USD} + Imp{" "}
                        </H6>
                    ))}
                {item?.parsedArs ? (
                    <H6>Total en ARS: {item?.total_w_shipping_ARP} + Imp </H6>
                ) : (
                    ""
                )}
                {item?.parsedUsd ? (
                    <H6>Total en USD: {item?.total_w_shipping_USD} + Imp </H6>
                ) : (
                    ""
                )}
              </Flex>
              */}

              <Flex p={"24px"}
                  justifyContent={
                    item?.status === "pending" && !selected
                        ? "space-between"
                        : "flex-start"
                  }
                  alignItems={"center"}
              >
                {!selected && (
                    <ButtonBekeuLink
                        leftIcon={<Img src={eye} />}
                        onClick={() => {
                          handleScrollTo();
                          history.push(
                              `/approve-carts/cart-detail/${item.id}/`
                          );
                        }}
                        mt={"8px"}
                    >
                      Ver pedido para aprobar
                    </ButtonBekeuLink>
                )}
              </Flex>
            </Flex>
          </BoxBekeu>
  );
};

export default injectIntl(
    connect(null, { ...product.actions, ...cartActions.actions })(ApproveCartOrdersItem)
);
