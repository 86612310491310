import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TagManager from "react-gtm-module";

import AOS from "aos";

import { Box, Button, Divider, Flex, Img, Spinner, Text, position } from "@chakra-ui/react";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import location from "../../design-system/icons/Location/location-tick-primary.svg";
import notFoundImg from "../../design-system/images/not-found.png";

import "aos/dist/aos.css";
import "./CardItem.scss";

const CardItem = ({ data, gridLoading, handleOpenInfo }) => {
  const storageFiltersVar = localStorage.getItem("storageFilters");
  const [imageError, setImageError] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState('');
  const [alertMessage, setAlertMessage] = useState(false);

  const history = useHistory();

  const storageFilters = {
    isFirstLoad: false,
    categories: {
      category_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).categories.category_name
          : [],
      category_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).categories.category_id
          : [],
    },
    brands: {
      brand_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).brands.brand_name
          : [],
      brand_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).brands.brand_id
          : [],
    },
    delivery_zones: {
      delivery_zone_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name
          : [],
      delivery_zone_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id
          : [],
    },
    price: storageFiltersVar ? JSON.parse(storageFiltersVar).price : [],
    delivery_days: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_days
        : [],
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (data?.prices && data.prices.length > 0) {
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: data.prices[0].currency_code,
      });
      const formattedPriceValue = formatter.format(parseFloat(data.prices[0].value));
      setFormattedPrice(formattedPriceValue);
    } else if (data?.disable_prices && data.disable_prices.length > 0) {
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: data.disable_prices[0].currency_code,
      });
      const formattedPriceValue = formatter.format(parseFloat(data.disable_prices[0].value));
      setFormattedPrice(formattedPriceValue);
    }
  }, [data]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const handleShowAlertMessage = () => {
    setAlertMessage(true);
  }

  const handleHideAlertMessage = () => {
    setAlertMessage(false);
  }

  return (
      <Box
        position={"relative"}
        borderRadius={12}
        overflow="hidden"
        boxShadow={"lg"}
        mb={"12px"}
        bg={"white"}
        onMouseOver={() => handleShowAlertMessage()}
        cursor={"pointer"}
        onMouseLeave={() => handleHideAlertMessage()}
        onClick={() => {
          handleScrollTo();
          localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
          TagManager.dataLayer({
            dataLayer: { event: "item", path: data.product_name },
          });
        }}
      >
        <Box
          position={"absolute"}
          zIndex={99}
          top={0}
          bottom={0}
          display={data.enable_buy === 0 && alertMessage ? "grid" : "none"}
          transition={"all 0.3s ease-in"}
          placeContent={"center"}
          textAlign={"center"}
          >
          <Text
            fontSize={"xs"}
            fontWeight={"bold"}
          > Ninguno de estos proveedores está registrado y/o activado en tu empresa
          </Text>

          <Text 
            color={"brand.primary"}
            fontSize={"xs"}
            fontWeight={"bold"}
            mt={2}
            onClick={() => handleOpenInfo()}
          >Más información</Text>
          
          <ButtonBekeuM
            mt={5}
            mb={0}
            w="50%"
            mx={"auto"}
            fontSize={"xs"}
            height={"1.7rem"}
            borderRadius={"0.35rem"}
            padding={"0.25rem 0.5rem"}
            onClick={() => history.push(`/detail/${data.sku}`)}
          >
            Ver producto
          </ButtonBekeuM>
        </Box>
        
        <Box
          style={{
            filter:data.enable_buy === 1 ? "none" : alertMessage ? "contrast(0.75) blur(0.14rem)" : "contrast(0.8)",
            background: data.enable_buy === 1 ? "unset" : "white"
          }}
          zIndex={2}
        >
          <Link
            to={`/detail/${data.sku}`}
          >

            <Flex justifyContent={"center"} p={"12px"} h={"156px"}>
              {gridLoading ? (
                <Spinner color="gray.300" size="lg" />
              ) : (
                <Flex
                overflow="hidden"
                      justifyContent={"center"}
                      alignItems="center"
                  >
                    <Img
                        style={{ mixBlendMode:"multiply", filter: data.enable_buy === 1 ? "unset" : "grayscale(0.7)" }}
                        h={imageError ? "50%" : "100%"}
                        title={imageError ? "Imagen no disponible" : data?.product_name}
                        src={imageError ? notFoundImg : data?.image_source}
                        draggable={"false"}
                        onError={() => setImageError(true)}
                    />
                  </Flex>
              )}
            </Flex>
            <Divider />
            <Flex
                flexDir={"column"}
                justifyContent="space-between"
                px={"12px"}
                pb={"10px"}
            >
              <Text
                  h={"38px"}
                  fontSize="14px"
                  lineHeight={"19px"}
                  fontWeight={400}
                  fontStyle={"normal"}
                  color={"brand.contentSecondary"}
                  mt={"12px"}
                  mb={"6px"}
                  overflow={"hidden"}
              >
                {data?.product_name}
              </Text>
              <Text
                  fontSize="14px"
                  lineHeight={"19px"}
                  color={"#000000"}
                  fontWeight="semibold"
                  my={"3px"}
              >
                {formattedPrice} + Imp
              </Text>
              <Flex
                  my={"3px"}
                  alignItems={"center"}
                  bg={"rgba(76, 175, 80, 0.1)"}
                  borderRadius={"12px"}
                  px={"8px"}
                  py={"2px"}
                  pr={"12px"}
                  minH={"23px"}
                  w="fit-content"
              >
                <BekeuIcon src={location} h={"100%"} mr={1} />
                <Text
                    color={"brand.success"}
                    fontSize={"0.8rem"}
                    fontWeight={400}
                    letterSpacing={"-0.005em"}
                >
                  Disponible en {data?.regions_available}{" "}
                  {data?.regions_available === 1 ? "zona" : "zonas"}
                </Text>
              </Flex>
            </Flex>
          </Link>
        </Box>
      </Box>
  );
};

export default CardItem;
