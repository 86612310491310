import React, {useEffect, useState} from "react";
import { Flex, SimpleGrid, Text, useDisclosure, Box } from "@chakra-ui/react";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { Paginator } from "../../components/Paginator/Paginator";
import useQuery from "../../hooks/useQuery";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as orderActions } from "./ducks/myOrders.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import MyOrdersItem from "../../components/MyOrdersItem/MyOrdersItem";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";
import MyOrdersItemLoader from "../../components/MyOrdersItem/MyOrdersItemLoader";
import { StackedBarChart } from "../../components/Charts/StackedBarChart/StackedBarChart";
import {getFeatures} from "../login/crud/auth.crud";

const MyOrdersPage = ({ listCarts, ...props }) => {
  const query = useQuery();
  const history = useHistory();
  const { pathname } = useLocation();

  const state = query.get("state");
  const d = query.get("d");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const from = query.get("from");
  const to = query.get("to");

  const mainState = useSelector((state) => state);
  const filteredItems = mainState.carts.carts;
  const arr = [...new Array(mainState.orders.ordersPagination.totalPages)];
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [sapAvailable, setSapAvailable] = useState(true);
  const [dispatchNoteAvailable, setDispatchNoteAvailable] = useState(true);
  const [shippingIncluded, setShippingIncluded] = useState(true);

  useEffect(() => {
    if(!mainState.auth.features)  getFeatures().then(r => {});
    console.log('|MyOrdersPage|', mainState);
  }, [mainState.auth])

  useEffect(() => {
    console.log('---', mainState);
    //console.log(mainState?.auth?.features?.find((str) => str === 'SAP'));
    if(mainState?.auth?.features?.find((str) => str === 'SAP')){
      setSapAvailable(true);
    } else {
      //setSapAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'dispatch_note')){
      setDispatchNoteAvailable(true);
    } else {
      //setDispatchNoteAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'shipping_included')){
      setShippingIncluded(true);
    } else {
      //setShippingIncluded(false);
    }
  }, [mainState.auth.features])

  const hasError = `${mainState.carts.result}`;

  useEffect(() => {
    listCarts({
      state,
      page,
      perPage,
      from,
      to,
    });
  }, [state, page, perPage, from, to, listCarts]);

  useEffect(() => {
    hasError.includes("generalError") && onOpen();
  }, [hasError, onOpen]);

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });
  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item?.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />
        <ApproveOrdersBreadcrumb />
        <AlertBekeu my={"32px"}>
          Recordá que una vez recibida tu compra debes confirmar la recepción
          ingresando a <strong> Ver compra</strong>
        </AlertBekeu>
        <H5>Mis compras</H5>

        <SimpleGrid mt={"32px"} minChildWidth="400px" spacing={4} height={"56px"}>
        <Box display="block" height={"56px"}>
          <Flex height={"56px"}>
            <StackedBarChart data={filteredItems}></StackedBarChart>
          </Flex>
        </Box>

        </SimpleGrid>

        <Flex justifyContent={"flex-end"}>
          <SimpleGrid mt={"170px"} minChildWidth="200px" spacing={4} w={"500px"}>

            {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
            {/* <InputBekeu
          disabled={true}
          placeholder={"Buscar"}
          bg={"white"}
          lefticon={<Img src={search} mr={4} />}
        /> */}
            <Box zIndex={9999}>
              <MenuBekeu
                height={"56px"}
                zIndex={9999}
                items={[
                  { name: "Todas", state: "0" },
                  { name: "Pendiente de Aprobación", state: "1" },
                  { name: "En Curso", state: "2" },
                  { name: "Rechazada", state: "3" },
                  { name: "Finalizada", state: "4" },
                ]}
              >
                {state === "" ? "Todas"
                  : state === "1" ? "Pendiente de Aprobación"
                  : state === "2" ? "En Curso"
                  : state === "3" ? "Rechazada"
                  : state === "4" ? "Finalizada"
                  : "Todas"}
              </MenuBekeu>
            </Box>
            <Box>
              <MenuBekeu
                height={"56px"}
                leftIcon
                setMoment={setMoment}
                state={state}
                items={[
                  {
                    name: "Todas",
                    date: "Todas",
                    to: formattedDate,
                    d: 0,
                  },
                  {
                    name: "Este mes",
                    date: "Este mes",
                    to: formattedDate,
                    d: 1,
                  },
                  {
                    name: "Últimos 3 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 3,
                  },
                  {
                    name: "Últimos 6 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 6,
                  },
                  {
                    name: "Últimos 12 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 12,
                  },
                ]}
              >
                {d
                  ? d === "0"
                    ? "Todas"
                    : d === "1"
                    ? "Este mes"
                    : d === "3"
                    ? "Últimos 3 meses"
                    : d === "6"
                    ? "Últimos 6 meses"
                    : d === "12" && "Últimos 12 meses"
                  : "Todas"}
              </MenuBekeu>
            </Box>
            {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
            {/* <MenuBekeu
          disabled={true}
          height={"56px"}
          items={[
            { name: "felipe@empresa.com.ar", user: "felipe@empresa.com.ar" },
            { name: "usuario@empresa.com.ar", user: "usuario@empresa.com.ar" },
            { name: "usuario@empresa.com.ar", user: "usuario@empresa.com.ar" },
          ]}
        >
          Seller
        </MenuBekeu> */}
            {/* <ButtonBekeuOutline
          onClick={() => history.push("/approve-orders/delegate-orders")}
          isDisabled
          height={"56px"}
        >
          Exportar/Descargar
        </ButtonBekeuOutline> */}
          </SimpleGrid>
        </Flex>

        {!mainState.carts.loading ? (
          <>
            {filteredItems && filteredItems.length > 0 ? (
              filteredItems.map((item, id) => {
                return (
                  <MyOrdersItem
                    mt={"32px"}
                    type={item?.state}
                    key={id}
                    item={{
                      ...item,
                      cost_centers: [
                        { cost_center: "AASDGASVQ", percentage: "20" },
                        { cost_center: "BASDBAJD", percentage: "80" },
                      ],
                    }}
                    status={item?.state === "pending" ? true : undefined}
                    sapAvailable={sapAvailable}
                    dispatchNoteAvailable={dispatchNoteAvailable}
                    shippingIncluded={shippingIncluded}
                  />
                );
              })
            ) : (
              <Text mt={6} className="animate__animated animate__fadeIn">
                No se encontraron compras{" "}
                {state === "1" ? "Pendiente de Aprobación"
                  : state === "2" ? "En Curso"
                  : state === "3" ? "Rechazada"
                  : state === "4" && "Finalizada"}
              </Text>
            )}
          </>
        ) : (
          [1, 2].map((item, id) => (
            <MyOrdersItemLoader mt={"32px"} key={id} item={item} />
          ))
        )}
        {!mainState.carts.loading &&
          mainState.carts &&
          mainState.carts.cartsPagination.totalPages > 1 && (
            <Paginator
              itemsArray={arr}
              totalItems={mainState.orders.ordersPagination.totalPages}
              perPage={5}
              mt={"24px"}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(MyOrdersPage)
);
