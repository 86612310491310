import { Flex, Progress } from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import ApproveOrdersItem from "../../components/ApproveOrdersItem/ApproveOrdersItem";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as orderActions } from "../approveOrdersPage/ducks/approveOrders.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import useQuery from "../../hooks/useQuery";
import {getFeatures} from "../login/crud/auth.crud";

const OrderDetailPage = (props) => {
  const params = useParams();
  const mainState = useSelector((state) => state);

  const query = useQuery();
  const state = query.get("state");

  const [sapAvailable, setSapAvailable] = useState(true);
  const [dispatchNoteAvailable, setDispatchNoteAvailable] = useState(true);
  const [shippingIncluded, setShippingIncluded] = useState(true);

  useEffect(() => {
    if(!mainState.auth.features)  getFeatures().then(r => {});
    console.log('|MyOrdersStatus|', mainState);
  }, [mainState.auth])

  useEffect(() => {
    console.log('---', mainState);
    //console.log(mainState?.auth?.features?.find((str) => str === 'SAP'));
    if(mainState?.auth?.features?.find((str) => str === 'SAP')){
      setSapAvailable(true);
    } else {
      //setSapAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'dispatch_note')){
      setDispatchNoteAvailable(true);
    } else {
      //setDispatchNoteAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'shipping_included')){
      setShippingIncluded(true);
    } else {
      //setShippingIncluded(false);
    }
  }, [mainState.auth.features])

  useEffect(() => {
    props.orderDetail({ order_id: params.orderId });
  }, []);

  const filteredItem = mainState.orders.orders;

  const handleApproveOrder = (item) => {
    props.approveOrders(item);
  };

  const handleRejectOrder = (item) => {
    props.rejectOrders(item);
  };

  const handlePagination = () => {
    props.orderDetail({ order_id: params.orderId });
  };

  function handleDeleteSuccess() {
    props.orderDetail({ order_id: params.orderId });
  }

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <ApproveOrdersBreadcrumb />
        <H5 mt={"32px"}>Detalle del pedido</H5>
        {mainState.orders.loading ? (
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        ) : filteredItem && filteredItem.length > 0 ? (
          filteredItem.map((item, id) => {
            return (
              <ApproveOrdersItem
                mt={"32px"}
                type={item.state}
                key={id}
                handleDeleteSuccess={handleDeleteSuccess}
                item={item}
                showitems
                selected
                status={item.status === "pending" ? true : undefined}
                handleApproveOrder={handleApproveOrder}
                handleRejectOrder={handleRejectOrder}
                handlePagination={handlePagination}
                params={params}
                state={state}
              />
            );
          })
        ) : (
          "No se encontraron resultados"
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(OrderDetailPage)
);
