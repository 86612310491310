import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import HomeWelcome from "../../components/HomeWelcome/HomeWelcome";

const Home = ({ userType, ...props }) => {
  const storageFilters = {
    isFirstLoad: true,
    categories: { category_name: [], category_id: [] },
    brands: { brand_name: [], brand_id: [] },
    delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
    price: [],
    delivery_days: [],
  };

  useEffect(() => {
    localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  }, [storageFilters]);

  return (
    <Flex flexDir={"column"} /*{...props}*/ minH="38rem">
      <HomeWelcome />
      {/* <HomeItems /> */}
    </Flex>
  );
};

export default Home;
