import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import tip from 'd3-tip'
import * as PropTypes from "prop-types";
import {ByZoneVerticalChart} from "../ByZoneVerticalChart/ByZoneVerticalChart";
import {Flex} from "@chakra-ui/react";

const width = 1200;
const height = 100;
const barHeight = 100;

const DualHorizontalCharts = ({ data }) => {

    useEffect(() => {
        // Rest of the code remains the same, but use the `data` prop instead of the local `data` variable

    }, [data]);

    const chartRef = useRef(null);

    useEffect(() => {
        const svg = d3.select(chartRef.current);

        // Create tooltips
        const tooltip = tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html(d => `<div>${d.province}
                                <br/><span style="color: #4CAF50;">Monto U$D: ${(d.USD) || '0'}</span>
                                <br/><span style="color: #64247E;">Monto AR$: ${(d.ARS) || '0'}</span></div>
                                `)
            .style("opacity", 0.5)
            .style("background-color", "white")
            .style("border", "1px solid black")
            .style("border-width", "1px")
            .style("border-radius", "1px")
            .style("padding", "5px");

        svg.call(tooltip);
        tooltip.hide();

        const yScale = d3.scaleBand()
            .domain(data.map(d => d.province))
            .range([0, barHeight])
            .padding(0.1);

        const maxARSTotal = d3.max(data, d => Math.max( d.ARS * 1.1));
        const maxUSDTotal = d3.max(data, d => Math.max( d.USD * 1.1));

        const xScaleUSD = d3.scaleLinear()
            .domain([maxUSDTotal, 0])
            .range([20, width / 2]);

        const xScaleARS = d3.scaleLinear()
            .domain([0, maxARSTotal])
            .range([width / 2, width -20 ]);

        const yAxis = d3.axisLeft(yScale);
        const xAxisUSD = d3.axisTop(xScaleUSD).ticks(5);
        const xAxisARS = d3.axisTop(xScaleARS).ticks(5);

        svg.append('g')
            .attr('transform', 'translate(0, 20)')
            .call(yAxis);

        svg.append('g')
            .attr('transform', 'translate(0, 20)')
            .call(xAxisUSD)
            .attr('color', '#4CAF50');

        svg.append('g')
            .attr('transform', `translate(0, 20)`)
            .call(xAxisARS)
            .attr('color', '#64247E');

        if(maxUSDTotal > 0) {
            svg.selectAll('.bar-usd')
                .data(data)
                .enter().append('rect')
                .attr('class', 'bar-usd')
                .attr('x', d => xScaleUSD(d.USD))
                .attr('y', d => yScale(d.province) + 20)
                .attr('width', d => width / 2 - xScaleUSD(d.USD))
                .attr('height', yScale.bandwidth())
                .on('mouseover', function (event, d) {
                    tooltip.show(d, this);
                    d3.select(this).attr('stroke', '#000').attr('stroke-width', '2px');
                })
                .on('mouseout', function (event, d) {
                    tooltip.hide();
                    d3.select(this).attr('stroke', '#333').attr('stroke-width', '1px');
                })
                .attr('fill', '#4CAF50');
        }

        if(maxARSTotal > 0) {
            svg.selectAll('.bar-ars')
                .data(data)
                .enter().append('rect')
                .attr('class', 'bar-ars')
                .attr('x', d => xScaleARS(0))
                .attr('y', d => yScale(d.province) + 20)
                .attr('width', d => xScaleARS(d.ARS) - width / 2)
                .attr('height', yScale.bandwidth())
                .on('mouseover', function (event, d) {
                    tooltip.show(d, this);
                    d3.select(this).attr('stroke', '#000').attr('stroke-width', '2px');
                })
                .on('mouseout', function (event, d) {
                    tooltip.hide();
                    d3.select(this).attr('stroke', '#333').attr('stroke-width', '1px');
                })
                .attr('fill', '#64247E');
        }
        svg.selectAll('.region-label')
            .data(data)
            .enter().append('text')
            .attr('class', 'region-label')
            .attr('x', 10)
            .attr('y', d => yScale(d.province) + 25 + yScale.bandwidth() / 2)
            .attr('text-anchor', 'right')
            .attr('alignment-baseline', 'right')
            .style('font-size', '12px')
            .style('fill', '#000000')
            .text(d => d.province);

    }, [data]);

    return (
        <svg ref={chartRef} width={width} height={"100%"} ></svg>
    );
};


export class ByZoneVerticalSVG extends React.Component {
    render() {
        let {data, ...props} = this.props;
/*
        const normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,
                internal_code: cart.carts_internal_code,
                date: new Date(new Date(cart.exported_at).getUTCFullYear(), new Date(cart.exported_at).getMonth()),
                dateStr: new Date(cart.exported_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                USD: Number(cart["sum(bov.total_USD)"]),
                ARS: Number(cart["sum(bov.total_ARP)"]),
                ARP: Number(cart["sum(bov.total_ARP)"]),
                quantity: 0,
                region: cart.region_name,
                iso_3166_2: cart.iso_3166_2,
                business_name: cart.business_name,
                fantasy_name: cart.fantasy_name

            }))
        };
*/
        let normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,
                internal_code: cart.internal_code,
                date: new Date(new Date(cart.created_at).getUTCFullYear(), new Date(cart.created_at).getMonth()),
                dateStr: new Date(cart.created_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                USD: parseFloat(cart.total_USD),
                ARS: parseFloat(cart.total_ARP),
                ARP: parseFloat(cart.total_ARP),
                quantity: 0,
                region: cart.address.region.name,
                province: cart.address.province.name,
                iso_3166_2: cart.address.province.code,
                business_name: cart.business_name,
                fantasy_name: cart.fantasy_name

            }))
        };


        let dataMap = normalizedData.items.reduce((dateOrders, buyOrder)=> {
            let regionIndex = dateOrders.findIndex(item => item.region === buyOrder.region);

            if(regionIndex > -1){
                dateOrders[regionIndex].dateOrders.push(buyOrder);
                dateOrders[regionIndex].USD += Number(buyOrder.USD);
                dateOrders[regionIndex].ARS += Number(buyOrder.ARP);
                dateOrders[regionIndex].ARP += Number(buyOrder.ARP);
                dateOrders[regionIndex].quantity += 1;
                dateOrders[regionIndex].Cantidad += 1;
            } else {
                dateOrders.push({
                    region: buyOrder.region,
                    province: buyOrder.province,
                    internal_code: buyOrder.internal_code,
                    dateStr: buyOrder.dateStr,
                    date: new Date(buyOrder.date.getUTCFullYear(), buyOrder.date.getMonth()),
                    dateOrders: [buyOrder],
                    USD: Number(buyOrder.USD),
                    ARS: Number(buyOrder.ARP),
                    ARP: Number(buyOrder.ARP),
                    quantity: 1,
                    Cantidad:1
                })
            }
            return dateOrders;
        }, []);

        let dataRegionMap = dataMap.sort((a, b) => a.ARS - b.ARS)

        return (
            <Flex>

                <Flex marginTop={30}>
                    <DualHorizontalCharts data={dataRegionMap} key={Math.random()}/>
                </Flex>
            </Flex>
        );
    }
}

ByZoneVerticalSVG.propTypes = {data: PropTypes.any};