import React, { useState } from "react";
import {
  Button,
  Flex,
  Menu,
  Text,
  MenuButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import edit from "../../design-system/icons/Essentials/message-edit.svg";
import { H6 } from "../../design-system/headings/H6";
import { CecoItem } from "./CecoItem";
import { useEffect } from "react";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { useSelector } from "react-redux";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";

const MenuCeco = ({
  cecoList,
  handleSelectedCeco,
  cecoSelected,
  product,
  handleDeleteCecoItem,
  handleDisabledCeco,
  setEnablePercentage,
  handleApplyAllCecos,
  allCecos,
  index,
  hasApplyAllCecos,
  sapAvailable
}) => {
  //hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mainState = useSelector((state) => state);

  //useState variables
  const initialCecoState = [
    {
      position: 1,
      percentage: "",
      cost_center: "",
    },
  ];
  const [totalCecoState, setTotalCecoState] = useState(initialCecoState);
  const [loading, setLoading] = useState(false);
  const [cecoItemState, setCecoItemState] = useState({});

  //functions
  const handleDropCecoState = (item, param, apiCall) => {
    if (totalCecoState.length > 1) {
      let pos = 0;

      totalCecoState.forEach(function(element, index) {
        if (element.position === item.position) {
          pos = index;
          totalCecoState.splice(pos, 1);
          setTotalCecoState([...totalCecoState]);
        }
      });
      apiCall && handleDeleteCecoItem(item, product);
    }
  };

  function handleMenuItem(item) {
    setCecoItemState(item);
  }

  const maxNumber = Math.max(
    ...totalCecoState?.map((x) => parseInt(x.position))
  );

  function handleAddCecoItem() {
    setTotalCecoState([
      ...totalCecoState,
      {
        position: maxNumber && maxNumber > 0 ? maxNumber + 1 : 1,
      },
    ]);
  }

  function handleSetCecoItem() {
    if (allCecos.cecos.length !== 0) {
      handleApplyAllCecos({
        apply: true,
        cecos: totalCecoState,
      });
    } else {
      totalCecoState.map((item) => handleSelectedCeco(item, product));
    }
    setLoading(true);
  }

  //variables validations
  const totalCecoPercentage = totalCecoState?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.percentage,
    0
  );
  const maxTotalCecoPercentage = totalCecoPercentage === 100;
  const hasAllCecosSelected = totalCecoState.filter((item) => item.cost_center);
  const enableConfirmChanges =
    !maxTotalCecoPercentage ||
    hasAllCecosSelected?.length !== totalCecoState?.length;

  const hasAnyCeco = product?.cost_centers?.filter((item) => item.cost_center);

  //useEffect
  useEffect(() => {
    if (cecoSelected && cecoSelected.length > 0) {
      setTotalCecoState([...cecoSelected]);
    } else {
      setTotalCecoState(initialCecoState);
    }
  }, [cecoSelected]);

  useEffect(() => {
    handleDisabledCeco(totalCecoState);
  }, [totalCecoState]);

  useEffect(() => {
    setEnablePercentage(maxTotalCecoPercentage);
  }, [maxTotalCecoPercentage]);

  useEffect(() => {
    if (mainState.cart.result === true) {
      setLoading(false);
      maxTotalCecoPercentage && onClose();
      hasAnyCeco?.length === 0 && setTotalCecoState(initialCecoState);
    }
  }, [mainState.cart.result, setLoading]);

  useEffect(() => {
    handleApplyAllCecos({ apply: false, cecos: [] });
  }, [isOpen]);

  useEffect(() => {
    allCecos?.apply &&
      allCecos.cecos.map((item) => handleSelectedCeco(item, product));
  }, [allCecos]);

  return (
    <Flex
      h="92px"
      flexDir={"column"}
      alignItems="center"
      justifyContent={"center"}
      zIndex={999}
    >
      <Text mb={"12px"}>Imputación</Text>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        isCentered
        blockScrollOnMount={false}
        zIndex={9999}
      >
        <ModalOverlay />
        <ModalContent p="24px" borderRadius={"12px"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }} />
          <ModalBody>
            <Flex flexDir={"column"} gap="12px">
              <H6>Seleccionar centro de costo</H6>
              <Paragraphs mb={"12px"} lineHeight="22px">
                Completá el porcentaje a imputar hasta alcanzar la totalidad de 100% entre los centros de costos
                elegidos, si querés agregar otro centro de costos presiona la tecla Enter.
              </Paragraphs>
              {totalCecoState?.map((cecoItem, id) => {
                return (
                  <CecoItem
                    key={id}
                    altKey={id}
                    cecoItem={cecoItem}
                    cecoList={cecoList}
                    handleSelectedCeco={handleSelectedCeco}
                    product={product}
                    totalCecoState={totalCecoState}
                    setTotalCecoState={setTotalCecoState}
                    dropCecoState={handleDropCecoState}
                    handleAddCecoItem={handleAddCecoItem}
                    handleMenuItem={handleMenuItem}
                    maxNumber={maxNumber && maxNumber > 0 ? maxNumber + 1 : 1}
                    enableConfirmChanges={enableConfirmChanges}
                    handleSetCecoItem={handleSetCecoItem}
                    sapAvailable={sapAvailable}
                  />
                );
              })}
              {mainState.cart?.items?.items?.length > 1 &&
                index === 0 &&
                !enableConfirmChanges &&
                hasApplyAllCecos && (
                  <CheckboxBekeu
                    className="animate__animated animate__fadeIn"
                    onChange={() =>
                      handleApplyAllCecos({
                        apply: false,
                        cecos:
                          allCecos.cecos.length === 0 ? totalCecoState : [],
                      })
                    }
                  >
                    Aplicar para todos los productos del carrito
                  </CheckboxBekeu>
                )}
              <Flex gap="12px" justifyContent={"center"}>
                <ButtonBekeuM
                  my={0}
                  px="32px"
                  w="50%"
                  onClick={handleSetCecoItem}
                  isDisabled={enableConfirmChanges}
                  isLoading={loading}
                >
                  Aceptar
                </ButtonBekeuM>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Menu isLazy>
        <MenuButton
          as={Button}
          variant={"ghost"}
          h={"72px"}
          _hover={{ bg: "rgba(0, 0, 0, 0)" }}
          _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
          _active={{ bg: "rgba(0, 0, 0, 0)" }}
          px={0}
          onClick={onOpen}
        >
          <Flex gap={2} alignItems="center" justifyContent={"center"}>
            {totalCecoState[0]?.id ? (
              <>
                {totalCecoState?.length === 1 ? (
                  <Text color="brand.primary">
                    {totalCecoState[0]?.cost_center}
                  </Text>
                ) : (
                  <Text color="brand.primary">{`+${totalCecoState?.length} CECOS`}</Text>
                )}
                <BekeuIcon src={edit} h="20px" title="Editar" />
              </>
            ) : (
              <Text color="brand.primary">{"Seleccionar"}</Text>
            )}
          </Flex>
        </MenuButton>
      </Menu>
    </Flex>
  );
};

export default MenuCeco;
