import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import { ProductDetailBreadcrumb } from "../../components/ProductDetailBreadcrumb/ProductDetailBreadcrumb";
import { ProductDetailMainCard } from "../../components/ProductDetailMainCard/ProductDetailMainCard";
import { ProductDetailSheet } from "../../components/ProductDetailSheet/ProductDetailSheet";
import ProductDetailOrderCheckout from "../../components/ProductDetailOrderCheckout/ProductDetailOrderCheckout";
import ProductDetailOptions from "../../components/ProductDetailOptions/ProductDetailOptions";
import { ProductDetailDescription } from "../../components/ProductDetailDescription/ProductDetailDescription";

import * as productsGrid from "../productGridPage/ducks/productsGrid.duck";

const ProductDetailPage = ({ getOneProduct, ...props }) => {
  const mainState = useSelector((state) => state);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [product, setProduct] = useState("");
  const history = useHistory();

  const { sku } = useParams();

  useEffect(() => {
    getOneProduct(sku);
  }, [getOneProduct, sku]);

  useEffect(() => { 
    mainState?.productsGrid?.product?.prices &&
      setSelectedZone(Object.keys(mainState?.productsGrid?.product?.prices)[0]);
    mainState?.productsGrid?.product?.prices.length !== 0 &&
      setSelectedProvider(
        mainState?.productsGrid?.product?.prices[
          Object.keys(mainState?.productsGrid?.product?.prices)[0]
        ][0]
      );
  }, [mainState.productsGrid.product]);

  const handleChangeDeliveryZone = (code) => {
    setSelectedZone(code);
    setSelectedProvider(mainState?.productsGrid?.product?.prices[code][0]);
  };

  useEffect(() => {
    mainState?.productsGrid?.product !== null &&
      setProduct(mainState?.productsGrid?.product?.product_detail);
  }, [mainState.productsGrid.product]);

  const skeletonLoading = mainState.productsGrid.loading;

   useEffect(() => {
     const product =
       mainState?.productsGrid?.product?.product_detail?.product_name &&
       history.location.pathname.includes("detail");

     if (product) {
       document.title =
         mainState?.productsGrid?.product?.product_detail?.product_name;
     }
   }, [history.location.pathname, mainState.productsGrid]);

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        gap="32px"
        w="75rem"
        /*{...props}*/
      >
        <Flex alignItems={"center"}>
          <ProductDetailBreadcrumb
            product={mainState?.productsGrid?.product?.product_detail}
          />
        </Flex>
        <Flex justifyContent={"space-between"} width="100%" gap="32px">
          <ProductDetailMainCard
            product={mainState?.productsGrid?.product}
            skeletonLoading={skeletonLoading}
            width="65%"
          />
          <ProductDetailOrderCheckout
            handleChangeDeliveryZone={handleChangeDeliveryZone}
            provider={selectedProvider}
            product={mainState?.productsGrid?.product}
            selectedZone={selectedZone}
            skeletonLoading={skeletonLoading}
            width="35%"
          />
        </Flex>
        <Flex justifyContent={"space-between"} width="100%" gap="32px">
          <Flex flexDir={"column"} width="65%" gap="32px">
            <ProductDetailDescription
              skeletonLoading={skeletonLoading}
              product={mainState?.productsGrid?.product?.product_detail}
            />
            <ProductDetailSheet
              product={mainState?.productsGrid?.product}
              skeletonLoading={skeletonLoading}
            />
          </Flex>
          <ProductDetailOptions
            skeletonLoading={skeletonLoading}
            providers={mainState?.productsGrid?.product?.prices[selectedZone]}
            width="35%"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, productsGrid.actions)(ProductDetailPage)
);
