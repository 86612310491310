import { Button, Flex, Link, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import "./MainMenu.scss";
import help from "../../design-system/icons/Navbar/help-circle.svg";
import { useHistory } from "react-router-dom";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const MenuButton = ({ ...props }) => (
  <Button
    variant={"ghost"}
    bg={"brand.primary"}
    colorScheme={"blackAlpha"}
    color={"brand.white"}
    borderRadius={"12px"}
    ml={2}
    _hover={{ bg: "brand.hover" }}
    _focus={{
      boxShadow:
        "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
    }}
    _active={{
      boxShadow: "rgba(0, 0, 0, 0)",
      color: "brand.acents",
    }}
    {...props}
  >
    <Text fontSize={"16px"} fontWeight={"normal"}>
      {props.children}
    </Text>
  </Button>
);

const MainMenu = ({ user, userType, userRoles, userPermissions}) => {
  const location = useHistory().location.pathname;
  const history = useHistory();

  function handleClick(e, redirectPage) {
    e.preventDefault();
    history.push(redirectPage);
  }

  return (
    <Flex justifyContent={"space-between"} flex={1}>
      <>
        <Flex>
          {userType === "2" && (
            <>
              <Link
                href="/my-orders/?page=1&per_page=20"
                style={{ textDecoration: "none" }}
              >
                <MenuButton
                  variant={"ghost"}
                  bg={"brand.primary"}
                  colorScheme={"blackAlpha"}
                  color={"brand.white"}
                  borderRadius={"12px"}
                  ml={2}
                  isActive={location.includes("/my-orders")}
                  onClick={(e) =>
                    handleClick(e, "/my-orders/?page=1&per_page=20")
                  }
                >
                  Mis compras
                </MenuButton>
              </Link>
              {/*<Link
                href="/approve-orders/?state=1&page=1&per_page=20"
                style={{ textDecoration: "none" }}
              >
                <MenuButton
                  variant={"ghost"}
                  bg={"brand.primary"}
                  colorScheme={"blackAlpha"}
                  color={"brand.white"}
                  borderRadius={"12px"}
                  ml={2}
                  isActive={location.includes("/approve-orders")}
                  onClick={(e) =>
                    handleClick(
                      e,
                      "/approve-orders/?state=1&page=1&per_page=20"
                    )
                  }
                >
                  Aprobar pedidos
                </MenuButton>
              </Link>*/}
                <Link
                    href="/approve-carts/"
                    style={{ textDecoration: "none" }}
                >
                    <MenuButton
                        variant={"ghost"}
                        bg={"brand.primary"}
                        colorScheme={"blackAlpha"}
                        color={"brand.white"}
                        borderRadius={"12px"}
                        ml={2}
                        isActive={location.includes("/approve-carts")}
                        onClick={(e) =>
                            handleClick(
                                e,
                                "/approve-carts/"
                            )
                        }
                    >
                        Aprobar compras
                    </MenuButton>
                </Link>
              {/*<Link
                    href="/my-stats/?state=3&page=1&per_page=20"
                    style={{ textDecoration: "none" }}
                >
                    <MenuButton
                        variant={"ghost"}
                        bg={"brand.primary"}
                        colorScheme={"blackAlpha"}
                        color={"brand.white"}
                        borderRadius={"12px"}
                        ml={2}
                        isActive={location.includes("/my-stats")}
                        onClick={(e) =>
                            handleClick(e, "/my-stats/?state=3&page=1&per_page=20")
                        }
                    >
                        Mis Estadísticas
                    </MenuButton>
                </Link>*/}
            </>
          )}
          {userType === "3" && (
            <Link
              href="/sales/?state=2&page=1&per_page=20"
              style={{ textDecoration: "none" }}
            >
              <MenuButton
                variant={"ghost"}
                bg={"brand.primary"}
                colorScheme={"blackAlpha"}
                color={"brand.white"}
                borderRadius={"12px"}
                ml={"-16px"}
                isActive={location.includes("/sales")}
                onClick={(e) =>
                  handleClick(e, "/sales/?state=2&page=1&per_page=20")
                }
              >
                Mis ventas
              </MenuButton>
            </Link>
          )}
          {userType === "3" && (
            <Link
              href="/quotes?state=1&page=1&per_page=20"
              style={{ textDecoration: "none" }}
            >
              <MenuButton
                variant={"ghost"}
                bg={"brand.primary"}
                colorScheme={"blackAlpha"}
                color={"brand.white"}
                borderRadius={"12px"}
                ml={2}
                isActive={location.includes("/quotes")}
                onClick={(e) =>
                  handleClick(e, "/quotes?state=1&page=1&per_page=20")
                }
              >
                Mis cotizaciones
              </MenuButton>
            </Link>
          )}
          {/*userType === "3" && (
            <Link
              href="/my-clients"
              style={{ textDecoration: "none" }}
            >
              <MenuButton
                variant={"ghost"}
                bg={"brand.primary"}
                colorScheme={"blackAlpha"}
                color={"brand.white"}
                borderRadius={"12px"}
                ml={2}
                isActive={location.includes("/my-clients")}
                onClick={(e) =>
                  handleClick(e, "/my-clients")
                }
              >
                Mis Clientes
              </MenuButton>
            </Link>
          )*/}

          {user && userType === "2" &&
              (userRoles && userRoles.includes("whitelist-admin") ||
                  (userPermissions && userPermissions.includes("provider-company-list")))  && (
            <Link
                href="/my-providers"
                style={{ textDecoration: "none" }}
            >
                <MenuButton
                    variant={"ghost"}
                    bg={"brand.primary"}
                    colorScheme={"blackAlpha"}
                    color={"brand.white"}
                    borderRadius={"12px"}
                    ml={2}
                    isActive={location.includes("/my-providers")}
                    onClick={(e) =>
                        handleClick(e, "/my-providers")
                    }
                >
                    Mis proveedores
                </MenuButton>
            </Link>
          )}
          {userType === "3" && (
            <Link
              href="/shipping?state=1&page=1&per_page=20"
              style={{ textDecoration: "none" }}
            >
              <MenuButton
                variant={"ghost"}
                bg={"brand.primary"}
                colorScheme={"blackAlpha"}
                color={"brand.white"}
                borderRadius={"12px"}
                ml={2}
                isActive={location.includes("/shipping")}
                onClick={(e) =>
                  handleClick(e, "/shipping?state=1&page=1&per_page=20")
                }
              >
                Mis envíos
              </MenuButton>
            </Link>
          )}
        </Flex>
        <div>
          <Tooltip
            closeDelay={200}
            p={"12px"}
            borderTopLeftRadius={"0px"}
            label={
              <Text>
                Escribinos a <strong>info@bekeu.com</strong> con tu duda o
                comentario. Acordate de sumar información de tu compra.
              </Text>
            }
            bg="white"
            color="black"
            borderRadius={"12px"}
            borderWidth={"1px"}
            placement={"bottom-start"}
          >
            <Button
              as={Link}
              variant={"ghost"}
              bg={"brand.primary"}
              colorScheme={"blackAlpha"}
              color={"brand.white"}
              borderRadius={"12px"}
              ml={2}
              _hover={{
                bg: "brand.hover",
                borderBottomWidth: 0,
                textDecoration: "none",
              }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                borderBottomWidth: 0,
              }}
              _active={{
                boxShadow: "rgba(0, 0, 0, 0)",
                color: "brand.acents",
                borderBottomWidth: 0,
              }}
              leftIcon={<BekeuIcon src={help} />}
              href={"mailto:info@bekeu.com"}
            >
              <Text fontSize={"16px"} fontWeight={"normal"}>
                Ayuda
              </Text>
            </Button>
          </Tooltip>
        </div>
      </>
    </Flex>
  );
};

export default MainMenu;
