import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import tip from 'd3-tip'
import *  as d3scale from 'd3-scale'
import * as d3inter from 'd3-interpolate'
//import * as d3 from 'd3-geo';
import * as topojson from 'topojson-client';
import valueByIsoCode from "lodash";
import * as PropTypes from "prop-types";
import {ByZoneVerticalSVG} from "../ByZoneVerticalSVG/ByZoneVerticalSVG";
import {Flex} from "@chakra-ui/react";

const width = 1200;

const MapComponent = ({dataValues}) => {
    useEffect(() => {
        // Rest of the code remains the same, but use the `data` prop instead of the local `data` variable
    }, [dataValues]);

    const mapRef = useRef(null);

    useEffect(() => {
        const width = 1000;
        const height = 1000;
	    let valueByIsoCode = new Map();

        if(dataValues.length > 0) {
            const svg = d3.select(mapRef.current)
                .append('svg')
                .attr('width', width)
                .attr('height', height);

            d3.json('/argentina_provinces_v1.geo.json').then((provinceData) => {
                const provinces = topojson.feature(provinceData, provinceData.features);

                valueByIsoCode = new Map(dataValues.map(d => [d.address.province.iso_3166_2, d.quantity]));

                const projection = d3.geoMercator()
                    .scale(1200)
                    .rotate([0, 0])
                    .center([-35, -30])
                    .translate([1200, 200]);

                const pathGenerator = d3.geoPath().projection(projection);

                // Define the color scale
                const colorScale = d3.scaleSequential(d3.interpolateRgb("blue", "red"))
                    .domain([0, d3.max(dataValues, d => d.quantity)]);

                // Create tooltips
                const tooltip = tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html(d => `<div><strong>${d.properties.label_es}</strong>
                                <br>Cantidad: ${valueByIsoCode.get(d.properties.iso_3166_2) || '0'}</div>`)
                    .style("opacity", 0.5)
                    .style("background-color", "white")
                    .style("border", "1px solid black")
                    .style("border-width", "1px")
                    .style("border-radius", "1px")
                    .style("padding", "5px");

                svg.call(tooltip);
                tooltip.hide();

                try {
                    // Add province names

                    svg.selectAll()
                        .data(provinceData.features)
                        .join('path')
                        .attr('d', pathGenerator)
                        .attr('stroke', '#64247E')
                        //.attr('fill', '#ccc');
                        .on('mouseover', function (event, d) {
                            tooltip.show(d, this);
                            d3.select(this).attr('stroke', '#000').attr('stroke-width', '2px');
                        })
                        .on('mouseout', function (event, d) {
                            tooltip.hide();
                            d3.select(this).attr('stroke', '#333').attr('stroke-width', '1px');
                        })
                        .attr('fill', d => {
                            const isoCode = d.properties.iso_3166_2;
                            let value = valueByIsoCode.get(isoCode);
                            //console.log(`ISO: ${isoCode}, Value: ${value}`);
                            if (isNaN(value) || value === undefined) {
                                return '#ccc';
                            }
                            return colorScale(value);
                        });

                    svg.selectAll('text')
                        .data(provinceData.features)
                        .enter().append('text')
                        .attr('x', d => pathGenerator.centroid(d)[0])
                        .attr('y', d => pathGenerator.centroid(d)[1])
                        .attr('text-anchor', 'middle')
                        .attr('font-size', '14px')
                        .attr('fill', '#000')
                        .text(d => d.properties.label_es);

                    // Adding a legend
                    const legend = svg.append('g')
                        .attr('transform', `translate(${width - 200}, ${height - 30})`);

                    const legendScale = d3.scaleLinear()
                        .domain([0, d3.max(dataValues, d => d.quantity) * 1])
                        .range([0, 200]);

                    const legendAxis = d3.axisTop(legendScale)
                        .ticks(5);



                    // Add a color gradient to the legend
                    legend.append('defs')
                        .append('linearGradient')
                        .attr('id', 'legend-gradient')
                        .selectAll('stop')
                        .data(colorScale.ticks().map((tick, i, n) => ({
                            offset: `${100 * i / n.length}%`,
                            color: colorScale(tick)
                        })))
                        .enter().append('stop')
                        .attr('offset', d => d.offset)
                        .attr('stop-color', d => d.color);

                    legend.append('rect')
                        .attr('width', 200)
                        .attr('height', 20)
                        .style('fill', 'url(#legend-gradient)');

                    legend.append('g')
                        .call(legendAxis);



                } catch (error) {
                    console.error(error);
                }

            }).catch(error => {
                console.error('Error loading data:', error);
            });
        }
    }, [dataValues]);
    return (
        <div ref={mapRef}></div>
    );

};

export class MapChartSVG extends React.Component {
    render() {
        let {data, ...props} = this.props;

        return (
            <Flex>
                <Flex marginTop={30}>
                    <MapComponent dataValues={data} key={Math.random()}/>
                </Flex>
            </Flex>
        );
    }
}

MapChartSVG.propTypes = {data: PropTypes.any};
