import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, Brush
} from "recharts";

import * as PropTypes from "prop-types";

const monthTickFormatter = (tick: string) => {
    const date = new Date(tick);
    return date.getMonth() + 1;
};

const dayTickFormatter = (tick: string) => {
    const date = new Date(tick);
    return date.getDay() + 1;
};

const dateTickFormatter = (tick: string) => {
    const date = new Date(tick);
    return date.toLocaleDateString("en-us", { year:"numeric", month:"numeric"});
};

const renderQuarterTick = (tickProps: any) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    if (month % 3 === 1) {
        return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
    }
    const isLast = month === 11;

    if (month % 3 === 0 || isLast) {
        const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    }
    return null;
};

const ARSformat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 0, maximumFractionDigits: 0,});
const USDformat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,});

const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
};

export class StackedBarChart extends React.Component {
    render() {
        let {data, ...props} = this.props;

        let minDate = new Date();
        let maxDate = new Date();

        const normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,

                date: new Date(new Date(cart.created_at).getUTCFullYear(), new Date(cart.created_at).getMonth()),
                dateStr: new Date(cart.created_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                USD: parseFloat(cart.total_USD),
                ARS: parseFloat(cart.total_ARP),
                ARP: parseFloat(cart.total_ARP),
                quantity: Number(cart.item_quantity)

                })).sort((a, b) => a.date - b.date)
        };

        const datesMap = normalizedData.items.reduce((dateOrders, buyOrder)=> {
            const dateIndex = dateOrders.findIndex(item => item.dateStr === buyOrder.dateStr);
            if(dateIndex > -1){
                dateOrders[dateIndex].dateOrders.push(buyOrder);
                dateOrders[dateIndex].USD += buyOrder.USD;
                dateOrders[dateIndex].ARS += buyOrder.ARS;
            } else {
                dateOrders.push({
                    dateStr: buyOrder.dateStr,
                    date: new Date(buyOrder.date.getUTCFullYear(), buyOrder.date.getMonth()),
                    dateOrders: [buyOrder],
                    USD:buyOrder.USD,
                    ARS:buyOrder.ARS,
                    ARP:buyOrder.ARP
                })
            }
            return dateOrders;
        }, []);

        if(datesMap.length === 0){
            datesMap.push({
                dateStr: new Date().toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                date: new Date(),
                dateOrders: [],
                USD:0,
                ARS:0,
                ARP:0
            })

        } else{
            minDate = normalizedData.items[0].date;
            maxDate = normalizedData.items[normalizedData.items.length-1].date;
            
            let currDate = new Date(minDate.getFullYear(), minDate.getMonth()+1);
            while(currDate < maxDate){
                let currDateStr = currDate.toLocaleDateString("en-us", { year:"numeric", month:"numeric"});
                const dateIndex = datesMap.findIndex(item => item.dateStr === currDateStr);
                if(dateIndex > -1){
                    break;
                } else {
                    datesMap.push({
                        dateStr: new Date(currDate).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                        date: new Date(currDate.getUTCFullYear(), currDate.getMonth()),
                        dateOrders: [],
                        USD:0,
                        ARS:0,
                        ARP:0
                    })
                }
                currDate = new Date(currDate.getFullYear(), currDate.getMonth()+1);
            }
            datesMap.sort((a, b) => a.date - b.date)
        }

        return (
            <ResponsiveContainer height={200}>
            <BarChart

                data={datesMap}
                margin={{
                    top: 5,
                    right: 60,
                    left: 60,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" height={"200px"}/>
                <XAxis dataKey="dateStr" />

                <YAxis yAxisId="USD" orientation="left" stroke="#4CAF50" type="number"
                    tickFormatter={(value) =>
                        ARSformat.format(value)
                    }
                />
                <YAxis yAxisId="ARS" orientation="right" stroke="#64247E" type="number"
                    tickFormatter={(value) =>
                        ARSformat.format(value)
                    }
                />
                <Tooltip formatter={(value) => ARSformat.format(value)}/>


                <Legend formatter={renderColorfulLegendText}/>

                <Bar yAxisId="USD" dataKey="USD" label="Total USD" stackId="a" fill="#4CAF50" />
                <Bar yAxisId="ARS" dataKey="ARS" label="Total ARP" stackId="b" fill="#64247E"/>


            </BarChart>
            </ResponsiveContainer>
        );
    }
}
////<Brush spacing={4} dataKey="dateStr" height={30} stroke="#64247E" />
StackedBarChart.propTypes = {data: PropTypes.any}
;
