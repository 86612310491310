import React, { useEffect } from "react";
import { Flex, SimpleGrid, Text, useDisclosure, Box } from "@chakra-ui/react";
import {ResponsiveContainer} from "recharts";
import { H5 } from "../../design-system/headings/H5";
import {H6} from "../../design-system/headings/H6";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import useQuery from "../../hooks/useQuery";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as orderActions } from "./ducks/myStats.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";
import { StackedBarChart } from "../../components/Charts/StackedBarChart/StackedBarChart";
import { BekeuTreeMap } from "../../components/Charts/TreeMap/BekeuTreeMap";
import {QuantityStackedBarChart} from "../../components/Charts/QuantityStackedBarChart/QuantityStackedBarChart";
import {ByZoneVerticalSVG} from "../../components/Charts/ByZoneVerticalSVG/ByZoneVerticalSVG";
import {KPITotalTransactions} from "../../components/Charts/KPITotalTransactions/KPITotalTransactions";
import {KPITotalAmountARS} from "../../components/Charts/KPITotalAmountARS/KPITotalAmountARS";
import {KPITotalAmountUSD} from "../../components/Charts/KPITotalAmountUSD/KPITotalAmountUSD";
import {KPITotalItems} from "../../components/Charts/KPITotalItems/KPITotalItem";
import {BekeuTreeMapARS} from "../../components/Charts/TreeMap/BekeuTreeMapARS";
import {BekeuTreeMapUSD} from "../../components/Charts/TreeMap/BekeuTreeMapUSD";
import {MapChartSVG} from "../../components/Charts/MapChart/MapChart";


const MyStatsPage = ({ listStatCarts, listStatBuyOrders, listStatBuyOrdersItems, ...props }) => {
  const query = useQuery();
  const history = useHistory();
  const { pathname } = useLocation();

  const state = query.get("state");
  const d = query.get("d");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const from = query.get("from");
  const to = query.get("to");

  const mainState = useSelector((state) => state);
  const filteredCarts = mainState.stats.carts;
  const filteredBuyOrders = mainState.stats.buyorders;
  const filteredBuyOrderItems = mainState.stats.items;
  //const arr = [...new Array(mainState.stats.cartsPagination.totalPages)];
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasError = `${mainState.stats.result}`;

  useEffect(() => {
        listStatCarts({state, page, perPage, from, to,});
      },
      [state, page, perPage, from, to, listStatCarts]);

/*
  useEffect(() => {
    listStatBuyOrders({ state, page, perPage, from, to, });
  }, [state, page, perPage, from, to, listStatBuyOrders]);

  useEffect(() => {
    listStatBuyOrdersItems({ state, page, perPage, from, to, });
  }, [state, page, perPage, from, to, listStatBuyOrdersItems]);
*/
  useEffect(() => {
    hasError.includes("generalError") && onOpen();
  }, [hasError, onOpen]);

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });
  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item?.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex minH={"100vh"} flexDir={"column"} py={"40px"} bg={"brand.background"} w="75rem" /*{...props}*/ >
        <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />

        <H5>Mis Estadísticas</H5>

        <Flex justifyContent={"flex-end"}>
          <SimpleGrid mt={"70px"} minChildWidth="200px" spacing={4} w={"500px"}>
            <Box>
              <MenuBekeu
                height={"56px"}
                items={[
                  { name: "Pendiente de aprobación", state: "1" }, //Updated
                  { name: "En curso", state: "2" }, //Updated
                  { name: "Rechazado", state: "3" }, //Updated
                  { name: "Finalizado", state: "4" }, //Updated
                ]}
              >
                {state === "1"  //Updated
                  ? "Pendiente de aprobación"
                  : state === "2"  //Updated
                  ? "En curso"
                  : state === "3"  //Updated
                  ? "Rechazado"
                  : state === "4" && "Finalizado"}
              </MenuBekeu>
            </Box>
            <Box>
              <MenuBekeu
                height={"56px"}
                leftIcon
                setMoment={setMoment}
                state={state}
                items={[
                  {
                    name: "Todas",
                    date: "Todas",
                    to: formattedDate,
                    d: 0,
                  },
                  {
                    name: "Este mes",
                    date: "Este mes",
                    to: formattedDate,
                    d: 1,
                  },
                  {
                    name: "Últimos 3 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 3,
                  },
                  {
                    name: "Últimos 6 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 6,
                  },
                  {
                    name: "Últimos 12 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 12,
                  },
                ]}
              >
                {d
                  ? d === "0"
                    ? "Todas"
                    : d === "1"
                    ? "Este mes"
                    : d === "3"
                    ? "Últimos 3 meses"
                    : d === "6"
                    ? "Últimos 6 meses"
                    : d === "12" && "Últimos 12 meses"
                  : "Todas"}
              </MenuBekeu>
            </Box>

            {}
          </SimpleGrid>
        </Flex>
        <Flex mt={10} justifyContent={"center"}>
          <Flex minH={"100vh"} flexDir={"column"} py={"40px"} bg={"brand.background"} w="75rem" /*{...props}*/ >

          <H6>KPIs</H6>
            <SimpleGrid columns={4} spacing={1}>
              <KPITotalTransactions data={filteredCarts}></KPITotalTransactions>
              <KPITotalItems data={filteredCarts}></KPITotalItems>
              <KPITotalAmountARS data={filteredCarts}></KPITotalAmountARS>
              <KPITotalAmountUSD data={filteredCarts}></KPITotalAmountUSD>
            </SimpleGrid>
          <H6>Evolución de Pedidos Mensuales</H6>
            <StackedBarChart data={filteredCarts}></StackedBarChart>

          <H6>Evolución Cantidades Totales</H6>
            <QuantityStackedBarChart data={filteredCarts}></QuantityStackedBarChart>

          <H6>Montos de Pedidos por Zona</H6>
            <ByZoneVerticalSVG data={filteredCarts}></ByZoneVerticalSVG>


          <H6>Cantidad de Pedidos por Proveedor</H6>
            <BekeuTreeMap data={filteredCarts} mt={"10px"} minChildWidth="400px" spacing={4} height={"200px"}/>

          <SimpleGrid mt={10} columns={2} spacing={1}>
            <Box>
              <H6>Montos por Proveedor (ARS)</H6>
              <BekeuTreeMapARS data={filteredCarts} mt={"10px"} minChildWidth="400px" spacing={4} height={"200px"}/>
            </Box>
            <Box>
              <H6>Montos por Proveedor (U$D)</H6>
              <BekeuTreeMapUSD data={filteredCarts} mt={"10px"} minChildWidth="400px" spacing={4} height={"200px"}/>
            </Box>
          </SimpleGrid>
          </Flex>
        </Flex>

        <Flex justifyContent={"center"} w="75rem">
          <SimpleGrid mt={10} columns={1} spacing={1} w="75rem">
            <H6>Cantidad de Pedidos por Provincia</H6>
            <center>
              <Flex  bg={"#ADD8E6"}>
                <MapChartSVG data={filteredCarts}></MapChartSVG>
              </Flex>
            </center>
          </SimpleGrid>
        </Flex>


        {!mainState.stats.loading ? (
          <>
            {filteredCarts && filteredCarts.length > 0 ? (
                filteredCarts.map((item, id) => {
                return (
                  <></>
                );
              })
            ) : (
              <Text mt={6} className="animate__animated animate__fadeIn">
                No se encontraron compras{" "}
                {state === "1" //Updated
                  ? "pendientes de aprobación"
                  : state === "2" //Updated
                  ? "en curso"
                  : state === "3" //Updated
                  ? "rechazado"
                  : state === "4" && "finalizadas"}
              </Text>
            )}
          </>
        ) : (
          [1, 2].map((item, id) => (
            <></>
          ))
        )}
      </Flex>
    </Flex>
  );
};


export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(MyStatsPage)
);
