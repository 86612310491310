import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { Link, useHistory } from "react-router-dom";
import CartSuccessModal from "../../components/CartSuccessModal/CartSuccessModal";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

export const CartSummary = ({
  cart,
  state,
  handleEndsBuy,
  disabledByInputs,
  getCart,
}) => {
  const history = useHistory();
  const [subtotal, setSubtotal] = useState(0);
  const ARS = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS',});
  const USD = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'USD',});

  useEffect(() => {
    if (cart && cart?.items && cart && cart?.items.length >= 0) {
      let sum = 0;
      cart.items.forEach((item) => {
        sum += item?.value * item?.quantity;
      });
      setSubtotal(sum);
      return;
    }
    if (cart && cart?.items && cart?.items.length === 0) {
      setSubtotal(0);
      return;
    }
  }, [cart?.items]);

  const parsedArs = parseInt(cart?.total_ARP) !== 0;
  const parsedUsd = parseInt(cart?.total_USD) !== 0;

  return (
    <BoxBekeu
      mt={"32px"}
      w={"35%"}
      minH={"200px"}
      overflow="hidden"
      p={"0"}
      maxH={"fit-content"}
      zIndex={1}
    >
      <Flex flexDir={"column"} p={"24px"}>
        <H6>Resumen de compra</H6>
        {!state.cart.loading && (
          <>
            {cart?.items?.map((product, id) => (
              <Flex flexDir={"column"} pt={"6px"} key={id}>
                <Flex
                  justifyContent={"space-between"}
                  _hover={{ color: "brand.hover" }}
                  cursor={"pointer"}
                  onClick={() => history.push(`detail/${product.sku}`)}
                  transition="300ms ease all"
                  gap="18px"
                >
                  <Paragraphs isTruncated>{product?.title}</Paragraphs>
                  <Flex gap={1}>
                    {/*<Paragraphs fontWeight={600}>
                      {product.currency_code}{" "}
                    </Paragraphs>*/}
                    <Paragraphs fontWeight={600}>
                      {" "}
                      {(product.currency_code === "ARS") ? ARS.format(parseFloat(product?.total)) : USD.format(parseFloat(product?.total))}
                    </Paragraphs>
                  </Flex>
                </Flex>
              </Flex>
            ))}
            <Divider pt={"6px"} />
            {parsedArs && (
              <Flex justifyContent={"space-between"} mt={"12px"} mb={"6px"}>
                <H6>Total </H6>
                <H6>{cart?.total_w_shipping_ARP && ARS.format(parseFloat(cart?.total_w_shipping_ARP))} + Imp</H6>
              </Flex>
            )}
            {parsedUsd && (
              <Flex justifyContent={"space-between"} mt={"6px"} mb={"12px"}>
                <H6>Total </H6>
                <H6>{cart?.total_w_shipping_USD && USD.format(parseFloat(cart?.total_w_shipping_USD))} + Imp</H6>
              </Flex>
            )}
          </>
        )}
        <Flex flexDir={"column"}>
          <CartSuccessModal
            isDisabled={subtotal <= 0 || disabledByInputs}
            handleEndsBuy={handleEndsBuy}
            cart={cart}
            getCart={getCart}
            user
          />
          <Link to={"/search"}>
            <ButtonBekeuOutline>Continuar comprando</ButtonBekeuOutline>
          </Link>
        </Flex>
      </Flex>
    </BoxBekeu>
  );
};
