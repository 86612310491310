import React from 'react';
import { Text } from 'recharts';
import {Box, Flex, SimpleGrid} from "@chakra-ui/react";

const KPILabel = ({ kpiLabel, kpiValue, backgroundColor="#4CAF50", headerColor="#A5D297"}) => {
    return (
        <Box marginTop={10} marginBottom={100} width={300} height={100} backgroundColor={headerColor}>
            <center>
                <Text
                    x={10}
                    y={10}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    scaleToFit={false}
                    style={{fontSize: 24}}
                    fontWeight="bold"
                    fill="#4CAF50"
                >
                    {kpiLabel}
                </Text>
            </center>
            <Box margin={0} backgroundColor={backgroundColor} width={300} height={100}>
                <center>
                    <Text
                        x={10}
                        y={10}
                        textAnchor="middle"
                        scaleToFit={true}
                        fontWeight="bold"
                        style={{fontSize: 64}}
                    >
                        {kpiValue}
                    </Text>
                </center>
            </Box>
        </Box>
    );
};

export default KPILabel;
