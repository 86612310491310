import { Flex, Progress } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import * as sales from "../salesPage/ducks/sales.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import useQuery from "../../hooks/useQuery";
import SalesItem from "../../components/SalesItem/SalesItem";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";

const SalesDetailPage = ({listDetailSales, approveSales, rejectSales, ...props}) => {
  const params = useParams();
  const mainState = useSelector((state) => state);
  const [remitNumber, setRemitNumber] = useState("");
  const [sellerComments, setSellerComments] = useState("");

  const query = useQuery();
  const state = query.get("state");
  const tenant = query.get("tenant")?? "tenant";

  useEffect(() => {
    listDetailSales({ order_id: params.orderId , tenant:tenant});
  }, []);

  const filteredItem = mainState.sales.sales;

  const handleApproveOrder = (item, itemsState) => {
    approveSales({
      item,
      items: itemsState.filter((item) => item.qty_sent > 0),
      dispatch_note_number: remitNumber,
      tenant: tenant,
    });
  };

  const handleRejectOrder = (item, itemsState) => {
    rejectSales({
      item,
      items: itemsState.filter((oItem) => item.delivery_time === oItem.delivery_time),
      delivery_time: item.delivery_time,
      tenant: tenant,
    });
  };

  const handlePagination = () => {
    listDetailSales({ order_id: params.orderId });
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <ApproveOrdersBreadcrumb />
        <AlertBekeu my={"24px"}>
          ¡Hola! Ahora contas con la opción para realizar entregas parciales, si
          tus productos tienen plazos de entregas distintos entregá por bloques
          y evitá demoras.
        </AlertBekeu>
        <H5>Detalle del pedido</H5>
        {!mainState.sales.loading ? (
          filteredItem.map((item, id, itemArr) => {
            return (
              <SalesItem
                mt={"32px"}
                type={item.state}
                key={id}
                item={item}
                itemArr={itemArr}
                showitems
                selected
                status={item.status === "pending" ? true : undefined}
                handleApproveOrder={handleApproveOrder}
                handleRejectOrder={handleRejectOrder}
                setRemitNumber={setRemitNumber}
                setSellerComments={setSellerComments}
                handlePagination={handlePagination}
                sellerComments={sellerComments}
                remitNumber={remitNumber}
                params={params}
                state={state}
                getHistory={props.getHistory}
                tenant={tenant}
              />
            );
          })
        ) : (
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...sales.actions, ...product.actions })(SalesDetailPage)
);
