import React from "react";
import {Treemap, Tooltip, ResponsiveContainer, Legend} from "recharts";
import * as PropTypes from "prop-types";

const COLORS = [
    /*"#64247E",*/
    /*"#9597E4",*/
    "#4CAF50",
    "#A5D297",
    "#E2CF45",
    "#F8C12D"
];


const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div
                className="recharts-tooltip-wrapper">
                <p className="label">{`${payload[0].payload.provider} : ${payload[0].value}`}</p>
                <p className="intro">{}</p>
                <p className="desc"></p>
            </div>
        );
    } else
        return null;
}

const CustomizedContent = (props: any) => {
    const { root, depth, x, y, width, height, index, colors, provider, quantity } = props;
    //console.log(value);

    return (
        <g>

            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    fill:
                        ( depth < 2 && root.children)
                            ? colors[Math.floor((index / root.children.length) * 6)]
                            : "none",
                    stroke: "#fff",
                    strokeWidth: 2 / (depth + 1e-10),
                    strokeOpacity: 1 / (depth + 1e-10)
                }}
            />
            {depth === 1 ? (
                <text
                    x={x + width / 2}
                    y={y + height / 2 + 7}
                    textAnchor="middle"
                    fill="#fff"
                    fontSize={20}
                >
                    {quantity}
                </text>
            ) : null}
            {depth === 1 ? (
                <text style={{textWrap: "break-word"}} x={x + 4} y={y + 18} fill="#fff" fontSize={14} fillOpacity={0.9} textDecoration={"string"}>
                    {provider}
                </text>
            ) : null}
        </g>
    );
};

export class BekeuTreeMap extends React.Component{
    render() {
        let {data, ...props} = this.props;

        let minDate = new Date();
        let maxDate = new Date();

        const normalizedData = {
            name: "Data",
            items: data.map((d) => ({
                ...d,

                date: new Date(new Date(d.created_at).getUTCFullYear(), new Date(d.created_at).getMonth()),
                dateStr: new Date(d.created_at).toLocaleDateString("en-us", {year: "numeric", month: "numeric"}),
                USD: parseFloat(d.total_USD),
                ARS: parseFloat(d.total_ARP),
                ARP: parseFloat(d.total_ARP),
                count: d.count,
                internal_code: d.internal_code,


            })).sort((a, b) => b.count - a.count)
        };


        const stats = {quantity:0, totalUSD:0, totalARP:0};
        let providersMap = {}
        if(normalizedData.items.length > 0) {

            normalizedData.items.reduce((stats_obj, buyOrder) => {
                stats.quantity += buyOrder.quantity;
                stats.totalUSD += buyOrder.USD;
                stats.totalARP += buyOrder.ARP;
                return null;
            })

            // REFACTOR For each buy order in orders or use a different service
            providersMap = normalizedData.items.reduce((dateOrders, buyOrder) => {
                const dateIndex = dateOrders.findIndex(item => item.provider === buyOrder.buy_orders[0].provider.business_name);
                if (dateIndex > -1) {
                    dateOrders[dateIndex].dateOrders.push(buyOrder);
                    dateOrders[dateIndex].USD += buyOrder.USD;
                    dateOrders[dateIndex].ARS += buyOrder.ARS;
                    dateOrders[dateIndex].count += 1;
                    dateOrders[dateIndex].USD_percentage = dateOrders[dateIndex].USD / stats.totalUSD * 100;
                    dateOrders[dateIndex].ARP_percentage = dateOrders[dateIndex].ARP / stats.totalARP * 100;
                    dateOrders[dateIndex].quantity = dateOrders[dateIndex].quantity + buyOrder.quantity;
                    dateOrders[dateIndex].quantity_percentage = dateOrders[dateIndex].quantity / stats.quantity;

                } else {
                    dateOrders.push({
                        dateStr: buyOrder.dateStr,
                        date: new Date(buyOrder.date.getUTCFullYear(), buyOrder.date.getMonth()),
                        dateOrders: [buyOrder],
                        USD: buyOrder.USD,
                        ARS: buyOrder.ARS,
                        ARP: buyOrder.ARP,
                        count: 1,
                        internal_code: buyOrder.internal_code,
                        USD_percentage: buyOrder.USD / stats.totalUSD * 100,
                        ARP_percentage: buyOrder.ARP / stats.totalARP * 100,
                        quantity : buyOrder.quantity,
                        quantity_percentage : buyOrder.quantity / stats.quantity,
                        provider : buyOrder.buy_orders[0].provider.business_name
                    })
                    //console.log(buyOrder.buy_orders[0].provider.business_name);
                }
                return dateOrders;
            }, []);

            providersMap = providersMap.sort((a, b) => b.count - a.count).filter(a => a.count !== 0);

            if (providersMap.length === 0) {
                providersMap.push({
                    dateStr: new Date().toLocaleDateString("en-us", {year: "numeric", month: "numeric"}),
                    date: new Date(),
                    dateOrders: [],
                    USD: 0,
                    ARS: 0,
                    ARP: 0,
                    provider: "Sin Datos"
                })

            }
        }

        return (
            <ResponsiveContainer height={700}>
                <Treemap type={"flat"}
                        isAnimationActive={false}
                        height={500}
                        data={providersMap}
                        dataKey={"quantity"}
                        stroke="#fff"
                        fill="#8884d8"
                        content={<CustomizedContent colors={COLORS}/>}
                    >


                    <Legend formatter={renderColorfulLegendText}/>
                </Treemap>
            </ResponsiveContainer>
        );
    }
}
//<Tooltip isAnimationActive={false} content={<CustomTooltip />}/>
BekeuTreeMap.propTypes = {data: PropTypes.any};
