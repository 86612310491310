import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";

export const SalesSuccessModal = ({
  handleApproveOrder,
  setRemitNumber,
  remitNumber,
  handlePagination,
  item,
  itemsState,
  handleFillResult,
  enableSendOrder,
  ...props
}) => {
  const mainState = useSelector((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleApprove = () => {
    setLoading(true);
    handleApproveOrder(item.id, itemsState);
    setRemitNumber("");
  };

  return (
    <>
      <ButtonBekeu isDisabled={!enableSendOrder} onClick={onOpen} my={0} w="fit-content" px="62px">
        Enviar pedido
      </ButtonBekeu>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setLoading(false);
        }}
        onCloseComplete={() => {
          if (mainState.orders && mainState.orders.result === true) {
            handlePagination();
          }
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={"12px"} w="540px" h="338px">
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {mainState.orders && mainState.orders.result === true ? (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"48px"}
                alignItems="center"
              >
                <Img src={success} />
                <H5 textAlign="center" my="24px">
                  ¡El pedido fue enviado con éxito!
                </H5>
                <ButtonBekeu
                  onClick={() => {
                    onClose();
                    handlePagination();
                  }}
                >
                  Aceptar
                </ButtonBekeu>
              </Flex>
            ) : (
              <Flex
                flexDir={"column"}
                justifyContent="space-between"
                padding={"48px"}
              >
                <H5 textAlign="center">
                  ¿Estás seguro que queres enviar el pedido?
                </H5>
                <InputBekeu
                  value={remitNumber}
                  placeholder={"Ingresá el número de remito"}
                  mt={"32px"}
                  onChange={(e) =>
                    setRemitNumber(e.target.value.replace(/[^0-9,.]/g, ""))
                  }
                />
                <Flex alignItems="center" mt={"64px"}>
                  <ButtonBekeuOutline onClick={onClose} mr={4}>
                    No, volver atras
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    onClick={handleApprove}
                    isDisabled={remitNumber === ""}
                    isLoading={loading}
                    my={0}
                  >
                    Sí, enviar
                  </ButtonBekeu>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
