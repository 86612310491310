import React from "react";
import {CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";

import * as PropTypes from "prop-types";
import {Text} from "@chakra-ui/react";
import KPILabel from "../KPILabel/KPILabel";


export class KPITotalItems extends React.Component {
    render() {
        let {data, ...props} = this.props;

        let minDate = new Date();
        let maxDate = new Date();

        const normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,

                date: new Date(new Date(cart.created_at).getUTCFullYear(), new Date(cart.created_at).getMonth()),
                dateStr: new Date(cart.created_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                USD: parseFloat(cart.total_USD),
                ARS: parseFloat(cart.total_ARP),
                ARP: parseFloat(cart.total_ARP),
                quantity: Number(cart.item_quantity)
            }))
        };

        const dataMap = normalizedData.items.reduce((dateOrders, buyOrder)=> {
            const dateIndex = dateOrders.findIndex(item => item.dateStr === buyOrder.dateStr);
            const buyOrderQuantity = 10;

            if(dateIndex > -1){
                dateOrders[dateIndex].dateOrders.push(buyOrder);
                dateOrders[dateIndex].USD += buyOrder.USD;
                dateOrders[dateIndex].ARS += buyOrder.ARS;
                dateOrders[dateIndex].quantity += buyOrderQuantity;
                dateOrders[dateIndex].Cantidad += buyOrderQuantity;
            } else {
                dateOrders.push({
                    dateStr: buyOrder.dateStr,
                    date: new Date(buyOrder.date.getUTCFullYear(), buyOrder.date.getMonth()),
                    dateOrders: [buyOrder],
                    USD:buyOrder.USD,
                    ARS:buyOrder.ARS,
                    ARP:buyOrder.ARP,
                    quantity: buyOrderQuantity,
                    Cantidad:buyOrderQuantity
                })
            }
            return dateOrders;
        }, []);

        const kpiValue = dataMap.reduce((accumulator, dateOrder) => accumulator + dateOrder.quantity, 0);



        return (
            <KPILabel backgroundColor="#4CAF50" headerColor="#A5D297" kpiLabel="Items Totales" kpiValue={kpiValue}></KPILabel>
        );
    }
}

KPITotalItems.propTypes = {data: PropTypes.any}
;
