import React from "react";
import {CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";

import * as PropTypes from "prop-types";
import {Text} from "@chakra-ui/react";
import KPILabel from "../KPILabel/KPILabel";


export class KPITotalTransactions extends React.Component {
    render() {
        let {data, ...props} = this.props;

        let minDate = new Date();
        let maxDate = new Date();

        const normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,

                date: new Date(new Date(cart.created_at).getUTCFullYear(), new Date(cart.created_at).getMonth()),
                dateStr: new Date(cart.created_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
                USD: parseFloat(cart.total_USD),
                ARS: parseFloat(cart.total_ARP),
                ARP: parseFloat(cart.total_ARP),
                quantity: Number(cart.item_quantity)
            }))
        };
        const kpiValue = normalizedData.items.reduce((accumulator, buyOrder) => accumulator + buyOrder.buy_orders.length, 0);


        return (
            <KPILabel backgroundColor="#4CAF50" headerColor="#A5D297" kpiLabel="Transacciones Totales" kpiValue={kpiValue}></KPILabel>
        );
    }
}

KPITotalTransactions.propTypes = {data: PropTypes.any}
;
