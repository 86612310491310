import React from "react";
import {CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";

import * as PropTypes from "prop-types";
import {Text} from "@chakra-ui/react";
import KPILabelCurrency from "../KPILabel/KPILabelCurrency";


export class KPITotalAmountARS extends React.Component {
    render() {
        let {data, ...props} = this.props;

        let minDate = new Date();
        let maxDate = new Date();

       const normalizedData = {
            name: "Data",
            items: data.map((cart) => ({ ...cart,

               date: new Date(new Date(cart.created_at).getUTCFullYear(), new Date(cart.created_at).getMonth()),
               dateStr: new Date(cart.created_at).toLocaleDateString("en-us", { year:"numeric", month:"numeric"}),
               USD: parseFloat(cart.total_USD),
               ARS: parseFloat(cart.total_ARP),
               ARP: parseFloat(cart.total_ARP),
               quantity: Number(cart.item_quantity)
            }))
       };

        const kpiValue = normalizedData.items.reduce((accumulator, buyOrder) => accumulator + buyOrder.ARS, 0);

        return (
            <KPILabelCurrency  backgroundColor="#805AD5" headerColor="#9597E4" kpiLabel="Total Gestionado AR$" kpiValue={kpiValue}></KPILabelCurrency>
        );
    }
}

KPITotalAmountARS.propTypes = {data: PropTypes.any}
;
