import React, { useRef, useEffect, useState } from "react";
import {
  Flex,
  Img,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  OrderedList,
  useDisclosure,
  Text
} from "@chakra-ui/react";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import document from "../../design-system/icons/Import/document.svg";

const FormUploadDispatchNote = ({
  setFileNameInput,
  fileNameInput
}) => {
  const [counter, setCounter] = useState(0);
  const [visibleErrorMessage, setVisibleErrorMessage] = useState(false);
  const inputFile = useRef(null);

  const handleFileBrowse = () => {
    inputFile.current.click();
  };

  return(
    <>
      <input 
        type='file' 
        id='file' 
        ref={inputFile} 
        style={{display: 'none'}}
        accept="application/pdf"
        onChange={(e) => {
          e.preventDefault();
          let fileName = e.target.files[0]?.name.split('.');
          let fileExtension = fileName[fileName.length - 1];
          if(fileExtension !== 'pdf' && fileExtension !== 'PDF'){
            setVisibleErrorMessage(true);
            setFileNameInput({ name: '', file: null});
          }else{
            if(e.target.files[0]?.size <= 1100000){
              setVisibleErrorMessage(false);
              setFileNameInput({ name: e.target.files[0].name, file: e.target.files[0]});
            }else{
              setVisibleErrorMessage(true);
              setFileNameInput({ name: '', file: null});
            }
          }
        }}
      />

      <form
        id="dropzoneId"
        className="dropzone animate__animated animate__fadeIn"
        style={{
          width: "100%",
          height: "260px",
          border: "1px dashed",
          borderColor: "#BDBDBD",
          borderRadius: "12px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <Img src={document} alt="document" />
          <Paragraphs
            mt={"28px"}
            className="dz-message"
            data-dz-message
          >
            {!visibleErrorMessage && (fileNameInput?.name?.length <= 3) &&
            <Text>Por favor, subí el remito en formato .pdf y con tamaño máximo de 1mb</Text>
            }
            
            {visibleErrorMessage ?  (
              <Text style={{color:'red', fontSize:11}}>
              El archivo subido no cumple los parámetros requeridos. Por favor adjunta el remito en formato .PDF y con un tamaño máximo de 1 MB.
              </Text>
            )
            :
            (
              <Text>
                {fileNameInput.name}
              </Text>
            )
            }
          </Paragraphs>
          <ButtonBekeuM
            px={"33px"}
            onClick={handleFileBrowse}
          >
            Subir archivo
          </ButtonBekeuM>
        </>
      </form>
    </>
  )
}

export default FormUploadDispatchNote;