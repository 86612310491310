import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Flex } from "@chakra-ui/react";

const Layout = ({ children, history, user , userType, userRoles, userPermissions}) => {
  return (
    <Flex
      flexDir={"column"}
      justifyContent="space-between"
      minH={"100vh"}
      bgColor="brand.background"
    >
      <Header history={history} user={user} userType={userType} userRoles={user && userRoles} userPermissions={user && userPermissions} />
      {children}
      <Footer userType={userType} />
    </Flex>
  );
};

export default Layout;
