import React from 'react';
import { Text } from 'recharts';
import {AbsoluteCenter, Box, Center, Flex, SimpleGrid} from "@chakra-ui/react";

const KPILabelCurrency = ({ kpiLabel, kpiValue, backgroundColor="#4CAF50", headerColor="#A5D297"}) => {
    return (
        <Box marginTop={10} marginBottom={100} width={300} height={100} backgroundColor={headerColor}>
            <center>
                <Text
                    x={10}
                    y={10}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    scaleToFit={false}
                    style={{fontSize: 24}}
                    fontWeight="bold"
                    fill="#4CAF50"
                >
                    {kpiLabel}
                </Text>
            </center>
            <Box margin={0} backgroundColor={backgroundColor} width={300} height={100} alignItems="center" vertical-align="middle">
                <Center>
                    <Text
                        x={10}
                        y={50}
                        noOfLines={1}
                        vertical-align="middle"
                        textAnchor="middle"
                        fontSizeAdjust={true}
                        alignmentBaseline="central"
                        scaleToFit={true}
                        fontWeight="bold"
                        style={{fontSize: 36, marginTop:20}}
                    >
                        {kpiValue}
                    </Text>
                </Center>
            </Box>
        </Box>
    );
};

export default KPILabelCurrency;
