import { Box } from "@chakra-ui/react";
import React from "react";

export const SalesBadge = ({ children, item, state, ...props }) => {
  return (
    <Box
      color={
            state === "1" ? "brand.warning"
          : state === "2" ? "brand.warning"
          : state === "2.1" ? "brand.warning"
          : state === "3" ? "brand.error"
          : state === "3.2" ? "brand.error"
          : state === "4" ? "brand.success"
          : state === "5" ? "brand.warning"
          : "brand.success"
      }
      background={
            state === "2" ? "rgba(255, 152, 0, 0.2)"
          : state === "2.1" ? "rgba(255, 152, 0, 0.2)"
          : state === "3" ? "rgba(244, 67, 54, 0.2)"
          : state === "3.2" ? "rgba(244, 67, 54, 0.2)"
          : state === "4" ? "rgba(76, 175, 80, 0.2)"
          : state === "5" ? "rgba(255, 152, 0, 0.2)"
          : "rgba(76, 175, 80, 0.2)"
      }
      padding={"8px"}
      px={"12px"}
      borderRadius={"12px"}
      {...props}
    >
      {children}
    </Box>
  );
};
