import axios from "axios";
import { urlsBase } from "../../../../config";

export const ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders`;
export const MY_ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-sales`;
export const HISTORY_URL = `${urlsBase.base_url}/api/v1/dispatch-notes`;

export function listSales(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestOrders += `&state=${payload.state}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestOrders += `&to=${payload.to}`;
  }

  if (payload && payload.tenant && payload.tenant !== []) {
    requestOrders += `&tenant=${payload.tenant}`;
  }

  return axios.get(`${MY_ORDERS_URL}/${requestOrders}`);
}

export function listDetailSales({ order_id, tenant }) {
  return axios.get(`${MY_ORDERS_URL}/${order_id}?tenant=${tenant}`);
}

export function getHistory({ order_id, tenant }) {
  return axios.get(`${HISTORY_URL}/${order_id}?tenant=${tenant}`);
}

export function approveSales(payload) {
  return axios.put(`${ORDERS_URL}/send/${payload.item}?tenant=${payload.tenant}`, {
    items: payload.items,
    dispatch_note_number: payload.dispatch_note_number,
  });
}

export function rejectSales(payload) {
  return axios.put(`${ORDERS_URL}/cancel/${payload.item}?tenant=${payload.tenant}`, {
    items: payload.items,
    delivery_time: payload.item.delivery_time
  });
}
