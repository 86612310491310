import React, { useEffect, useState } from "react";
import { Flex, Progress, useDisclosure } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ApproveOrdersBreadcrumb } from "../ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as orderActions } from "../../pages/myOrdersPage/ducks/myOrders.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import MyOrdersItem from "../MyOrdersItem/MyOrdersItem";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import {getFeatures} from "../../pages/login/crud/auth.crud";

const MyOrdersStatus = (props) => {
  const params = useParams();
  const mainState = useSelector((state) => state);
  const [claim, setClaim] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileNameInput, setFileNameInput] = useState({ name: "", file: null});

  const hasError = `${mainState.carts.result}`;

  useEffect(() => {
    props.listDetailCart({ order_id: params.orderId });
  }, []);

  useEffect(() => {
    (hasError.includes("generalError") || hasError.includes("fileError")) && onOpen();
  }, [hasError, onOpen]);

  const filteredItem = mainState.carts.carts;

  const [sapAvailable, setSapAvailable] = useState(true);
  const [dispatchNoteAvailable, setDispatchNoteAvailable] = useState(true);
  const [shippingIncluded, setShippingIncluded] = useState(true);

  useEffect(() => {
    if(!mainState.auth.features)  getFeatures().then(r => {});
    console.log('|MyOrdersStatus|', mainState);
  }, [mainState.auth])

  useEffect(() => {
    console.log('---', mainState);
    //console.log(mainState?.auth?.features?.find((str) => str === 'SAP'));
    if(mainState?.auth?.features?.find((str) => str === 'SAP')){
      setSapAvailable(true);
    } else {
      //setSapAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'dispatch_note')){
      setDispatchNoteAvailable(true);
    } else {
      //setDispatchNoteAvailable(false);
    }
    if(mainState?.auth?.features?.find((str) => str === 'shipping_included')){
      setShippingIncluded(true);
    } else {
      //setShippingIncluded(false);
    }
    console.log('SAP: ' + sapAvailable + 'dispatch_note: ' + dispatchNoteAvailable + ', shipping_included: ' + shippingIncluded);
  }, [mainState.auth.features])


  const handleConfirmOrderCart = (item, dispatchNoteNumber) => {
    props.confirmMyOrdersCart({
      orderId: item,
      dispatchNoteNumber,
      file: fileNameInput
    });
  };

  const handleClaimOrderCart = (item, dispatchNoteNumber) => {
    props.claimMyOrdersCart({
      orderId: item?.id,
      claimMessage: claim,
      providerId: item?.provider_id,
      dispatchNoteNumber,
    });
  };

  const handlePagination = () => {
    props.listDetailCart({ order_id: params.orderId });
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <ApproveOrdersBreadcrumb />
        <H5 mt={"32px"}>Estado de la compra</H5>
        {mainState.carts.loading ? (
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        ) : filteredItem && filteredItem.length > 0 ? (
          filteredItem.map((item, id, itemArr) => {
            return (
              <MyOrdersItem
                mt={"32px"}
                key={id}
                item={item}
                showitems
                selected
                status={item.state === "pending" ? true : undefined}
                handleConfirmOrderCart={handleConfirmOrderCart}
                handleClaimOrderCart={handleClaimOrderCart}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                handlePagination={handlePagination}
                setClaim={setClaim}
                claim={claim}
                user={props.user}
                getHistory={props.getHistory}
                itemArr={itemArr}
                sapAvailable={sapAvailable}
                dispatchNoteAvailable={dispatchNoteAvailable}
                shippingIncluded={shippingIncluded}
              />
            );
          })
        ) : (
          "No se encontraron resultados"
        )}
        <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />
      </Flex>
    </Flex>
  );
};
export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(MyOrdersStatus)
);
