import React, {useEffect, useState } from "react";
import {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Divider,
  Switch,
  Checkbox,
  Stack,
  Img,
  Grid,
  GridItem,
  Spinner,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import editIcon from "../../design-system/icons/Essentials/message-edit.svg";
import checkPrimary from "../../design-system/icons/Shipping/success-circle-primary.svg";
import checkDisabled from "../../design-system/icons/Shipping/success-circle-disabled.svg";
import crossIcon from "../../design-system/icons/Errors/cross.svg";
import callIcon from "../../design-system/icons/Contact/call.svg";
import emailIcon from "../../design-system/icons/Contact/sms.svg";
import userIcon from "../../design-system/icons/Profile/profile.svg";
import moneyIcon from "../../design-system/icons/Profile/money-recive.svg";
import userCodeIcon from "../../design-system/icons/Profile/user-id.svg";


const ProviderText = ({ children, ...props }) => {
  return (
    <Text
      fontWeight={400}
      fontSize={"14px"}
      isTruncated
      lineHeight={"19px"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}>
      {children}
    </Text>
  );
};

// const ProviderButton = ({ src, onClick, label = "", editState, ...props }) => {
//   return (
//     <Flex cursor={"pointer"} overflow="hidden" justifyContent={"center"}>
//       <Button
//         variant={"link"}
//         p={0}
//         _focus={{
//           boxShadow: "rgba(0, 0, 0, 0)",
//         }}
//         onClick={onClick}
//         className="animate__animated animate__fadeIn"
//         {...props}>
//         {editState ? (
//           <BekeuIcon src={src} width={"1.5rem"} height={"1.5rem"} title={label} />
//         ) : (
//           <BekeuIcon src={src} width={"1.5rem"} height={"1.5rem"} title="" />
//         )}
//       </Button>
//     </Flex>
//   );
// };

function CustomIcon(props) {
  return <Img src={crossIcon} padding={"1"} alt="cross" style={{border: props.isChecked ? "0" : "1px solid #BDBDBD", borderRadius:"0.3rem"}}/>;
}

const PROVIDERACTIONS = {
  play: "play",
  pause: "pause",
  block: "block",
  unblock: "unblock",
};

export const ProviderItem = ({
  item,
  handleEnable,
  handleDisable,
  handleBlock,
  handleUnblock,
  handleChangeInternalCode,
  handleSaveInternalCode,
  SAPNumber,
  result
  //changePaymentCondition,
}) => {
  const { isOpen, isBlockOpen, onOpen, onBlockOpen, onClose, onBlockClose } =
    useDisclosure();
  
  const [loading, setLoading] = useState(false);
  // const [inputValue, setInputValue] = useState(item?.payment_condition);
  const [checkSuccessState, setCheckSuccessState] = useState(editIcon);
  const [actionName, setActionName] = useState("");
  const [actionCaption, setActionCaption] = useState("");
  const [actionOnClick, setActionOnClick] = useState({});
  const [editSAP, setEditSAP] = useState({ id: null, status: false });
  const [infoModal, setInfoModal] = useState({ show: false, item: {} });

  useEffect(() => {
    // setInputValue(item?.payment_condition);

    // Change providers switch color
    const providerSwitchStatus = document.querySelector(
      `#playPause-${item.provider_id}`
    );
    if (providerSwitchStatus !== null) {
      if (providerSwitchStatus.querySelector("span[data-checked]") !== null) {
        providerSwitchStatus.querySelector(
          "span[data-checked]"
        ).style.backgroundColor = "#4CAF50";
      } else {
        providerSwitchStatus.querySelector("span").style.background = "#9E9E9E";
      }
    }

    // Change checkbox style
    const providerCheckboxStatus = document.querySelector(`#checkboxStyle-${item.provider_id}`);
    if (providerCheckboxStatus !== null){
      if (providerCheckboxStatus.querySelector('span[data-checked]') !== null){
        providerCheckboxStatus.querySelector("span[data-checked]").style.borderRadius = ".3rem";
        providerCheckboxStatus.querySelector("span[data-checked]").style.width = "1.5rem";
        providerCheckboxStatus.querySelector("span[data-checked]").style.height = "1.5rem";
      } else {
        providerCheckboxStatus.querySelector("span").style.width = "1.5rem";
        providerCheckboxStatus.querySelector("span").style.height = "1.5rem";
      }
    }

  }, [item]);

  useEffect(() => {
    if (SAPNumber !== "" && editSAP.status){
      setCheckSuccessState(checkPrimary);
    }
      
    if (SAPNumber === "" && editSAP.status) {
      setCheckSuccessState(checkDisabled);
    }
  }, [SAPNumber]);

  const handleActionOnClick = (actionDetails) => {
    if (actionDetails.action === PROVIDERACTIONS.play) {
      return handleEnable(actionDetails.itemId);
    }

    if (actionDetails.action === PROVIDERACTIONS.pause) {
      return handleDisable(actionDetails.itemId);
    }

    if (actionDetails.action === PROVIDERACTIONS.unblock) {
      return handleUnblock(actionDetails.itemId);
    }

    if (actionDetails.action === PROVIDERACTIONS.block) {
      return handleBlock(actionDetails.itemId);
    }
  };

  const handleAllowEditInternalCode = (inputId, providerId) => {
    if (editSAP.status && checkSuccessState !== checkDisabled) {
      setLoading(true);
      setTimeout(() => {
        handleSaveInternalCode(inputId, providerId);
      }, 1000)
      setEditSAP({ id: null, status: false });
    } else {
      const actualValue = document.querySelector(`#inputSAP-${inputId}`).value;
      actualValue === "" && setCheckSuccessState(checkDisabled); 
      handleChangeInternalCode(actualValue, inputId);
      setEditSAP({ id: inputId, status: true });
    }
  };

  const showInfoModal = (item) => {
    setInfoModal({
      show: true,
      item: item,
    });
  };

  return (
    <>
      <ErrorBoundary fallback={<p>Something went wrong Loaded Providers</p>}>
        <Grid templateColumns="8rem 8rem 9rem 20rem repeat(2, 1fr)" gap={3}>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={() => {
              setLoading(false);
            }}
            size="xl"
            isCentered>
            <ModalOverlay />
            <ModalContent
              borderRadius="0.75rem"
              fontFamily={"Open Sans"}
              flexDir={"row"}>
              <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />

              <ModalBody
                borderRadius={"0.75rem"}
                m={"1.25rem"}
                textAlign="center"
                p="1.5rem">
                <H5 mb={"1.5rem"}>
                  {`¿Estás seguro que querés ${actionName} a este proveedor?`}
                </H5>
                <Paragraphs mb={"1.5rem"} lineHeight="22px">
                  {actionCaption}
                </Paragraphs>
                <Flex w="100%">
                  <ButtonBekeuOutline mr="0.75rem" onClick={onClose}>
                    No, cancelar
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    my="0"
                    ml="0.75rem"
                    onClick={() => {
                      handleActionOnClick(actionOnClick);
                      setLoading(true);
                    }}
                    isLoading={loading}>
                    {`Sí, ${actionName}`}
                  </ButtonBekeu>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>

          {item?.internal_code ? (
            <>
              <GridItem my={4} placeContent={"center"}>
                <Box textAlign={"center"} id={`playPause-${item.provider_id}`}>
                  <Switch
                    onChange={() => {
                      setActionName(
                        item?.enable_client === 0 ? "activar" : "desactivar"
                      );
                      setActionCaption(
                        item?.enable_client === 0
                          ? "Si activas el proveedor sus productos estarán disponibles para comprar"
                          : "Los compradores de tu empresa verán las publicaciones del proveedor, pero estarán desactivadas para comprar."
                      );
                      setActionOnClick({
                        action: item?.enable_client === 0 ? "play" : "pause",
                        itemId: item?.provider_id,
                      });
                      onOpen();
                    }}
                    aria-label="Activar-Pausar"
                    size={"md"}
                    isChecked={item?.enable_client}
                    disabled={item?.client_block === 1}
                  />
                </Box>
              </GridItem>
              
              {/* Block - Unblock */}
              <GridItem my={4} placeContent={"center"}>
                <Box
                  textAlign={"center"}
                  id={`checkboxStyle-${item.provider_id}`}>
                  <Checkbox
                    variant={"unstyled"}
                    onChange={() => {
                      setActionName(
                        item?.client_block === 1 ? "desbloquear" : "bloquear"
                      );
                      setActionCaption(
                        item?.client_block === 1
                          ? "Las publicaciones del proveedor estarán visibles para comprar por los compradores de tu empresa."
                          : "Las publicaciones del proveedor no estarán visibles para comprar por los compradores de tu empresa."
                      );
                      setActionOnClick({
                        action: item?.client_block === 0 ? "block" : "unblock",
                        itemId: item?.provider_id,
                      });
                      onOpen();
                    }}
                    isChecked={item?.client_block}
                    icon={<CustomIcon clientBlock={item?.client_block}/>}
                    colorScheme={"red"}
                    borderColor={"unset"}
                  />
                </Box>
              </GridItem>
            </>
          ) : (
            <>
              <GridItem my={4} placeContent={"center"}>
                <Box textAlign={"center"}></Box>
              </GridItem>

              <GridItem my={4} placeContent={"center"}>
                <Box textAlign={"center"}></Box>
              </GridItem>
            </>
          )}

          {/* CUIT */}
          <GridItem my={4} placeContent={"center"}>
            <Box textAlign={"center"}>{item?.cuit ? item?.cuit : "" }</Box>
          </GridItem>

          {/* NAME */}
          <GridItem my={4} placeContent={"center"} maxW={"20rem"}>
            <Box textAlign={"center"}>
              <ProviderText
                cursor={"pointer"}
                onClick={() => showInfoModal(item)}
                color={"brand.primary"}
                fontWeight={"bold"}>
                {item?.business_name}
              </ProviderText>
            </Box>
          </GridItem>

          {/* MODAL INFO */}
          <Modal
            isOpen={infoModal.show}
            onClose={onClose}
            onCloseComplete={() => {
              setLoading(false);
            }}
            size="lg"
            isCentered>
            <ModalOverlay />
            <ModalContent
              borderRadius="0.75rem"
              fontFamily={"Open Sans"}
              flexDir={"row"}>
              <ModalCloseButton
                _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                onClick={(e) => setInfoModal({ show: false, item: null })}
              />
              <ModalBody
                borderRadius={"0.75rem"}
                m={"1.25rem"}
                textAlign="center"
                p="1.5rem">
                <H5 mb={"1.5rem"}>
                  {item?.business_name}
                  {item?.cuit && <Text fontSize={"md"}>CUIT {item.cuit}</Text>}
                </H5>
                <Stack
                  spacing={3}
                  width={["auto", "18.75rem"]}
                  margin={"auto"}
                  alignItems={"flex-start"}
                  textAlign={"left"}>
                  {item?.name && (
                    <Flex>
                      <Img src={userIcon} alt={"user"} mr="1"></Img>
                      <Text
                        fontSize={"md"}
                        color={"brand.primary"}
                        fontWeight={
                          "bold"
                        }>{`${item.name} ${item.last_name}`}</Text>
                    </Flex>
                  )}
                  {item?.email && (
                    <Flex>
                      <Img src={emailIcon} alt={"email"} mr="1"></Img>
                      <Text
                        fontSize={"md"}
                        style={{
                          color: "#515151",
                        }}>
                        {item.email}
                      </Text>
                    </Flex>
                  )}

                  {item?.phone && (
                    <Flex>
                      <Img src={callIcon} alt={"phone_number"} mr="1"></Img>
                      <Text
                        fontSize={"md"}
                        style={{
                          color: "#515151",
                        }}>
                        {item.phone}
                      </Text>
                    </Flex>
                  )}

                  {item?.iibb && (
                    <Flex>
                      <Img src={moneyIcon} alt={"iibb"} mr="1"></Img>
                      <Text
                        fontSize={"md"}
                        style={{
                          color: "#515151",
                        }}>
                        {item.iibb}
                      </Text>
                    </Flex>
                  )}

                  {item?.internal_code && (
                    <Flex>
                      <Img
                        src={userCodeIcon}
                        alt={"internal_code"}
                        mr="1"></Img>
                      <Text
                        fontSize={"md"}
                        style={{
                          color: "#515151",
                        }}>
                        {item.internal_code}
                      </Text>
                    </Flex>
                  )}
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* SAP */}
          <GridItem my={4} placeContent={"center"}>
            <Box textAlign={"center"} m={"auto"} minW={"9rem"}>
              <Stack justifyContent={"center"}>
                <InputGroup>
                  <Input
                    h={["3rem"]}
                    size={["sm"]}
                    type="text"
                    defaultValue={item?.internal_code}
                    placeholder="N° SAP"
                    id={`inputSAP-${item?.provider_id}`}
                    onChange={(e) =>
                      handleChangeInternalCode(e.target.value, item?.provider_id)
                    }
                    isDisabled={editSAP.id === item.provider_id ? false : true}
                    borderRadius={"0.75rem"}
                    padding={["1.5rem 1rem"]}
                    style={{border:"1px solid #BDBDBD", opacity:"1"}}
                  />
                  {loading ? (
                      <InputRightElement h={"100%"}>
                        <Spinner
                          thickness="2px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="sm"
                          margin={"0 !important"}
                          background={"white"}
                        />
                    </InputRightElement>
                  ) : (
                    <InputRightElement
                      h={"100%"}
                      onClick={(e) =>
                        checkSuccessState === checkDisabled
                          ? e.preventDefault()
                          : handleAllowEditInternalCode(item.provider_id)
                      }
                      cursor={
                        checkSuccessState === checkDisabled
                        ? "not-allowed"
                        : "pointer"
                      }
                      >
                      <Img
                        background={"white"}
                        width={"1.4rem"}
                        height={"1.4rem"}
                        // margin={"0 !important"}
                        src={checkSuccessState}
                        alt="edit"
                      />
                    </InputRightElement>
                  )}
                </InputGroup>
              </Stack>
            </Box>
          </GridItem>

          {/* STATUS */}
          <GridItem my={4} placeContent={"center"}>
            <Box textAlign={"center"}>
              <Flex justifyContent={"center"}>
                {item?.internal_code === null ? (
                  <Text
                    borderRadius={"0.75rem"}
                    bg={"rgba(244, 67, 54, 0.2)"}
                    color={"brand.error"}
                    paddingInline={"2.5"}
                    paddingY={"1.5"}
                    minW={"8rem"}
                    fontWeight={"nomal"}>
                    Sin N° SAP
                  </Text>
                ) : item?.client_block === 1 ? (
                  <Text
                    borderRadius={"0.75rem"}
                    color={"brand.error"}
                    bg={"rgba(244, 67, 54, 0.2)"}
                    paddingInline={"2.5"}
                    paddingY={"1.5"}
                    minW={"8rem"}
                    fontWeight={"nomal"}>
                    Bloqueado
                  </Text>
                ) : item?.enable_client === 1 ? (
                  <Text
                    bg="rgba(76, 175, 80, 0.2)"
                    borderRadius={"0.75rem"}
                    color={"brand.success"}
                    paddingInline={"2.5"}
                    paddingY={"1.5"}
                    minW={"8rem"}
                    fontWeight={"nomal"}>
                    Activado
                  </Text>
                ) : (
                  <Text
                    borderRadius={"0.75rem"}
                    bg={"rgba(255, 152, 0, 0.2)"}
                    color={"brand.warning"}
                    paddingInline={"2.5"}
                    paddingY={"1.5"}
                    minW={"8rem"}
                    fontWeight={"nomal"}>
                    Desactivado
                  </Text>
                )}
              </Flex>
            </Box>
          </GridItem>

          {/* <GridItem my={4} placeContent={"center"}>
          <Box textAlign={"center"}>
            <ProviderText></ProviderText>
          </Box>
        </GridItem> */}
        </Grid>
        <Divider />
      </ErrorBoundary>
    </>
  );
};
// export { ProviderList };
