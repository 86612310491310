import {
    Avatar,
    AvatarGroup,
    Button, Collapse,
    Divider,
    Flex,
    Img, Spinner,
    Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ApproveOrdersBadge } from "../ApproveOrdersBadge/ApproveOrdersBadge";
import eye from "../../design-system/icons/Security/eye.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
//import * as cart from "../../store/cart/cart.duck";
import * as cartActions from "../../pages/approveCartsPage/ducks/approveCarts.duck";

import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";


import { ApproveCartsRejectModal } from "../ApproveCartsRejectModal/ApproveCartsRejectModal";
import { ApproveCartsSuccessModal } from "../ApproveCartsSuccessModal/ApproveCartsSuccessModal";
import { ShippingBlock } from "../ShippingBlock/ShippingBlock";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useSelector } from "react-redux";
import trash from "../../design-system/icons/Cart/trash.svg";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { useState } from "react";
import notFoundImg from "../../design-system/images/not-found.png";
import {CartOrderId} from "../CartOrderId/CartOrderId";
import {ApproveCartsBadge} from "../ApproveCartsBadge/ApproveCartsBadge";
import {MyOrdersItemBadge} from "../MyOrdersItemBadge/MyOrdersItemBadge";
import {MaxDeliveryTimeBadge} from "../MaxDeliveryTimeBadge/MaxDeliveryTimeBadge";
import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import {HistoryItem} from "../HistoryItem/HistoryItem";

const DetailItem = ({
                        orderItem,
                        handlePagination,
                        item,
                        itemArr,
                        handleScrollTo,
                        history,
                        handleNoProduct,
                        handleGetHistory,
                        noProduct,

                    }) => {
    const [isOpen, setIsOpen] = useState(false);
    const mainState = useSelector((state) => state);
    const [itemsState, setItemsState] = useState([]);
    const [imageError, setImageError] = useState(false);
    const [sapAvailable, setSapAvailable] = useState(false);
    const [dispatchNoteAvailable, setDispatchNoteAvailable] = useState(false);
    const ARS = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS',});
    const USD = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'USD',});

    useEffect(() => {
        setItemsState(
            itemArr &&
            itemArr[0]?.items?.map((orderItemVal) => {
                return {
                    buy_order_item_id: orderItemVal?.id,
                    qty_sent: orderItemVal?.quantity - orderItemVal?.quantity_sent,
                    delivery_time: orderItemVal?.days_delivery_time_block,
                };
            })
        );
    }, [itemArr]);

    let newDeliveryArray = [];

    if (orderItem && orderItem.items) {
        orderItem.items.forEach((x, i) => {
            if (!newDeliveryArray.hasOwnProperty(x.days_delivery_time_block)) {
                newDeliveryArray[x.days_delivery_time_block] = {
                    days_delivery_time_block: [],
                };
            }

            newDeliveryArray[
                x.days_delivery_time_block
                ].days_delivery_time_block.push({
                ...x,
                days_delivery_time_block: x.days_delivery_time_block,
            });
        });
    }

    return (
        <Flex flexDir={"column"} justifyContent={"center"} alignItems="center">
            <BoxBekeu
                width={"85%"}
                my={"48px"}
                boxShadow="none"
                border="0.5px solid"
                borderColor="brand.border"
                p={0}
            >
                <Flex flexDir={"column"}>
                    <Flex alignItems={"center"} justifyContent={"space-between"} p="24px">
                        <ProductDetailModalSeller
                            sellerName={orderItem.provider?.fantasy_name}
                            email={orderItem.provider?.email}
                            phone={orderItem.provider?.phone}
                        />
                        <MyOrdersItemBadge item={orderItem}>
                            { orderItem?.state === "1" ? "Pendiente"
                            : orderItem?.state === "2" ? "Entrega en proceso"
                            : orderItem?.state === "2.1" ? "Enviada por proveedor"
                            : orderItem?.state === "3" ? "Rechazada"
                            : orderItem?.state === "3.1" ? "Rechazada por Aprobador"
                            : orderItem?.state === "3.2" ? "Rechazada por Proveedor"
                            : orderItem?.state === "3.3" ? "Rechazada por ERP"
                            : orderItem?.state === "3.4" ? "Rechazada por Sistema"
                            : orderItem?.state === "4" ? "Confirmada"
                            : orderItem?.state === "5" ? "Reclamada"
                            : "Pendiente"}
                        </MyOrdersItemBadge>
                    </Flex>
                    <Divider />
                    <Flex
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        px="24px"
                    >
                        {orderItem?.currency_code && (
                            <Text
                                fontWeight={400}
                                fontSize={"16px"}
                                color="brand.contentSecondary"
                                py="24px"
                            >
                                Importe en {orderItem?.currency_code}
                            </Text>
                        )}
                        {orderItem?.erp_number && (
                            <Text
                                fontWeight={400}
                                fontSize={"16px"}
                                border="1px solid"
                                borderColor={"brand.contentSecondary"}
                                padding={"8px"}
                                borderRadius={"12px"}
                                color="brand.contentSecondary"
                            >
                                Nro de Pedido SAP: {orderItem?.erp_number}
                            </Text>
                        )}
                        {orderItem?.internal_code && (
                            <Text
                                fontWeight={400}
                                fontSize={"16px"}
                                border="1px solid"
                                borderColor={"brand.contentSecondary"}
                                padding={"8px"}
                                borderRadius={"12px"}
                                color="brand.contentSecondary"
                            >
                                Código interno: {orderItem?.internal_code}
                            </Text>
                        )}
                    </Flex>
                    {newDeliveryArray.map((newDeliveryArray, id) => (
                        <Flex flexDir={"column"} key={id}>
                            <Divider />
                            <MaxDeliveryTimeBadge
                                days={
                                    newDeliveryArray?.days_delivery_time_block[0]
                                        ?.days_delivery_time_block
                                }
                                m="24px"
                            />
                            {newDeliveryArray?.days_delivery_time_block?.map((i, id) => (
                                <div key={id}>
                                    <Divider />
                                    <Flex justifyContent={"space-between"} p="24px" gap="12px">
                                        <Flex>
                                            <Flex
                                                w="88px"
                                                h="88px"
                                                mr="20px"
                                                border="1px solid"
                                                borderColor={"brand.border"}
                                                borderRadius={"12px"}
                                                overflow="hidden"
                                                justifyContent={"center"}
                                                alignItems="center"
                                                gap="24px"
                                            >
                                                <Img
                                                    src={
                                                        imageError
                                                            ? notFoundImg
                                                            : i?.combination?.media?.image_source ||
                                                            i?.data_combination?.image_source
                                                    }
                                                    onError={() => setImageError(true)}
                                                    draggable={"false"}
                                                    objectFit="cover"
                                                    cursor={imageError ? "not-allowed" : "pointer"}
                                                    onClick={() => handleNoProduct(i)}
                                                    title={
                                                        imageError
                                                            ? "Imagen no disponible"
                                                            : i?.combination?.title ||
                                                            i?.data_combination?.title
                                                    }
                                                    h={imageError ? "50%" : "100%"}
                                                />
                                            </Flex>
                                            <Flex flexDir={"column"} gap="12px">
                                                <Paragraphs
                                                    lineHeight={"22px"}
                                                    _hover={{ color: "brand.hover" }}
                                                    transition="300ms ease all"
                                                    onClick={() => handleNoProduct(i)}
                                                    noOfLines={2}
                                                    cursor="pointer"
                                                    w={"325px"}
                                                >
                                                    {i?.combination?.title || i?.data_combination?.title}
                                                </Paragraphs>
                                                <H6>
                                                    {i?.currency_code} {ARS.format(parseFloat(i?.price_unit))} + Imp
                                                </H6>
                                                <Flex flexDir={"column"}>
                                                    <Paragraphs
                                                        lineHeight={"22px"}
                                                        mb="3px"
                                                        fontWeight={600}
                                                    >
                                                        Centros de costo:
                                                    </Paragraphs>
                                                    {i?.cost_centers?.map((item, key) => (
                                                        <Flex
                                                            key={key}
                                                            justifyContent={"space-between"}
                                                            w="80%"
                                                        >
                                                            <Paragraphs lineHeight={"22px"}>
                                                                {item?.cost_center}
                                                            </Paragraphs>
                                                            <Paragraphs lineHeight={"22px"}>
                                                                {item?.percentage}%
                                                            </Paragraphs>
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                                            <Text>Cantidad comprada</Text>
                                            <Text>{i?.quantity}</Text>
                                        </Flex>
                                        <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                                            <Text>Cantidad enviada</Text>
                                            <Text>{i?.quantity_sent}</Text>
                                        </Flex>
                                        <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                                            <Text>Cantidad confirmada</Text>
                                            <Text>{i?.quantity_confirmed}</Text>
                                        </Flex>
                                        <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                                            <Text>Cantidad cancelada</Text>
                                            <Text>{i?.quantity_cancel}</Text>
                                        </Flex>
                                    </Flex>
                                </div>
                            ))}
                        </Flex>
                    ))}

                    {noProduct && (
                        <Text
                            fontSize={"sm"}
                            mb={4}
                            className="animate__animated animate__fadeIn"
                        >
                            Este producto no se encuentra disponible para comprar nuevamente
                        </Text>
                    )}
                    <Divider />
                    <Flex
                        flexDir={"column"}
                        alignItems="flex-end"
                        mt={"16px"}
                        gap="12px"
                        p="24px"
                    >
                        {orderItem?.total_ARP && parseInt(orderItem?.total_ARP) !== 0 && (
                            <>
                                <Flex justifyContent={"space-between"} gap={"16px"}>
                                    <H6 fontWeight={400}>Subtotal productos en ARS:</H6>{" "}
                                    <H6 fontWeight={400}>{ARS.format(parseFloat(orderItem?.total_ARP))} + Imp</H6>
                                </Flex>
                                <Flex justifyContent={"space-between"} gap={"16px"}>
                                    <H6 fontWeight={400}>Costo de envío en ARS:</H6>
                                    {!orderItem?.shipping_cost_ARP || orderItem?.shipping_cost_ARP ? (
                                        <H6 fontWeight={400} color="brand.success">
                                            Incluído
                                        </H6>
                                    ) : (
                                        <H6 fontWeight={400}>
                                            {ARS.format(parseFloat(orderItem?.shipping_cost_ARP))} + Imp
                                        </H6>
                                    )}
                                </Flex>
                                <H5>Total en ARS {ARS.format(parseFloat(orderItem?.total_w_shipping_ARP))} + Imp</H5>
                            </>
                        )}
                        {orderItem?.total_USD && parseInt(orderItem?.total_USD) !== 0 && (
                            <>
                                <Flex justifyContent={"space-between"} gap={"16px"}>
                                    <H6 fontWeight={400}>Subtotal productos en USD:</H6>{" "}
                                    <H6 fontWeight={400}>{USD.format(parseFloat(orderItem?.total_USD))} + Imp</H6>
                                </Flex>
                                <Flex justifyContent={"space-between"} gap={"16px"}>
                                    <H6 fontWeight={400}>Costo de envío en USD:</H6>
                                    {!orderItem?.shipping_cost_USD  || orderItem?.shipping_cost_USD ? (
                                        <H6 fontWeight={400} color="brand.success">
                                            Incluído
                                        </H6>
                                    ) : (
                                        <H6 fontWeight={400}>
                                            {ARS.format(parseFloat(orderItem?.shipping_cost_USD))} + Imp
                                        </H6>
                                    )}
                                </Flex>
                                <H5>Total en USD {ARS.format(parseFloat(orderItem?.total_w_shipping_USD))} + Imp</H5>
                            </>
                        )}
                    </Flex>
                </Flex>
            </BoxBekeu>
        </Flex>
    );
};

const ApproveCartOrdersItem = ({
  item,
  type,
  showitems,
  selected,
  state,
  getOneProduct,
  handleApproveCart,
  handleRejectCart,
  handleApproveOrder,
  handleRejectOrder,
  handlePagination,
  params,
  ...props
}) => {
  const history = useHistory();
  const [imageError, setImageError] = useState(false);
  const [noProduct, setNoProduct] = useState(false);
  const ARS = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS',});
  const USD = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'USD',});

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
    function handleNoProduct(i) {
        handleScrollTo();
        if (i.combination) {
            history.push(`/detail/${i?.sku}`);
        } else {
            setNoProduct(!noProduct);
        }
    }


    let parsedFullName = item?.client.user?.name + " " + item?.client.user?.last_name;
  if (item?.client?.erp_username) parsedFullName += " (" + item?.client?.erp_username + ")";
  showitems = true;
  const total_items = item.buyOrders.reduce((accumulator, buyOrder) => accumulator + buyOrder.items.length, 0);

  return (
      <BoxBekeu p={0} {...props}>
          <Flex flexDir={"column"}>
              <Flex alignItems={"center"} justifyContent={"space-between"} mb={30} p="24px">
                <H5>{moment(item?.created_at).format("LL")}</H5>
                  <Flex>
                      <CartOrderId mr={4}>
                          Carrito N°{item?.internal_code}
                      </CartOrderId>
                      <ApproveCartsBadge item={item}>
                          {item?.state === "1" ? "Pendiente"
                          : item?.state === "2" ? "Entrega en proceso"
                          : item?.state === "3" ? "Rechazada"
                          : item?.state === "4" ? "Finalizada"
                          : "En Curso"}
                      </ApproveCartsBadge>
                  </Flex>
              </Flex>
              <Divider />
              <Flex justifyContent={"space-between"} p={"24px"}>
                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                    Comprador
                  </Paragraphs>
                  <Paragraphs>{parsedFullName}</Paragraphs>
                </Flex>
                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                    Nro de compra
                  </Paragraphs>
                  <Paragraphs>{item?.internal_code}</Paragraphs>
                </Flex>
                <Flex flexDir={"column"} w="20rem" lineHeight="1.5rem">
                  <Paragraphs fontWeight={600}>Zona de entrega</Paragraphs>
                  <Paragraphs lineHeight="1.5rem">
                      {item?.address?.logistic_center} - {item?.address?.name}
                  </Paragraphs>
                </Flex>

                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                    Comentarios del comprador
                  </Paragraphs>
                  <Paragraphs>{item?.approver_comments}</Paragraphs>
                </Flex>
                <Flex flexDir={"column"}>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem" justify="center">
                      <Text justify="center" textAlign="center">Total de artículos:</Text>
                  </Paragraphs>
                  <Paragraphs fontWeight={600} lineHeight="1.5rem">
                      <Text justify="center" textAlign="center">{total_items}</Text>
                  </Paragraphs>
                    {/*<AvatarGroup
                      size="md"
                      max={2}
                      draggable={false}
                      justifyContent={"space-evenly"}
                  >
                  </AvatarGroup>*/}
                  <Paragraphs textAlign="center"></Paragraphs>
                </Flex>
              </Flex>
              <Divider />

              <Flex justifyContent={"center"} alignItems="center">
                  <BoxBekeu
                      width={"85%"}
                      mt={"48px"}
                      boxShadow="none"
                      border="0.5px solid"
                      borderColor="brand.border"
                      p="0"
                  >
                      <H6 p="24px">Totales en ARS y USD</H6>
                      <Divider />
                      <Flex justifyContent={"space-between"} height="200px" p="24px">
                          {item?.total_ARP && parseInt(item?.total_ARP) !== 0 ? (
                              <Flex flexDir={"column"} justifyContent={"space-between"}>
                                  <Flex flexDir={"column"} gap="8px">
                                      <H6>Total productos en ARS</H6>
                                      <H6 fontWeight={400}>{ARS.format(parseFloat(item?.total_ARP))} + Imp</H6>
                                  </Flex>
                                  <Flex flexDir={"column"} gap="8px">
                                      <H6>Total costo de envío en ARS</H6>
                                      {!item?.shipping_cost_ARP || item?.shipping_cost_ARP === 0 ? (
                                          <H6 fontWeight={600} color="brand.success">
                                              Envío incluído
                                          </H6>
                                      ) : (
                                          <H6 fontWeight={400}>
                                              {ARS.format(parseFloat(item?.shipping_cost_ARP))} + Imp
                                          </H6>
                                      )}
                                  </Flex>
                              </Flex>
                          ):(<></>)}
                          {item?.total_USD && parseInt(item?.total_USD) !== 0 ? (
                              <Flex flexDir={"column"} justifyContent={"space-between"}>
                                  <Flex flexDir={"column"} gap="8px">
                                      <H6>Total productos en USD</H6>
                                      <H6 fontWeight={400}>{USD.format(parseFloat(item?.total_USD))} + Imp</H6>
                                  </Flex>
                                  <Flex flexDir={"column"} gap="8px">
                                      <H6>Total costo de envío USD</H6>
                                      {!item?.shipping_cost_USD || item?.shipping_cost_USD === 0 ? (
                                          <H6 fontWeight={600} color="brand.success">
                                              Envío incluído
                                          </H6>
                                      ) : (
                                          <H6 fontWeight={400}>
                                              {USD.format(parseFloat(item?.shipping_cost_USD))} + Imp
                                          </H6>
                                      )}
                                  </Flex>
                              </Flex>
                          ):(<></>)}
                      </Flex>
                  </BoxBekeu>
              </Flex>
              {
              item?.buyOrders &&
              item.buyOrders.map((orderItem, oid, itemArr) => (
                  <DetailItem
                      key={oid}
                      orderItem={orderItem}
                      history={history}
                      handlePagination={handlePagination}
                      itemArr={itemArr}
                      handleScrollTo={handleScrollTo}
                      noProduct={noProduct}
                      handleNoProduct={handleNoProduct}
                  />
              ))}


              <Flex flexDir={"column"} justifyContent={"center"} alignItems="center">
                      <Flex flexDir={"column"}>
                          {item?.state === "1" && (
                          <Flex alignItems={"center"} justifyContent={"center"} p="24px" w={"100%"}>
                              <Flex textAlign={"center"} alignItems={"center"}>

                                    <Flex alignItems={"center"}>
                                      <ApproveCartsRejectModal
                                          handleRejectCart={handleRejectCart}
                                          item={item}
                                          handlePagination={handlePagination}
                                          px={"50px"}
                                      />
                                      <ApproveCartsSuccessModal
                                          handleApproveCart={handleApproveCart}
                                          item={item}
                                          handlePagination={handlePagination}
                                          px={"50px"}
                                      />
                                    </Flex>
                              </Flex>
                            </Flex>
                          )}
                      </Flex>
              </Flex>
          </Flex>
      </BoxBekeu>
  );
};

export default injectIntl(
    connect(null, { ...product.actions, ...cartActions.actions })(ApproveCartOrdersItem)
);
